import React from "react";
import { Col, Media, UncontrolledTooltip } from 'reactstrap';
import { isMobile } from "react-device-detect";
import { useNavigate } from 'react-router-dom';

export const Footer = ({
    pending,
    color = "secondary",
    key,
    link,
    name,
    description,
    children,
    textToDisplay,
    displayButtons,
    overrideImage,
    specificAim,
    ...props
}) => {
    const navigate = useNavigate();
    const toThinkingPlace = e => {
        e.preventDefault();
        if (specificAim) { navigate(`/notebook/${specificAim}`); }
        else { navigate(`/aims_list`); }
    }

    return (
        <div>
            <br />
            <Col id="footer-area" className="bg-white rounded border border-dark flex" style={{ placeContent: 'center' }}>
                {textToDisplay !== "" ?
                    <>
                        <Media object src={overrideImage ? `/icons/${overrideImage}` : '/icons/information.png'} style={{ maxWidth: "60px", maxHeight: "60px", alignSelf: "center" }} aria-label={"Information image"} />
                        <div className="footer-row">
                            <div className="footer-text-container">{textToDisplay}</div>
                        </div>
                    </>
                    : ""}
                {displayButtons !== "false" && (
                    <>
                        <Media className="clicky" id="notebook" object src={'/icons/browse.png'} style={{ maxWidth: "75px", maxHeight: "75px", alignSelf: "center" }} onClick={toThinkingPlace} aria-label={"Press to go to notebook"} />
                        <UncontrolledTooltip placement="auto" target={`notebook`} trigger={!!isMobile ? ("click") : ("click hover")}>
                            This is your personal notebook in which you can save your thoughts.  Only you can see what you write.  You can also download your notes if you want to.  (If you want to save your place in the questions, just make a note for that question, and you can go back to that point via your notebook next time you log in.)
                        </UncontrolledTooltip>
                    </>
                )
                }
            </Col>

            <br />
        </div>
    );
};

export default Footer;
