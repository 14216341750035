import React, {useState, useEffect} from "react";
import { Button, Input as ReactStrapInput } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import { useClient } from '../../hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { FormGroup, Input } from '../react-hook-form';
import { NotificationManager } from "react-notifications";

export const EditNoteModal = ({
  open,
  setOpen,
  selectedNote,
  selectedQuestion,
  selectedPersonalisedAim,
  showQuestionSelect,
  chosenElement,
  setUserNotes,
  reloadAim,
  children,
  ...props
}) => {
  const client = useClient();
  //eslint-disable-next-line
  const [ questions, setQuestions] = useState([]);
  const [ count, setCount ] = useState(selectedNote.thought.length);
  const methods  = useForm({
    defaultValues: selectedNote,
		mode: 'all',
	});
	const {
		handleSubmit,
		setError,
    setValue,
    reset
	} = methods;

    const [ thought, setThought] = useState(selectedNote.thought);

  useEffect(() => {
    if(selectedNote) {
      setThought(selectedNote.thought);
      const questions = [{value: selectedNote.Question, label: selectedNote.Question__Name}];
      setQuestions(questions);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedNote]);

  const handleClose = () => {
    setOpen(false);
    setCount(0);
    reset(selectedNote);
    if (reloadAim) reloadAim(selectedPersonalisedAim?.id);
  };

  useEffect(() => {
    reset(selectedNote);

  }, [reset, selectedNote, open])

  const recount = e => {
    setCount(e.target.value.length);
    setValue("thought", e.target.value, false);
  };

  const onSubmit = (formData) => {
    if(typeof formData.Question === "object"){
        formData.Question = formData.Question?.value ?? selectedQuestion?.id ?? null;
    }
		client
			.post('/usernote/edit', {note: selectedNote.id, ...formData})
			.then((responseData) => {
        NotificationManager.success('Your note has been added successfully!');
        if(selectedQuestion != null){
          setUserNotes(r => {
            if (r) {
              return [responseData.data, ...r];
            }
            return [responseData.data];
          });
        }

        handleClose();
			})
			.catch((ex) => {
				const errorMessages = ex?.response?.data?.detail
				if(errorMessages){
					for (const [key, value] of Object.entries(errorMessages)) {
						setError(key, { message: value[0] });
					}
				}

				NotificationManager.error('An error occurred. Please try again.');
			});
	};

  return(
    <FormProvider {...methods}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Modal show={open} onHide={handleClose} className="modal-md" {...props}>
          <Modal.Header closeButton>
            <Modal.Title>Edit the note for this question</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup name="question" label="Question">
              <Input
                component={ReactStrapInput}
                type='textarea'
                rows={3}
                name={"Question__Name"}
                readOnly
              />
            </FormGroup>
            {!! selectedNote && (
              <>
                <FormGroup name="thought" label="Thoughts?">
                  <Input
                    component={ReactStrapInput}
                    type='textarea'
                    rows={10}
                    className='form-control'
                    name={'thought'}
                    placeholder='Enter your thoughts on this question here.'
                    onChange={recount}
                    />
                </FormGroup>
                <p style={{textAlign: 'right', fontSize: '10px'}}>
                  {count > 4096 ? (
                      <i style={{color: 'red'}}>{count}/4096</i>
                    ):
                      <i style={{color: 'black'}}>{count}/4096</i>
                  }
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="rounded-lg" color="danger" onClick={handleClose}>
              Close
            </Button>
            <Button className="rounded-lg px-8" color="success" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </FormProvider>
  )
}


export default EditNoteModal;
