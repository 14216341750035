import React, { useEffect, useRef } from 'react';
import Slider from "react-slick";


export const ElementCarousel = ({
  pending,
  color = "secondary",
  key,
  link,
  name,
  description,
  children,
  arrows = true,
  dots = false,
  slidesToShow = null,
  origin,
  current,
  setCurrent,
  ...props
}) => {
    const slider = useRef();
    useEffect(() => {
      if(current>=0 && typeof current === 'number'){
        slider.current.slickGoTo(current);
        window.scrollTo(0, 0);
      }else{
        slider.current.slickGoTo(0);

      }
    }, [current]);


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow ?? 1,
        slidesToScroll: slidesToShow ?? 1,
        initialSlide: current,
        className: "slides",
        beforeChange: (curr, next) => {
          if(curr ===-1 || next ===-1 || typeof curr === "undefined" || typeof next === "undefined"){return;}
          setCurrent(next)
        },
        arrows: false,
      };
    return (
        <Slider ref={slider} {...settings}>
          {children}
        </Slider>
    );
};

export default ElementCarousel;
