import React from "react";
import { useFormik, FormikProvider, Form } from "formik";
import { Link } from "react-router-dom";
import { createClient } from "hooks/useClient";
import { useParams } from "react-router-dom";
import PasswordFields from "./PasswordFields";
import { Trans, useTranslation } from "react-i18next";
import { Page, Header } from 'components/common';
import { Alert, Row, Col } from 'reactstrap';
import PendingButton from "components/common/PendingButton";

export const ResetPassword = () => {
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      success: false,
      error: null,
    },
    onSubmit,
  });
  const { setFieldValue } = formik;
  const { success, error } = formik.values;
  const { token } = useParams();
  const { t } = useTranslation();

  async function onSubmit({ password }) {
    const client = createClient();
    setFieldValue("success", false);
    setFieldValue("error", null);

    try {
      await client.post("password_reset", { password, token });
      setFieldValue("success", true);
    } catch (ex) {
      const code = ex?.response?.data?.code;

      const error = {
        InvalidToken: t(
          "This password reset request is invalid or has already been used"
        ),
        TokenExpired: t("This password reset request has expired"),
        PasswordFailsValidation: t("Please provide a stronger password"),
      }[code];

      if (error) {
        setFieldValue("error", error);
      } else {
        throw ex;
      }
    }
  }


  return (
    <Page background="admin-pages" displayButtons={"false"}>
      <Header className="mb-2" displayIcon={"false"} title={"Reset Password"}></Header>
      <Row className='align-items-center h-100'>
        <Col className='p-2'>
          {!success ? (
            <>
              <p className="text-center text-sm mb-4">
                <Trans>Please confirm your new password below</Trans>
              </p>
              <FormikProvider value={formik}>
                <Form className="mt-4">
                  <PasswordFields />
                  <PendingButton className="float-right rounded-md h-10" color="success" pending={formik.isSubmitting}>
                    Submit
                  </PendingButton>
                </Form>
                {error ? (
                  <Alert color="danger" className="mt-2">
                    {error}
                  </Alert>
                ) : null}
              </FormikProvider>
            </>
          ) : (
            <>
              <div className="prose prose-sm text-center mb-4">
                <p className="text-center">
                  <Trans>
                    Your password has been successfully changed. You may now{" "}
                    <Link to={"/login"}>login</Link>.
                  </Trans>
                </p>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default ResetPassword;
