import React from "react";
import { Page, Header } from "components/common";
import { Row, Col } from 'reactstrap';

export const SelfEsteem = () => {
  
  return (
    <Page background="questions">
        <Header displayIcon="false" displayButtons="true"></Header>

        <h1 style={{textAlign: "center"}}>What is my self-esteem like?</h1>

        <Row>
          <Col xs={'12'} md={'6'} lg={'4'}>
            <div className="mb-5 mt-4" style={{textAlign: "center"}}><b>Low self esteem</b></div>
            <div>I often seem to have negative thoughts about myself</div>
            <div>I generally think I’m no good at most things</div>
            <div>I often doubt my abilities and that I will succeed</div>
            <div>I often feel like a failure</div>
            <div>I often think I’m no good</div>
            <div>People are always better than me at things</div>
            <div>I’m unhappy with myself as a person</div>
            <div>I often feel useless</div>
            <div>I rarely praise myself, even when I’ve done well</div>
            <div>I often feel worthless, like I have no value</div>
            <div>I don’t have much respect for myself</div>
            <div>There’s nothing much that I have to be proud of</div>
            <div>I think that other people have more worth than me</div>
            <div>I feel ashamed and embarrassed when I know I’ve failed</div>
            <div>My regrets often pull me down and I struggle to move on</div>
          </Col>
          <Col xs={'12'} md={'6'} lg={'4'}>
            <div className="mb-5 mt-4" style={{textAlign: "center"}}><b>High self esteem</b></div>
            <div>	I generally think positively about myself	</div>
            <div>	I know that I have some good qualities	</div>
            <div>	I usually have confidence in myself	</div>
            <div>	I often succeed at things	</div>
            <div>	I rarely think negatively about myself	</div>
            <div>	Compared to other people, I can usually do things as well as they can	</div>
            <div>	I’m happy with myself as a person	</div>
            <div>	I rarely experience a sense of uselessness	</div>
            <div>	I can praise myself when I do well	</div>
            <div>	I feel like I have worth and value as a person	</div>
            <div>	I respect myself	</div>
            <div>	I’m proud of myself for what I have achieved	</div>
            <div>	I can see that other people have worth as well as me	</div>
            <div>	I can honestly acknowledge when I’m not good at something	</div>
            <div>	I can express regret without it overwhelming me	</div>
          </Col>
          <Col xs={'12'} md={'6'} lg={'4'}>
            <div className="mb-3 mt-4" style={{textAlign: "center"}}><b>Over-confidence (which can mask low self esteem)</b></div>
            <div>	I always think positively about myself, regardless of the situation			</div>
            <div>	I generally think I’m good at most things			</div>
            <div>	I sometimes exaggerate my good qualities			</div>
            <div>	I minimise my failings			</div>
            <div>	I never think negative thoughts about myself			</div>
            <div>	I usually think I do better than other people (even when I don’t)			</div>
            <div>	I’m often impressed with myself			</div>
            <div>	I don’t ever feel useless or that I can’t help in some way			</div>
            <div>	I over-exaggerate my successes and build myself up			</div>
            <div>	My worth and value as a person is particularly significant to me			</div>
            <div>	I think other people should respect me			</div>
            <div>	My achievements are of high importance to me			</div>
            <div>	I feel that I have more worth or value than other people			</div>
            <div>	I hide my failings as best I can			</div>
            <div>	I have no regrets			</div>
          </Col>
        </Row>

		</Page>
  );
}

export default SelfEsteem;
