import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

export const StepProgressBar = ({
  percent,
  ...props

}) => {
  return (
    <ProgressBar
      percent={Number(percent)}
      filledBackground="linear-gradient(to right, #ADD8E6, #00008B)"
    >
      {/* <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              src=""
              alt=""
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              src=""
              alt=""
            />
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              src=""
              alt=""
            />
          )}
        </Step> */}
    </ProgressBar>
  );
};
export default StepProgressBar;
