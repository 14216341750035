import React from "react";
import { Page, Header } from "../../../components/common";
import { Trans, useTranslation } from "react-i18next";
import { useClient, useConfirmModal, useUserContext } from "../../../hooks";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Alert, Button, ModalBody, ModalHeader, Media } from "reactstrap";
import { SubscriptionDetail } from "./components/SubscriptionDetail";
import { NotificationManager } from "react-notifications";
import { defaultQueryFn } from "./queries";

export const SubscriptionView = () => {
  const { t } = useTranslation();
  const client = useClient();
  const { Modal, toggle } = useConfirmModal();
  const { refreshUser } = useUserContext();

  const { data: subscription, refetch } = useQuery(
    ["stripe/subscription"],
    defaultQueryFn(client),
    {
      suspense: true,
      silent: true,
      onSuccess: (v) => console.log({ v }),
    }
  );

  const { status, cancel_at_period_end } = subscription ?? {};
  const isCancellable =
    status && !(status === "incomplete_expired" || status === "canceled");

  const statusMessage = {
    incomplete: t(
      "Your subscription is incomplete and will expire in 24 hours. Please pay any outstanding invoices to activate."
    ),
    incomplete_expired: t(
      "Your subscription attempt expired, please restart the process to continue."
    ),
    past_due: t(
      "Your account is past due, please pay any outstanding invoices to avoid service disruption."
    ),
    unpaid: t("Your account has unpaid invoices and has been temporarily suspended."),
  }[status];

  async function onCancel() {
    try {
      await client.post("stripe/cancel_subscription");
      NotificationManager.success(t("Your subscription has been cancelled successfully!"));
    } catch (ex) {
      console.error(ex);
      NotificationManager.error(t("Unable to cancel subscription at this time."));
    }
    await refetch();
    refreshUser();
  }

  return (
    <div className="tw-max-w-screen-md tw-mx-auto">
      <Modal onConfirm={onCancel}>
        <ModalHeader>
          <Trans>Cancel subscription</Trans>
        </ModalHeader>
        <ModalBody>
          {/* <p>
            <Trans>Are you sure you want to cancel your subscription?</Trans>
          </p> */}
          <ul>
            <li>
              <Trans>Of course, no problem. All your subscription information will be finally deleted in 30 days, (giving you a little time to reinstate your account if you change your mind). Use the password reset on the login page if you'd like to reinstate your account.</Trans>
            </li><p></p>
            <li>
              <Trans>
                Thanks for using the Self-therapist tool.  We hope you’ve found it valuable.  Before you go, would you be kind enough to fill out one of the feedback forms at <a href="https://www.self-therapist.com/feedback-forms">www.self-therapist.com/feedback-forms</a>?  It will help us to improve the tool for other people.
              </Trans>
            </li>
            <li><p></p>
              <Trans>
                Thanks so much.
              </Trans>
            </li>
          </ul>
          {/* <Alert color="info">
            <Trans>
              <strong>Note:</strong> Remaining credit will be refunded to your payment method after your cancellation has been processed and can take between 3-10 business days to arrive depending on your payment method and banking provider.
            </Trans>
          </Alert> */}
        </ModalBody>
      </Modal>
      <Page title={t("")}>
        <Header className="mb-2" displayIcon={"false"}></Header>
        <SubscriptionDetail subscription={subscription} />

        <div className="col-md-9 col-xl-6 m-auto">
          <ul className="tw-list-none tw-pl-0 tw-leading-loose tw-mt-2 tw-mb-4">
            <li className="justify-content-center flex items-center">
              {!subscription || !isCancellable ? (
                <Link to={"create-subscription"}
                  className="flex items-center btn-primary main-btn my-2 border-0 text-white h-10"
                >
                  <Trans>Start subscription</Trans>
                </Link>
              ) : (
                <Link to={"update-subscription"}
                  className="flex items-center btn-primary main-btn my-2 border-0 text-white h-10"
                >
                  <Trans>Update subscription</Trans>
                </Link>
              )}
            </li>
            {isCancellable && !cancel_at_period_end ? (
              <li className="justify-content-center flex items-center">
                <Button
                  onClick={toggle}

                  className="btn-danger main-btn my-2 border-0 text-white h-10"
                >
                  <Trans>Cancel subscription</Trans>
                </Button>
              </li>
            ) : null}
            <li className="justify-content-center flex items-center pt-5">
              <Media object src={'/Powered by Stripe - blurple.svg'} width={250} height={250} aria-label="Powered by Stripe" />
            </li>
          </ul>
        </div>
        {statusMessage ? <Alert color="warning">{statusMessage}</Alert> : null}

      </Page>
    </div>
  );
};
