import React, { useState } from "react";

import { useClient, useUserContext } from "../../../hooks";
import { useForm, FormProvider } from "react-hook-form";
import { Page } from "../../../components/common";
import { NotificationManager } from "react-notifications";

import { SubmitButton, FormGroup, Input } from "../../../components/react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CalculatedPrice } from "./components/CalculatedPrice";

import { PlanField } from "./components/PlanField";
import { usePriceOptions } from "./hooks/usePriceOptions";
import { Button, Media } from "reactstrap";

export const CreateSubscription = () => {
  const client = useClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useUserContext();
  const [isPending, setIsPending] = useState(false);
  const [finalQuantity, setFinalQuantity] = useState();

  const methods = useForm({
    mode: "all",
  });
  const { handleSubmit, control } = methods;

  const priceOptions = usePriceOptions();

  const onSubmit = ({ price, quantity }) => {
    setIsPending(true);

    client.post("stripe/create_subscription", {
      quantity: finalQuantity ?? quantity,
      price: price.id,
    }).then((resp) => {
      const { client_secret: clientSecret, paid } = resp.data;
      navigate("payment-method", {
        state: {
          clientSecret,
          paid,
        }
      });
    }).catch(err => {
      // navigate("/subscription");
      const error = err?.response?.data?.detail

      if (error) {
        NotificationManager.error(error);
      } else {
        NotificationManager.error(t("This subscription was not created, please try again."));
      }

    }).finally(function () { setIsPending(false); });
  }

  return (
    <Page title={t("Create Subscription")} translate={false}>
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          {!!user.organisation_user && (

            <FormGroup label={t("How many invitations would you like to purchase?")} required name="quantity">
              <Input
                defaultValue={1}
                className="form-control"

                name="quantity"
                rules={{
                  required: true,
                }}
              />
            </FormGroup>
          )}

          <PlanField
            name="price"
            orgAccount={!!user.organisation_user ? true : false}
            rules={{
              required: true,
            }}
            options={priceOptions}
          />
          {!!user.is_organisation_admin && (
            <p className="mt-2"><b>If you need more than 50 invitations, you may like to <a href="/contact_us">contact us</a> direct to discuss bulk discounts</b></p>
          )}
          <p className='pt-6'></p>
          <CalculatedPrice setQuantity={setFinalQuantity} />
          <p className='pt-6'></p>
          <div className="flex justify-between">
            <Button onClick={() => navigate("../")}>Cancel</Button>
            <SubmitButton control={control} pending={isPending}>
              <Trans>Continue</Trans>
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
      <li className="justify-content-center flex items-center pt-5">
        <Media object src={'/Powered by Stripe - blurple.svg'} width={250} height={250} aria-label="Powered by Stripe" />
      </li>

    </Page>
  );
};
