import { useController } from "react-hook-form";
import ReactSelect from "react-select";
import React from "react";

export const Select = ({
  name,
  defaultValue,
  control,
  rules,
  shouldUnregister,
  ...props
}) => {
  const { field } = useController({
    name,
    defaultValue,
    shouldUnregister,
    rules,
    control,
  });

  return <ReactSelect {...field} {...props} />;
};
