import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from "react-notifications";

import { useClient } from 'hooks';
import { Row, Col } from 'reactstrap';
import { Page, LoadingSpinner, DecisionButton, StepProgressBar, Header, cipher } from "components/common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export const DecisionTreeWouldLike = () => {
	const client = useClient();
	const [categories, setCategories] = useState([]);
	const pageHead = "I could work on...";

	useEffect(() => {

		client.get("category").then(e => {
			setCategories(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve categories. Please try again.");
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<Page background="journey_three">
			<Breadcrumb>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_start", style: { color: "darkblue" } }}>
					Think
				</Breadcrumb.Item>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_options", style: { color: "darkblue" } }}>
					Choose Path
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					Browse Categories
				</Breadcrumb.Item>
			</Breadcrumb>
			<StepProgressBar percent={15} />
			<Header className="mb-2" displayIcon={"false"} title={pageHead}></Header>
			<Row className='align-items-center h-100 mt-2'>
				<Col className='i-know-card text-center'>
					{!!categories ? categories.map((category, index) => (
						<Col sm={'12'} md={'8'} xl={'6'} className="justify-content-around w-100 m-auto" key={index} >
							<DecisionButton keyName={index} link={`/choose_quality/${cipher.obfuscate(category.id)}`} name={category.name} description={category.description} />
						</Col>
					)) : <LoadingSpinner />}
				</Col>
			</Row>
		</Page>
	);
};

export default DecisionTreeWouldLike;
