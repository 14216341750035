import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import { Page, Header } from 'components/common';

export const Onboarding = () => {
  return (
    <Page background="admin-pages">
      <Header className="mb-2" displayIcon={"false"}></Header>
      <Row className='text-center h-100 mt-2'>
        <Col xs={8} className='i-know-card mx-auto'>
          {/* <h4 className=""><b>This is a self-therapy tool which helps you to think through your issues using questions - the kind of questions therapists might ask you</b></h4> */}
          <img className="mx-auto" style={{ maxWidth: '30%' }} src={'/icons/ST-logo.png'} alt='' />
        </Col>
      </Row>
      <Col className="m-auto" >
        <Col xs={'12'} md={'8'} lg={'6'} className="justify-content-center m-auto" >
          {/* <Link className="main-btn" to="/help">
            User Guide
          </Link> */}
          <a className="main-btn" target="_blank" href="https://www.self-therapist.com/help">User Guide<br /><br />
            <a>(Or see 'Help' on the main menu)</a>
          </a>
        </Col>

        <Col xs={'12'} md={'8'} lg={'6'} className="justify-content-center m-auto" >
          <Link className="main-btn" to="/the_research">
            The academic research behind the tool
          </Link>
        </Col>
      </Col>

      <div className="flex justify-content-center" >
        <Link className="btn-primary main-btn question-grid-items my-3 border-0 text-white h-10" to="/signup">
          Continue
        </Link>
      </div>
      <div style={{ paddingBottom: '200px' }}></div>
    </Page>
  );
};

export default Onboarding;
