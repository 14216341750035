import React from "react";
import { useNavigate } from 'react-router';
import { Trans, useTranslation } from "react-i18next";
import { selectPaymentMethodOption, getTotalPrice } from "../selectors";
import { formatLocalDate } from "../../../../services/util";
import { useStatusMapping } from "../hooks/useStatusMapping";
import { formatStripeAmount } from "../util";
import { useUserContext, useClient } from '../../../../hooks';
import { NotificationManager } from 'react-notifications';

const Detail = ({ header, children }) => {
  return (
    <li>
      <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
        <div style={{ marginRight: 'auto' }}>{header}</div>
        <div><strong>{children}</strong></div>

      </div>

    </li>
  );
};

export const SubscriptionDetail = ({ subscription }) => {
  const { t } = useTranslation();

  const {
    current_period_end,
    cancel_at_period_end,
    default_payment_method,
    plan,
    quantity,
    status,
    customer,
  } = subscription ?? {};
  const { balance, currency } = customer ?? {};

  const statusMapping = useStatusMapping();

  const { user, refreshUser } = useUserContext();

  const navigate = useNavigate();

  const client = useClient();

  function startTrial() {
    client.post("start_trial").then(e => {
      refreshUser();
      NotificationManager.success("You have successfully started your free trial, welcome to Self Therapist!");
      localStorage.setItem("completed_onboarding", 'true');
      navigate('/onboarding_instructions');

    }).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Could not start your free trial, please try again.");
    });
  }

  const clientFeedback = () => { window.open('https://forms.gle/roDiCP3BepNaCDcn7', '_blank'); };

  return (

    <ul className="list-none pl-0  col-md-9 col-xl-6 m-auto leading-relaxed">
      {subscription ? (
        <>
          <div className="alert alert-info text-center xs:p-1 sm:p-2 md:p-6" role="alert">

            <Detail header={t("Payment method")}>
              {default_payment_method
                ? selectPaymentMethodOption(t)(default_payment_method).label
                : t("None")}
            </Detail>
            {quantity > 1 ? (

              <Detail header={t("Number of Invitations")}>{quantity}</Detail>
            ) : null}
            <Detail header={'Subscription Cost'}>{getTotalPrice(t)(plan, quantity)} </Detail>
            {/* <Detail header={t("Plan")}>{selectPriceOption(t)(plan).label}</Detail> */}
            {current_period_end ? (
              <Detail header={t("Current period end")}>
                {formatLocalDate(current_period_end * 1000)}
              </Detail>
            ) : null}
            <Detail header={t("Status")}>
              {statusMapping[status] || status}
              {status === "active" && cancel_at_period_end ? (
                <span className="text-muted tw-font-normal">
                  {" "}
                  <Trans>(cancelling at period end)</Trans>
                </span>
              ) : null}
            </Detail>
            {balance !== undefined ? (
              <Detail header={t("Account balance")}>
                {formatStripeAmount(Math.abs(balance))} {currency.toUpperCase()}
                {balance < 0 && (" In Credit")}
                {balance > 0 && (" Owed")}

              </Detail>
            ) : null}
          </div>
        </>
      ) : (
        <>
          {!!(user?.subscription_status !== 'active' && user?.subscription_status !== 'active_trial' && user?.trial_ended && user?.trial_days_remaining === 0) && (
            <div className="alert alert-danger text-center p-6" role="alert">
              You don't currently have an active subscription.
            </div>
          )}
          {user?.trial_days_remaining > 0 && (
            <div className="alert alert-warning text-center p-6" role="alert">
              You have {user?.trial_days_remaining} days remaining of your trial.

            </div>
          )}
          <div className="alert alert-warning text-center p-6" role="alert">
            Subscribe so you can have access whenever you need it
          </div>
          {user?.organisation && (
            <div className="alert alert-info text-center p-6" role="alert">
              Purchase invitations for clients or employees (users), and each time an invitation is accepted, your balance of invitations will reduce by one.
            </div>
          )}
          {user?.trial_ended === null && (
            <div className="alert alert-info text-center p-6" role="alert">
              Try the tool for a few days without a subscription. Click <u className="clicky" onClick={() => startTrial()}>here</u> to start.
            </div>
          )}
          {user?.subscription_status === 'expired_study' && (
            <div className="alert alert-info text-center p-6" role="alert">
              We're sorry, the study you were invited to has expired. Would you mind completing the User's Feedback form using the following <u className="clicky" onClick={() => clientFeedback()}>link</u>?
            </div>
          )}
          {/* <Detail header={t("Subscription")}>
          <Trans>Not set up</Trans>
        </Detail> */}
        </>
      )
      }
    </ul >
  );
};
