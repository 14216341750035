import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import { Page } from 'components/common';

export const Welcome = () => {
	return (
		<Page background="admin-pages">
			<div className='flex m-auto justify-content-center align-items-center'>
				<div style={{ paddingBottom: '400px' }}></div>
				<Row className='align-items-center h-100 mt-2'>
					<Col xs={8} className='i-know-card text-center mx-auto'>
						<h3><b>Welcome</b></h3>
						<img className="mx-auto" style={{ maxWidth: '30%' }} src={'/icons/ST-logo.png'} alt='' />
						<h4 className="my-4"><b>This is a clinical tool to help you to manage your mental health and your self-development</b></h4>
						<div className="flex justify-content-center" >
							<Link className="btn-primary main-btn question-grid-items my-3 border-0 text-white h-10" to="/onboarding">
								Start
							</Link>
						</div>
					</Col>
				</Row>
			</div>
			<Row>
				<Col>
					<Link className="float-left" to="/login">
						Login
					</Link>
				</Col>
				<Col>
					<a href="https://self-therapist.com" target="_blank" rel="noopener noreferrer" className="float-right">
						What is this?
					</a>
				</Col>
			</Row>
		</Page>
	);
};

export default Welcome;
