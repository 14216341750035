import React, { useEffect, useState } from 'react';
import { Media } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import classNames from "classnames";

export const Header = ({
  pending,
  color = "secondary",
  key,
  link,
  children,
  setReorderMode,
  setAns,
  reorderMode,
  displayIcon,
  displayButtons,
  secondIcon,
  className,
  iconSize = 75,
  title,
  ...props
}) => {

  const navigate = useNavigate();
  const [isHiddenHelp, setIsHiddenHelp] = useState("");

  useEffect(() => {
    var element = document.getElementById("footer-area");
    if (!element) {
      setIsHiddenHelp("invisible");
    } else {
      setIsHiddenHelp("");
    }
  }, [])
  const onBackClick = e => {
    e.preventDefault();
    if (reorderMode && setReorderMode) {
      if (setAns) setAns(null);
      setReorderMode(false);
    }
    else {
      navigate(-1);
    }
  }
  const toBottom = e => {
    var element = document.getElementById("footer-area");
    if (element) {
      element.classList.add("shake2");

      setTimeout(function () {
        element.classList.remove("shake2");
      }, 500)
      element.scrollIntoView();
    }
  }
  return (
    <div className={classNames("my-2 header-flex", className)} {...props}>
      {displayButtons !== "false" ?
        <Media className="clicky" object src={'/icons/previous.png'} width={iconSize} height={iconSize} onClick={onBackClick} style={{ height: `${iconSize}px`, width: `${iconSize}px`}} alt="Go Back" />
        : ""}
      {displayIcon !== "false" ?
        <Media object src={displayIcon} width={iconSize} height={iconSize}  style={{ height: `${iconSize}px`, width: `${iconSize}px`}} alt="Variable button" />
        : ""}

      {children ?? (
        //eslint-disable-next-line
        <h4 role="heading" aria-level="1" className="my-4 text-center"><b>{title}</b></h4>
      )}
      {displayButtons !== "false" ?
        <Media className={classNames("clicky", isHiddenHelp)} object src={'/icons/information.png'} width={iconSize} height={iconSize} onClick={toBottom}  style={{ height: `${iconSize}px`, width: `${iconSize}px`}} alt="More information" />
        : ""}
    </div>
  );
};

export default Header;
