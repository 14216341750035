import React from "react";
import { useFormik, FormikProvider, Field, Form } from "formik";
import { isEmail } from "validator";

import { createClient } from "hooks/useClient";
import { Trans } from "react-i18next";
import { Page, Header } from 'components/common';
import { Row, Col } from 'reactstrap';
import PendingButton from "components/common/PendingButton";
import { NotificationManager } from 'react-notifications';

export const ResetPasswordRequest = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      success: false,
    },
    onSubmit,
  });
  const { setFieldValue } = formik;
  const { success } = formik.values;

  async function onSubmit(x) {
    const client = createClient();
    await client.post("password_reset_request", x)
      .then(resp => {
        setFieldValue("success", true);
        NotificationManager.success('Request sent');
      })
      .catch(exc => {
        NotificationManager.error('Unable to send the reset email to your email address ');
      });
  }

  // const backToLogin = (
  //   <div className="text-right">
  //     <Link to={"../login"} className="text-sm">
  //       <Trans>Back to login</Trans>
  //     </Link>
  //   </div>
  // );

  return (
    <Page background="admin-pages" displayButtons={"false"}>
      <Header className="mb-2" displayIcon={"false"} title={"Forgot Password"}></Header>
      <Row className='align-items-center h-100'>
        <Col className='p-2'>
          {!success ? (
            <>
              <p className="text-center text-sm mb-4">
                <Trans>
                  Enter your email address below to set or reset your password
                </Trans>
              </p>
              <FormikProvider value={formik}>
                <Form className="mt-4">
                  <div className="input-wrapper">
                    <label className="input-label">
                      Email
                    </label>
                    <Field
                      className="input"
                      name="email"
                      validate={(v) => !isEmail(v)}

                    />
                  </div>
                  <PendingButton className="float-right rounded-md h-10" color="success" pending={formik.isSubmitting}>
                    Submit
                  </PendingButton>
                </Form>
              </FormikProvider>
            </>
          ) : (
            <>
              <div className="text-center mb-4">
                <p>
                  <Trans>
                    We have sent you an email containing a link to reset your
                    password.
                  </Trans>
                </p>
                <p>
                  <Trans>
                    If you don't get an email check your spam folder or try
                    again.
                  </Trans>
                </p>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default ResetPasswordRequest;
