import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { useClient } from 'hooks';
import { Row, Col } from 'reactstrap';
import { Page, DecisionButton, Header, cipher, Footer } from "components/common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "./SelfFeedback.scss"
import LoadingSpinner from 'components/common/LoadingSpinner';

export const ElementsStatementsQuiz = () => {
	const client = useClient();
	const [elements, setElements] = useState([]);
	const pageHead = "Self Assessment";
	const flavourText = "These basic aims guide you to work towards becoming a 'fully-functioning' person, rather than working on an aim that may not be helpful. Choose one to work with, or if they don't seem right, go back to the Start page and try a different button.  You can also save aims in your notebook to come back to later."
	const { state } = useLocation();
	const questionIds = state?.questionIds
	const origin = "quiz"

	useEffect(() => {
		async function load() {
			const questionStatements = await client.get("aimstatement?id__in=" + questionIds).get("data");
			const S = new Set(questionIds.map(e => e.toString()));
			const statements = questionStatements.filter(e => S.has(e.id.toString()));
			const elements = statements.map(e => e?.element_obj).filter(e => !!e);
			const elementSet = new Set(elements.map(e => e?.id));
			const elementLookup = elements.reduce((prev, curr) => {
				prev[curr?.id] = curr;
				return prev;
			}, {});
			setElements(Array.from(elementSet).map(e => elementLookup[e]));
		}
		try {
			load();
		}
		catch (exc) {
			console.warn(exc);
			NotificationManager.error("Could not retrieve the aim statements. Please try again.");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Page background="journey_four_elements" footer={<Footer textToDisplay={flavourText} displayButtons={"true"}></Footer>}>
			<Breadcrumb>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_start", style: { color: "darkblue" } }}>
					Think
				</Breadcrumb.Item>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_self_assessment" }}>
					Self Assessment
				</Breadcrumb.Item>
			</Breadcrumb>
			<Header className="mb-2" displayIcon={"false"} title={pageHead}></Header>
			<Row className='align-items-center h-100 mt-2'>
				<Col className='i-know-card text-center'>

					<>
						<Col xs={'8'} id="footer-area" className="bg-white rounded border border-dark pb-3 m-auto" style={{ fontSize: "14px" }}>

							<br /><h6><b>Instructions</b></h6>

							Select the aim that you would like to work towards in your self-therapy
						</Col>
					</>

					{elements && elements.length > 0 ?
						<>
							{elements.map((element, idx) => (
								<Col sm={'12'} md={'8'} xl={'6'} className="justify-content-md-center w-100 m-auto" >
									<DecisionButton
										keyName={idx}
										link={`/aim_reflection/${cipher.obfuscate(element.id)}`}
										name={element.name}
										description={""}
										origin={origin}
										// tag={Div}
										canPersonalise={true}
										element={element}
									/>
								</Col>
							))}
						</>
						: <LoadingSpinner />}
				</Col>
			</Row>
		</Page>
	);
};

export default ElementsStatementsQuiz;
