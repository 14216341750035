import React, { useRef } from "react";
import { Loading } from "./Loading";
import html2canvas from "html2canvas";
import download from "downloadjs";
import { Button, Card, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { isIE } from "react-device-detect";

export const ReportContainer = ({ title, subtitle, loading, children }) => {
  const downloadRef = useRef();

  async function onDownload() {
    const element = downloadRef.current;

    if (isIE) {
      global.html2canvas(element, {
        onrendered: (canvas) => {
          download(canvas.toDataURL(), "self-therapist.png");
        },
      });
    } else {
      const chart = await html2canvas(element, {
        scrollX: 0,
        scrollY: -window.scrollY,
      });
      download(chart.toDataURL(), "self-therapist.png");
    }
  }

  const base = (children) => (
    <Card body className="mb-4 border-brand-light">
      <div ref={downloadRef} className="bg-white">
        <Row form className="align-center">
          <Col>
            <h3 className="text-lg font-medium text-brand-dark mb-2">
              {title}
            </h3>
            <h3 className="text-sm font-medium text-brand-dark mb-4">
              {subtitle}
            </h3>
          </Col>
          <Col md="auto" className="text-center -mt-4 mb-2 md:mt-0 md:mb-0">
            <Button onClick={onDownload} color="link">
              Download{" "}
              <FontAwesomeIcon icon={faCloudDownloadAlt} />
            </Button>
          </Col>
        </Row>
        {children}
      </div>
    </Card>
  );

  if (loading) return base(<Loading />);

  return base(<div>{children}</div>);
};

export default ReportContainer;
