import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import i18n from "./config/i18n";
import ErrorBoundary from "./components/ErrorBoundary";
import { I18nextProvider } from "react-i18next";
import { NotificationContainer } from "react-notifications";
import { RecoilRoot, useTransactionObservation_UNSTABLE } from "recoil";
import { persistedAtoms } from "./atoms";
import { UserAtomEffect } from "./atomEffects";
import { QueryClient, QueryClientProvider } from "react-query";

import "assets/css/bootstrap.min.css";
import "assets/fonts/dm-sans/stylesheet.css";
import "assets/css/select2.min.css";
import "react-step-progress-bar/styles.css";
import "assets/css/style.css";
import 'react-reorderable-list/dist/index.css'

const PersistenceObserver = () => {
  useTransactionObservation_UNSTABLE(({ atomValues, modifiedAtoms }) => {
    for (const modifiedAtom of modifiedAtoms) {
      let value = atomValues.get(modifiedAtom);
      if (value === undefined) {
        localStorage.removeItem(modifiedAtom);
      } else {
        localStorage.setItem(modifiedAtom, JSON.stringify(value));
      }
    }
  });
  return null;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      retry: false,
      structuralSharing: false,
      useErrorBoundary: true,
    },
    mutations: {
      retry: false,
      useErrorBoundary: true,
    },
  },
});
export const App = () => {
  return (
    <Suspense fallback={null}>
      <RecoilRoot
        initializeState={({ set }) => {
          for (const atom of persistedAtoms) {
            const key = atom.key.replace(/__withFallback$/, "");

            if (localStorage.hasOwnProperty(key)) {
              try {
                const value = JSON.parse(localStorage[key]);
                set(atom, value);
              } catch (ex) {
                console.error(ex);
              }
            }
          }
        }}
      >
        <PersistenceObserver />
        <I18nextProvider i18n={i18n}>
          <NotificationContainer />
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <UserAtomEffect />
              <ErrorBoundary>
                <Suspense fallback={null}>
                  <Routes />
                </Suspense>
              </ErrorBoundary>
            </BrowserRouter>
          </QueryClientProvider>
        </I18nextProvider>
      </RecoilRoot>
    </Suspense>
  );
};

export default App;
