import React, { useEffect, useState } from "react";
import { Row, Col, Container, Media } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { HighlightModal, AddNoteModal, cipher } from "components/common";
import { useUserContext } from 'hooks';
import { NotificationManager } from "react-notifications";
import Button from "./Button";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
export const QuestionBox = ({
  pending,
  keyName,
  link,
  question,
  whole_question,
  prompt,
  follow_up,
  end,
  children,
  currentSlide,
  setCurrentSlide,
  chosenElement,
  client,
  userNotes,
  searchWords = [],
  ...props
}) => {
  const [showSuggestedResponses, setShowSuggestedResponses] = useState([false]);
  const [showFollowUp, setShowFollowUp] = useState([false]);
  const [createNoteModalOpen, setCreateNoteModalOpen] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [_userNotes, setUserNotes] = useState(userNotes);
  const [currentPersonalisedAim, setCurrentPersonalisedAim] = useState();
  const [useFollowupAsQuestion, setUseFollowupAsQuestion] = useState(false);

  const navigate = useNavigate();
  const { user } = useUserContext();

  const changeShowSuggestedResponses = () => {
    if (showSuggestedResponses === false) {
      setShowSuggestedResponses(true);
    }
    else {
      setShowSuggestedResponses(false);
    }
  };

  const changeShowFollowUp = () => {
    if (showFollowUp === false) {
      setShowFollowUp(true);
    }
    else {
      setShowFollowUp(false);
    }
  };

  const changeShowNotes = () => {
    if (showNotes === false) {
      setShowNotes(true);
    }
    else {
      setShowNotes(false);
    }
  };

  const findOrCreatePersonalisedAim = async () => {
    try {
      const data = await client.get(`personalisedaim?Element_id=${chosenElement.id}`).get("data");
      if (data.length > 0) {
        setCurrentPersonalisedAim(data[0]);
      }
      else {
        const newAim = await client.post('personalisedaim', {
          Element_id: chosenElement.id,
        }).get("data");
        setCurrentPersonalisedAim(newAim);
      }
    }
    catch (e) {
      NotificationManager.error("Something went wrong. Please try again.");
    }
    finally {
      setCreateNoteModalOpen(true);
    }
  };



  useEffect(() => {
    changeShowSuggestedResponses();
    changeShowFollowUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const baseClasses = " align-content-stretch m-auto mobile-padlow";
  return (
    <Col sm={'11'} className="justify-content-md-center m-auto rounded" >
      <>
        <Row className="question-btn">
          <Col xs={'12'} md={'10'} className={classnames(baseClasses)} >

            <HighlightModal
              searchWords={user?.organisation != null && user?.organisation?.popups_enabled === false ? [] : searchWords}>
              <b>{question}</b>
            </HighlightModal>
          </Col>
          <Col xs={'3'} md={'2'} className={classnames(baseClasses, 'h-100')} >
            <Media className="clicky" object src={'/icons/browse.png'} width={75} height={75} aria-label={"Add a new note to question"} onClick={async () => {
              setUseFollowupAsQuestion(false);
              findOrCreatePersonalisedAim()
            }
            } />
          </Col>
        </Row>
        {!!prompt && (
          <Row className="main-btn" onClick={() => changeShowSuggestedResponses()}>
            <Col xs={'12'} md={'3'}>
              <div>
                Show some sample responses
              </div>
            </Col>
            <Col xs={'12'} md={'7'} className={classnames(baseClasses, 'd-flex', !!showSuggestedResponses ? 'sample-green' : 'blurred-text')}>
              <b style={{ fontSize: "1rem" }}>{prompt}</b>
            </Col>
          </Row>
        )}
        {!!follow_up && (
          <Row className="main-btn" onClick={() => changeShowFollowUp()}>
            <Col xs={'12'} md={'3'}>
              <div>
                Show follow-up question
              </div>
            </Col>
            <Col xs={'12'} md={'7'} className={classnames(baseClasses, !!showFollowUp ? '' : 'blurred-text')}>
              {!!showFollowUp ? (
                <HighlightModal
                  searchWords={user?.organisation != null && user?.organisation?.popups_enabled === false ? [] : searchWords}
                >
                  <b style={{ fontSize: "1rem" }}>{follow_up}</b>
                </HighlightModal>

              ) : (
                <p>
                  <b style={{ fontSize: "1rem" }}>{follow_up}</b>
                </p>

              )}
            </Col>
            <Col xs={'3'} md={'2'} className={classnames(baseClasses, 'h-100')} >
              <Media className="clicky" object src={'/icons/browse.png'} width={75} height={75} aria-label={"Add a new note to follow-up question"} onClick={async () => {
                setUseFollowupAsQuestion(true);
                findOrCreatePersonalisedAim()
              }
              } />
            </Col>
          </Row>
        )}

        {keyName === end && (
          <div className="d-flex align-content-stretch justify-content-center flex-wrap">
            <Button color="primary" className="btn-primary main-btn my-3 border-0 text-white h-15" onClick={() => navigate(`/element_chosen/${cipher.obfuscate(chosenElement.id)}`)}>Questions completed <br /> Try a different type</Button>
          </div>
        )}

        {keyName === end && (

          <div className="d-flex align-content-stretch justify-content-center flex-wrap">
            <a href="https://forms.gle/roDiCP3BepNaCDcn7" target="_blank" rel="noopener noreferrer">
              <Button color="primary" className="btn-primary main-btn my-3 border-0 text-white h-10">User's Feedback</Button>
            </a>
            {/* <a href="https://forms.gle/EMfQHR9KYyfg8XgS8" target="_blank" rel="noopener noreferrer">
                  <Button color="primary" className="btn-primary main-btn my-3 border-0 text-white h-10">Therapist Feedback</Button>
                </a> */}
          </div>
        )}


        <div className="d-flex align-content-stretch justify-content-center flex-wrap" style={{ flexDirection: 'row-reverse' }}>
          {keyName !== end && (
            <Button color="primary" className="btn-primary main-btn my-3 border-0 text-white h-10" onClick={() => setCurrentSlide(c => c + 1)} >
              Next Question
              <FontAwesomeIcon icon={faChevronCircleRight} className="ml-2" />
            </Button>
          )}
          {keyName !== 1 && (
            <Button color="primary" className="btn-primary main-btn my-3 border-0 text-white h-10" onClick={() => setCurrentSlide(c => c > 0 ? c - 1 : 0)} >
              <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-2" />
              Previous Question
            </Button>
          )}
        </div>

        <div className="d-flex align-content-stretch justify-content-center flex-wrap">
          {!!_userNotes && _userNotes.length > 0 &&
            <Button color="primary" className="btn-primary main-btn my-3 border-0 text-white h-10" onClick={() => changeShowNotes()} >{showNotes ? "Hide" : "Show"} previous thoughts</Button>
          }
        </div>
      </>


      {!!showNotes && _userNotes.length > 0 &&
        <Container className="thinking-notes-container pb-4" style={{ background: "#FFFFFF" }}>
          {/* <h3 className="pt-3">Notes</h3> */}
          {(_userNotes.length > 0) ? Array.from(_userNotes.sort()).map((note, index) => (
            <>
              <div class="chat-container">
                <p></p>
                <span class="time-right-questions">{note.Question__Name}</span>
                <p className="pt-4" id={index}>{note.thought}</p>
                <span class="time-right-questions">{new Date(note?.last_updated).toLocaleDateString()}</span>
              </div>
            </>
          )) :
            <h5 className="mt-3"><b>
              No notes added, use "New Note" below to start
            </b></h5>
          }
        </Container>

      }

      <AddNoteModal
        open={createNoteModalOpen}
        setOpen={setCreateNoteModalOpen}
        selectedQuestion={whole_question}
        chosenElement={chosenElement}
        showQuestionSelect={false}
        selectedPersonalisedAim={currentPersonalisedAim ? currentPersonalisedAim : null}
        setUserNotes={setUserNotes}
        useFollowupAsQuestion={useFollowupAsQuestion}
      />

    </Col >
  );
};

export default QuestionBox;
