import React, {useState, useEffect, useCallback} from "react";
import { Button, Input as ReactStrapInput } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import { useClient } from '../../hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { FormGroup, Select, Input } from '../../components/react-hook-form';
import { NotificationManager } from "react-notifications";
import AddButton from "./AddButton";

export const AddNoteModal = ({
  open,
  setOpen,
  selectedQuestion,
  selectedPersonalisedAim,
  showQuestionSelect,
  chosenElement,
  setUserNotes,
  reloadAim,
  useFollowupAsQuestion,
  children,
  ...props
}) => {
  const client = useClient();
  const [questions, setQuestions] = useState([]);
  const selectedAim = selectedPersonalisedAim ? selectedPersonalisedAim?.id : null;
  const Element = selectedPersonalisedAim ? selectedPersonalisedAim?.Element : null;
  const [ count, setCount ] = useState(0);
  const methods  = useForm({
    defaultValues: {
      Question: selectedQuestion,
      thought: ''
    },
		mode: 'all',
	});
	const {
		handleSubmit,
		formState: { errors },
		setError,
    setValue,
    reset
	} = methods;
  const loadQuestions = useCallback(() => {
    async function load() {
      try {
        const data = await client.get(`question?element=${Element?.id ?? chosenElement?.id}`).get("data");
        const questions = data.map(q => ({
          value: q.id,
          label: q.question
        }));
        questions.push({value: null, label: 'Generic Note'});
        setQuestions(questions);
      }
      catch (exc) {
        console.warn(exc);
        NotificationManager.error("Questions could not be retrieved!");
      }
    }
    load();
  // eslint-disable-next-line
  }, [Element?.id, client, chosenElement]);

  useEffect(() => {
    if(showQuestionSelect && !selectedQuestion){
      loadQuestions();
    }
	}, [loadQuestions, selectedPersonalisedAim, showQuestionSelect, selectedQuestion]);

  const handleClose = () => {
    setOpen(false);
    setCount(0);
    reset();
    if (reloadAim) reloadAim(selectedPersonalisedAim?.id);
  };

  const recount = e => {
    setCount(e.target.value.length);
    setValue("thought", e.target.value, false);
  };

  const onSubmit = (formData) => {
    if(typeof formData.Question === "object"){
        formData.Question = formData.Question?.value ?? selectedQuestion?.id ?? null;

    }
    if(useFollowupAsQuestion){
      formData.is_followup = true;
    }
		client
			.post('/usernote', {aim: selectedAim, ...formData})
			.then((responseData) => {
        NotificationManager.success('Your note has been added successfully!');
        if(selectedQuestion != null){
          setUserNotes(r => {
            if (r) {
              return [responseData.data, ...r];
            }
            return [responseData.data];
          });
        }
        handleClose();
			})
			.catch((ex) => {
				const errorMessages = ex?.response?.data?.detail
				if(errorMessages){
					for (const [key, value] of Object.entries(errorMessages)) {
						setError(key, { message: value[0] });
					}
				}

				NotificationManager.error('An error occurred. Please try again.');
			});
	};


  return(
    <FormProvider {...methods}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Modal show={open} onHide={handleClose} className="modal-md" {...props}>
          <Modal.Header closeButton>
            <Modal.Title>Add a new note for this question</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!!showQuestionSelect ? (
              <FormGroup label={"Question"} name={"Question"}>
                <Select
                  defaultValue={selectedQuestion}
                  name={"Question"}
                  rules={{
                    required: false,
                  }}
                  styles = {{ 
                    singleValue: () => ({
                      paddingTop: '5px'
                    }),
                  }}
                  options={questions}
                  placeholder='Generic Note'
                />
              </FormGroup>
            ): (
              <>
                {selectedQuestion && (useFollowupAsQuestion ? selectedQuestion.follow_up : selectedQuestion.question)}
              </>
            )}
            <FormGroup name="thought">
              <Input
                component={ReactStrapInput}
                type='textarea'
                className='form-control'
                name={'thought'}
                rows={10}
                placeholder='Your thoughts?'
                rules={{
                  required: true,
                  maxLength: 4096,
                }}
                onChange={recount}
              />
            {errors.thought && 
              <div className='alert alert-danger mt-1 mb-0 p-2'>
                {errors.thought?.type === 'maxLength' && ("Sorry, your note exceeds the maximum amount of 4096 characters!")}
                {errors.thought?.type === 'required' && ("Sorry, your note cannot be blank")}

              </div>
            }
            
            </FormGroup>
            <p style={{textAlign: 'right', fontSize: '10px'}}>
              {count > 4096 ? (
                <i style={{color: 'red'}}>{count}/4096</i>
                ):
                <i style={{color: 'black'}}>{count}/4096</i>
              }
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button aria-label="Close" className="rounded-lg" color="danger" onClick={handleClose}>
              Close
            </Button>
            <AddButton aria-label="Add" className="rounded-lg px-8" color="success" onClick={handleSubmit(onSubmit)}>
              Add
            </AddButton>
          </Modal.Footer>
        </Modal>
      </form>
    </FormProvider>
  )
}


export default AddNoteModal;
