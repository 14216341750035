import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { Page, Footer, Header, AddNoteModal, EditNoteModal, EditFeedbackAimModal, AddButton, cipher } from "components/common";
import { useClient } from 'hooks';
import { Row, Col, Container, Media, Button } from 'reactstrap';
import { faChevronCircleLeft, faChevronCircleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const ThinkingPlace = () => {
	const client = useClient();
	const navigate = useNavigate();
	const [currentPersonalisedAim, setCurrentPersonalisedAim] = useState();
	const [next, setNext] = useState();
	const [prev, setPrev] = useState();
	const [createNoteModalOpen, setCreateNoteModalOpen] = useState(false);
	const [editFeedbackAimModalOpen, setEditFeedbackAimModalOpen] = useState(false);
	const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);
	const [currentNote, setCurrentNote] = useState(null);

	const flavourText = "This page is for you to make personal notes. You could use the space to make a note of personal insights, things to remember, points to think through in more details, or issues to talk about to someone else."
	const { id } = useParams();

	async function reloadAim(id) {
		if (!id) return;
		try {
			const aim = await client.get(`personalisedaim/${id}`).get("data");
			setCurrentPersonalisedAim(aim);
		}
		catch (e) {
			NotificationManager.error("Sometihng went wrong when reloading data");
		}
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	function loadData() {
		client.get(`personalisedaim`).get("data").then(e => {
			reloadAim(id);
			// Force same order of position.
			e.sort(function (a, b) { return a.id - b.id });
			if (id) {
				const idx = e.findIndex(x => parseInt(x.id) === parseInt(id));
				setPrev(e[idx - 1]?.id);
				setNext(e[idx + 1]?.id);
			}
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve personalised aims");
		})
		// eslint-disable-next-line
	}

	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		// <Page title={t("Accept invite")} translate={false} background='admin-pages'>
		<Page background="notebook"
			footer={<Footer textToDisplay={flavourText} displayButtons={"true"}></Footer>}>
			<Header className="mb-2" displayIcon={"false"} title={"Notebook"}></Header>
			{!!currentPersonalisedAim ? (
				<>
					<Container className="thinking-aim-container p-4">
						<div class="d-flex justify-content-between">
							<Button
								color="primary"
								disabled={!prev}
								className="h-50 border-0 text-white mr-2"
								onClick={() => navigate('../' + prev)}
								aria-label="Previous"
							>
								<FontAwesomeIcon icon={faChevronCircleLeft} className="" />
								{/* Previous */}
							</Button>
							<Button
								color="primary"
								disabled={!next}
								className="h-50 border-0 text-white"
								onClick={() => navigate('../' + next)}
								aria-label="Next"
							>
								{/* Next */}
								<FontAwesomeIcon icon={faChevronCircleRight} className="" />
							</Button>
						</div>
						<Row className='align-items-center mt-4'>
							<Col className='i-know-card text-center'>
								{/* <h4 className="my-4"><b>{pageHead}</b></h4> */}
								<Row className="notebook-padding pt-4">
									<Col sm={'5'} md={'4'} xl={'3'}>
										<Link style={{ color: 'black' }} to={"/element_chosen/" + cipher.obfuscate(currentPersonalisedAim?.Element?.id)}><h5 className="">Aim {currentPersonalisedAim?.Element?.key}</h5></Link>
									</Col>
									{/* <Col sm={'7'} md={'8'} xl={'9'}> */}
									<Col sm={'6'} md={'7'} xl={'8'}>
										<Link
											style={{ color: 'darkblue' }}
											to={"/element_chosen/" + cipher.obfuscate(currentPersonalisedAim?.Element?.id)}
										>
											<h5 className="">
												{currentPersonalisedAim?.Element?.name}{' '}
												<FontAwesomeIcon size="sm" icon={faArrowRight} className="clicky mobile-padless" />
											</h5>
										</Link>
									</Col>
									<Col sm={'1'} className="">
										{/* <Link
											style={{ color: 'darkblue' }}
											to={"/element_chosen/" + cipher.obfuscate(currentPersonalisedAim?.Element?.id)}
										>
										</Link> */}
									</Col>
								</Row>
								<Row className="notebook-padding ">
									<Col sm={'5'} md={'4'} xl={'3'}>
										<h5 className="">My specific aim is to: </h5>
									</Col>
									<Col sm={'6'} md={'7'} xl={'8'} className="rounded">
										{!!currentPersonalisedAim.personalised_aim_text ? (
											<h5 className="aimbot"> {currentPersonalisedAim.personalised_aim_text}</h5>
										) :
											<h5 className="clicky pb-2" onClick={() => setEditFeedbackAimModalOpen(true)} style={{ background: "#FFF", border: "0px", color: "grey", opacity: "0.5", fontSize: '1px' }}>
												_<Media className="clicky mobile-padless" object src={'/icons/pencil.png'} width={30} style={{ maxWidth: '36px', float: 'right' }} height={30} onClick={() => setEditFeedbackAimModalOpen(true)} />
											</h5>
										}
									</Col>
									<Col sm={'1'} className="rounded d-flex justify-center align-items-center">
										{/* <Media className="clicky mobile-padless" object src={'/icons/pencil.png'} width={36} style={{ maxWidth: '36px' }} height={36} onClick={() => setEditFeedbackAimModalOpen(true)} /> */}
									</Col>
								</Row>

								<div className="notebook-metrics-container">
									<div className="first pr-10">Ratings out of 10:</div>
									<div>Negative effect before</div>
									<div className="notebook-highlight-1 number clicky" onClick={() => setEditFeedbackAimModalOpen(true)}>
										{currentPersonalisedAim.before_negative === 0 ? (
											<Media className="clicky mobile-padless" object src={'/icons/red-exclamation-mark.png'} />
										) :
											<>
												{currentPersonalisedAim.before_negative}
											</>
										}
									</div>
									<div>Negative effect after</div>
									<div className="notebook-highlight-1 number clicky" onClick={() => setEditFeedbackAimModalOpen(true)}>
										{currentPersonalisedAim.after_negative === 0 ? (
											<Media className="clicky mobile-padless" object src={'/icons/red-exclamation-mark.png'} />
										) :
											<>
												{currentPersonalisedAim.after_negative}
											</>
										}
									</div>

									<div>Managing before</div>
									<div className="notebook-highlight-2 number clicky" onClick={() => setEditFeedbackAimModalOpen(true)}>
										{currentPersonalisedAim.before_progress === 0 ? (
											<Media className="clicky mobile-padless" object src={'/icons/red-exclamation-mark.png'} />
										) :
											<>
												{currentPersonalisedAim.before_progress}
											</>
										}
									</div>
									<div>Managing after</div>
									<div className="notebook-highlight-2 number clicky" onClick={() => setEditFeedbackAimModalOpen(true)}>
										{currentPersonalisedAim.after_progress === 0 ? (
											<Media className="clicky mobile-padless" object src={'/icons/red-exclamation-mark.png'} />
										) :
											<>
												{currentPersonalisedAim.after_progress}
											</>
										}
									</div>
								</div>

							</Col>

						</Row>

					</Container>

					<Container className="thinking-notes-container notebook-padding pb-4">
						<h5 className="d-inline-block">My Notes</h5>
						<AddButton color="success"
							className="h-100 float-right min-120 border-0 text-white"
							onClick={() => setCreateNoteModalOpen(true)}>
							New Note
						</AddButton>
						{(currentPersonalisedAim?.notes?.length > 0) ? Array.from(currentPersonalisedAim.notes.sort()).reverse().map((note, index) => (
							<div className='my-4' key={`aim-${index}`}>
								<div className="chat-container notebook-notes">
									<h6 className="time-right d-inline-block">{new Date(note?.last_updated).toLocaleDateString()}</h6>
									<Media className="clicky float-right" style={{ 'width': '24px' }} object src={'/icons/pencil.png'} onClick={() => [setCurrentNote(note), setEditNoteModalOpen(true)]} />

									{note.Question == null ? (
										<span className="time-right float-right">{note.Question__Name}</span>
									) : (
										<Link
											to={"/question_list/" + cipher.obfuscate(currentPersonalisedAim?.Element?.id) + "/" + note.Question__Group}
											state={{ question_id: note.Question }}
										>
											<span className="time-right clicky float-right">
												{note.Question__Name}
												{" "}
												<FontAwesomeIcon size="sm" icon={faArrowRight} className="" />
											</span>
										</Link>

									)}

									<p className="notebook_table-text" id={index}>{note.thought}</p>

									{/* <div className="main-btn question-grid-items" style={{background: "#007bff", border: "0px", color: "white"}} onClick={() => setEditFeedbackAimModalOpen(true)} >Edit</div> */}
								</div>
								<div className="squiggle-holder">
									<div className="squiggle-tinyLine squiggle-tinyLine1"></div>
									<div className="squiggle-tinyLine squiggle-tinyLine2"></div>
								</div>
							</div>
						)) :
							<div className="alert alert-info text-center p-6" role="alert" style={{ opacity: "0.5" }}>
								{"Use the New Note button to add a note, or add them from the questions pages"}
							</div>
						}
					</Container>




					<AddNoteModal
						open={createNoteModalOpen}
						setOpen={setCreateNoteModalOpen}
						selectedQuestion={null}
						showQuestionSelect={true}
						selectedPersonalisedAim={currentPersonalisedAim}
						reloadAim={reloadAim} />
					{!!currentNote && (
						<EditNoteModal
							open={editNoteModalOpen}
							setOpen={setEditNoteModalOpen}
							selectedNote={currentNote}
							selectedPersonalisedAim={currentPersonalisedAim}
							reloadAim={reloadAim} />
					)}
					<EditFeedbackAimModal
						open={editFeedbackAimModalOpen}
						setOpen={setEditFeedbackAimModalOpen}
						selectedPersonalisedAim={currentPersonalisedAim}
						reloadAim={reloadAim} />
				</>
			) : (
				<>
					<div className="alert alert-warning text-center p-6 mt-3" role="alert">
						{"No current personalised aims. Use the Start button above and add some aims to your account!"}
					</div>
					{/* <div className="main-btn ml-2 mr-2 question-grid-items" style={{background: "#007bff", border: "0px", color: "white"}} onClick={() =>navigate(`/decision_tree_start`)} >Start now!</div> */}
				</>

			)}

		</Page>
	);
};

export default ThinkingPlace;
