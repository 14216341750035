import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { LoadingSpinner, DecisionButton } from "components/common";

import { useClient } from 'hooks';
import { Row, Col } from 'reactstrap';
import { Page, Footer, StepProgressBar, Header, cipher } from 'components/common';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export const ChooseBroad = () => {
	const client = useClient();
	const [categorygroups, setCategoryGroups] = useState([]);
	const flavourText = "We’re helping you to narrow down your thinking through a decision tree."
	const pageHead = "I would like to think about..";

	useEffect(() => {

		client.get("categorygroup").then(e => {
			setCategoryGroups(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve category groups. Please try again.");
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Page background="journey_two" footer={<Footer textToDisplay={flavourText} displayButtons={"true"}></Footer>}>
			<Breadcrumb>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_start", style: { color: "darkblue" } }}>
					Think
				</Breadcrumb.Item>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_options", style: { color: "darkblue" } }}>
					Choose Path
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					Decide Group
				</Breadcrumb.Item>
			</Breadcrumb>
			<StepProgressBar percent={25} />
			<Header className="mb-2" displayIcon={"false"} title={pageHead}></Header>
			<Row className='align-items-center h-100 mt-2'>
				<Col className='i-know-card text-center'>
					<Col sm={'12'} md={'8'} xl={'6'} className="justify-content-md-center w-100 m-auto" >
						{!!categorygroups ? categorygroups.map((category, index) => (
							<DecisionButton
								keyName={index}
								link={category.id === 3 ? `/choose_quality/${cipher.obfuscate(3)}` : `/choose_specific/${cipher.obfuscate(category.id)}`}
								origin="flowTwo"
								//ID 3 corresponds to the relationship broad/specific ID, this is used to skip the specific page as there is only one option available
								//Origin is also set here in advance as it controls the styling further down
								name={category.name}
								description={category.description}
							/>
						)) : <LoadingSpinner />}
					</Col>
				</Col>
			</Row>
		</Page>
	);
};

export default ChooseBroad;
