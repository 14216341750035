import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { LoadingSpinner, DecisionButton, StepProgressBar, Page, Footer, Header, Breadcrumbs, cipher } from "components/common";
import { useClient } from 'hooks';
import { Row, Col } from 'reactstrap';

export const ChooseElement = () => {
	const client = useClient();
	const params = useParams();
	const [elements, setElements] = useState([]);
	const flavourText = "These basic aims guide you to work towards becoming a 'fully-functioning' person, rather than working on an aim that may not be helpful. Choose one to work with, or if they don't seem right, go back to the Start page and try a different button.  You can also save aims in your notebook to come back to later."
	const { state } = useLocation();
	const origin = state?.from
	const pageHead = state?.from === "flowTwo" ? "My aim is to describe myself this way" : "My aim is to describe myself this way";
	useEffect(() => {

		client.get(`element?quality=${cipher.deobfuscate(params['quality_id'])}`).then(e => {
			setElements(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve elements. Please try again.");
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Page background="element" footer={<Footer textToDisplay={flavourText} displayButtons={"true"}></Footer>}>
			<Breadcrumbs
				parent={'Decide Quality'}
				current={'Decide Aim'}
			/>
			<StepProgressBar percent={100} />
			<Header className="mb-2" displayIcon={"false"} title={pageHead}></Header>
			<Row className='align-items-center h-100 mt-2'>
				<Col className='i-know-card text-center'>
					<Col sm={'12'} md={'8'} xl={'6'} className="justify-content-md-center w-100 m-auto" >
						{!!elements ? elements.map((element, index) => (
							<DecisionButton
								key={index}
								furtherClass={"aimbot"}
								keyName={index}
								link={`/aim_reflection/${cipher.obfuscate(element.id)}`}
								name={element.name}
								description={""}
								origin={origin}
								canPersonalise={true}
								element={element}
							/>
						)) : <LoadingSpinner />}
					</Col>
				</Col>
			</Row>
		</Page>
	);
};

export default ChooseElement;
