import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useClient } from "../../hooks";
import { Page, Header } from 'components/common';
import { NotificationManager } from 'react-notifications';

import {
  Input,
  FormGroup,
  SubmitButton,
} from "../../components/react-hook-form";

export const EditQualityStatement = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;
  const isNew = !id || id === 'create';
  const methods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit, setValue } = methods;
  const client = useClient();
  const [data, setData] = useState();

  useEffect(() => {
    if (!isNew) {
      client.get(`qualitystatement/${id}`).then(({ data }) => {
        setData(data);
      }).catch(exc => {
        console.warn(exc);
        NotificationManager.error("Could not retrieve your quality statement");
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (data) {
      setValue('key', data.key);
      setValue('statement', data.statement);
    }
  }, [data, setValue]);


  async function onSubmit(x) {
    if (isNew) {
      client
        .post(`qualitystatement`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    } else {
      client
        .put(`qualitystatement/${id}`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    }

  }

  return (
    <Page background="admin">
      {!!isNew ? (
        <Header className="mb-2" displayIcon={"false"} title={`New Quality Statement`} />
      ) : (
        <Header className="mb-2" displayIcon={"false"} title={`Edit Quality Statement`} />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label={"Key"} name={"key"} required>
            <Input
              type='text'
              className='form-control'
              name={'key'}
              placeholder='Key'
              rules={{
                required: true,
                maxLength: 255
              }}
            />
          </FormGroup>
          <FormGroup label={"Statement"} name={"statement"}>
            <Input
              type='textarea'
              className='form-control'
              name={'statement'}
              placeholder='Statement'
              rules={{
                required: false,
                maxLength: 16860
              }}
            />
          </FormGroup>

          <div className="flex justify-between">
            <SubmitButton color="primary">
              {isNew ? "Create" : "Save"}
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};
export default EditQualityStatement;
