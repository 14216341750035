import React, { useState, useEffect } from "react";
import { Row, Col, Button } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';

export const StatementsModal = ({
    open,
    setOpen,
    parentElement,
    onSubmitParent,
    children,
    ...props
}) => {
    const [regularStatements, setRegularStatements] = useState([]);
    const [oppositeStatements, setOppositeStatements] = useState([]);
    const handleClose = () => {
        setOpen(false);
        onSubmitParent();

    };

    useEffect(() => {
        const statements = parentElement?.statements ?? [];
        setRegularStatements(statements.filter(e => !e.is_opposite));
        setOppositeStatements(statements.filter(e => e.is_opposite));
    }, [parentElement]);

    return (
        <>
            <Modal show={open} onHide={handleClose} {...props}>
                <Modal.Header closeButton>
                    <Modal.Title>{!!parentElement && parentElement.name}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <label>If you answer yes to quite a lot of these questions, you may benefit from choosing this aim.</label>
                    <Row>
                        <Col sm={'12'} style={{ background: "#F8C8DC" }}>
                            {!!regularStatements ? regularStatements.map((statement, index) => (
                                <Row className="mt-2 pl-1 pt-1" key={index}>
                                    <Col sm={'9'}>
                                        <label>{statement.statement}</label>
                                    </Col>
                                    <Col sm={'3'} style={{ verticalAlign: 'middle' }}>
                                        <input type="checkbox"></input>
                                    </Col>
                                </Row>
                            )) : null}
                        </Col>

                        {oppositeStatements.length > 0 ? (
                            <>
                                <h3 className="justify-content-center">OR</h3>
                                <Col sm={'12'} style={{ background: "#F8C8DC" }}>
                                    {!!oppositeStatements ? oppositeStatements.map((statement, index) => (

                                        <Row className="mt-2 pl-1 pt-1" key={index}>
                                            <Col sm={'9'}>
                                                <label>{statement.statement} </label>
                                            </Col>
                                            <Col sm={'3'}>
                                                <input type="checkbox"></input>
                                            </Col>
                                        </Row>
                                    )) : null}
                                </Col>
                            </>
                        ) : null}
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default StatementsModal;
