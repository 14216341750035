import React from 'react';
import { NotificationManager } from "react-notifications";
import { FormProvider, useForm } from "react-hook-form";

import {
  Input,
  FormGroup,
} from "../../components/react-hook-form";
import { useClient, useUserContext } from 'hooks';
import { Button, Alert, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const BlockUserModal = ({ Modal, toggle, organisation }) => {
  const client = useClient();
  const { refreshUser } = useUserContext();
  const methods = useForm({
    defaultValues: {
      'Organisation': organisation.id,
      'EmailAddress': '',
    },
    mode: "onBlur",
  });
  const { handleSubmit, reset } = methods;

  const onEmailSubmit = (formData) => {
    client.post("invites/block_user", formData).then((resp) => {
      NotificationManager.success("Success!");
      reset({
        'Organisation': organisation.id,
        'EmailAddress': '',
      })
      toggle();
      refreshUser();
    }).catch(err => {
      console.error(err);
      const detail = err?.response?.data?.detail;

      if (detail) {
        NotificationManager.error(detail);
        return;
      } else {
        NotificationManager.error("Something went wrong. Please try again.");
      }
    });
  }

  return (
    <Modal>
      <ModalHeader closeButton>End User's Subscription</ModalHeader>
      <ModalBody>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onEmailSubmit)}>
            <FormGroup label={"Email"} name={"EmailAddress"} required>
              <Input
                type='text'
                className='form-control'
                name={'EmailAddress'}
                placeholder='Email'
                rules={{
                  required: false,
                }}
              />
            </FormGroup>

            <Alert color="info">
              This user will no longer have paid access, but can start their own subscription
            </Alert>
            <input className="btn btn-primary" type="submit" value="Submit" />
          </form>
        </FormProvider>

      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={() => toggle()}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default BlockUserModal;
