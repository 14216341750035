import React, { useState } from "react";
import { useNavigate } from 'react-router';
import { NotificationManager } from "react-notifications";
import { useClient } from '../../hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { FormGroup, SubmitButton, Input } from '../../components/react-hook-form';
import { Row, Col, Button } from 'reactstrap';
import { Page, Footer } from 'components/common';
import { CreateAccount } from './CreateAccount'
// CSS
import 'react-slideshow-image/dist/styles.css';
import 'assets/css/style.css';
import Dropzone from "react-dropzone";

export const SignupOrganisation = () => {
	const methods = useForm({
		defaultValues: {
			User: {
				username: '',
				email: '',
				password: '',
				password_confirm: ''
			},
			Organisation: {
				name: '',
				logo: '',
			}
		},
		mode: 'all',
	});
	const [logo, setLogo] = useState();

	const {
		handleSubmit,
		setValue,
		formState: { errors },
		setError,
	} = methods;

	const flavourText = "If you have any problems signing up, please email us at hello@self-therapist.com. Passwords need to be a minimum of 8 characters and a mix of numbers and letters"
	const client = useClient();
	const navigate = useNavigate();
	const [isPending, setIsPending] = useState(false);

	const onSubmit = (formData) => {
		if (formData.User.password !== formData.User.password_confirm) {
			setError('User.password_confirm', { type: 'Password mismatch', message: 'Passwords do not match' });
			return;
		}
		setIsPending(true);
		client
			.post('/organisation_signup', formData)
			.then((responseData) => {
				//Redirect to org admin panel?
				NotificationManager.success("You have successfully signed up, please log in to your account to get started.", "Welcome to Self Therapist.", 20000);
				navigate('/login');
			})
			.catch((ex) => {

				const status = ex?.response?.status;
				const errorMessages = ex?.response?.data?.detail
				if (status === 413) {
					NotificationManager.error("Logo uploaded is too large, please choose a smaller image and try again.");
					return;
				}
				if (errorMessages.User) {
					for (const [key, value] of Object.entries(errorMessages.User)) {
						//eslint-disable-next-line
						if (key == 'username') {
							setError('User.email', { message: value[0] });
						} else {
							setError('User.' + key, { message: value[0] });
						}
					}
				}
				if (errorMessages.Organisation) {
					for (const [key, value] of Object.entries(errorMessages.Organisation)) {
						setError('Organisation.' + key, { message: value[0] });
					}
				}
				NotificationManager.error('An error occurred with signing up, please contact +44 (0)1629 363357 if this persists.');
			}).finally(function () { setIsPending(false); });
	};

	return (
		<>
			<Page background="admin-pages" footer={<Footer textToDisplay={flavourText} displayButtons={"false"}></Footer>}>
				{/* <Container fluid='sm' className='col-sm-8 col-lg-5 h-100'> */}
				<Row className='align-items-center h-100'>
					<Col className='p-2'>
						<FormProvider {...methods}>
							<form onSubmit={(e) => e.preventDefault()}>
								{!!methods && (
									<CreateAccount methods={methods} accountType={'an Organisational'} />
								)}

								<FormGroup label={"Organisation Name"} name={"Organisation.name"}>
									<Input
										type='text'
										className='form-control'
										name={'Organisation.name'}
										placeholder='Organisation Name'
										rules={{
											required: true,
											maxLength: 255
										}} login-card
									/>
								</FormGroup>
								{errors.Organisation?.name && <div className='alert alert-danger mt-1 mb-0 p-2'>{errors.Organisation.name?.message}</div>}
								<FormGroup label="Logo" name="logo">
									<Dropzone
										multiple={false}
										className="border border-primary rounded"
										style={{}}
										onDrop={acceptedFiles => console.log(acceptedFiles)}
										onDropAccepted={files => {

											const reader = new FileReader();
											reader.onload = (e) => {
												setLogo(e.target.result);
												setValue('Organisation.logo', e.target.result);
											};
											files.forEach(file => reader.readAsDataURL(file));

											// reader.readAsDataURL(file);
										}}
									>
										{({ getRootProps, getInputProps }) => (
											<>
												{!!logo && (
													<div className="flex contain_upload">
														<img
															alt="logo"
															className="img-thumbnail mb-3"
															src={logo}
														/>
														<Button
															className="mt-1"
															onClick={() => {
																setLogo(null);
																setValue('Organisation.logo', null);
																// dispatch(actions.change("local.logo", null));
															}}
														>
															Clear
														</Button>
													</div>
												)}
												<div className="m-2 text-center border border-primary rounded">
													<div {...getRootProps()}>
														<input {...getInputProps()} />
														<p className="mt-3">Drag and drop, or click here to upload a file</p>
													</div>

												</div>
											</>)}
									</Dropzone>
								</FormGroup>
								{errors.Organisation?.logo && <div className='alert alert-danger mt-1 mb-0 p-2'>{errors.Organisation.logo?.message}</div>}
								<SubmitButton onClick={handleSubmit(onSubmit)} pending={isPending} style={{ background: "#007bff", border: "0px", color: "white", height: "40px" }} className="main-btn ml-2 mr-2 question-grid-items" aria-label="Press to register">Register</SubmitButton>

							</form>
						</FormProvider>
					</Col>
				</Row>
				{/* </Container> */}
			</Page>
		</>
	);
};

export default SignupOrganisation;
