import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useClient } from 'hooks';
import { NotificationManager } from "react-notifications";
import { Row, Col, FormGroup, Alert } from 'reactstrap';
import { LoadingSpinner, DecisionButton, Page, Footer, Header, QuickModal, cipher } from "components/common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import DebouncedInput from "components/common/DebouncedInput";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from 'reactstrap';

export const DecisionTreeIKnow = () => {
	const client = useClient();
	const aimsBox = { maxWidth: "75%", margin: "auto", height: "170px" }
	const [elements, setElements] = useState([]);
	const [commonIssueElements, setCommonIssueElements] = useState([]);

	const [search, setSearch] = useState();
	const flavourText = "These basic aims guide you to work towards becoming a 'fully-functioning' person, rather than working on an aim that may not be helpful. Choose one to work with, or if they don't seem right, go back to the Start page and try a different button.  You can also save aims in your notebook to come back to later."
	const pageHead = "Specifically, this is what I would like to work on";
	const origin = "flowOne"
	const [crisisModelOpen, setCrisisModelOpen] = useState(false);
	const [stopCrisisOpening, setStopCrisisOpening] = useState(false);

	function handleCrisis(search_term) {
		const s = search_term
			.toLowerCase();
		var wordsArray = ['crisis', 'die', 'suicide', 'kill', 'harm', 'self-harm', 'jump']
		return wordsArray.some(w => s.includes(w));
	}
	function setCrisisModelToggle(bool) {
		if (!bool) {
			setStopCrisisOpening(true);
		}
		setCrisisModelOpen(bool);
	}
	function handleChange(event) {
		var search = event.target.value;
		setSearch(search);
		if (handleCrisis(search) && !stopCrisisOpening) {
			setCrisisModelOpen(true);
		}
		if (search.length > 2) {
			client.get(`element?search=${search}`).then(e => {
				setElements(e['data']);
			}).catch(exc => {
				console.warn(exc);
				NotificationManager.error("Could not retrieve elements. Please try again.");
			})
		}
	};
	useEffect(() => {
		client.get(`common_issues`).then(e => {
			setCommonIssueElements(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve elements");
		})
		//eslint-disable-next-line
	}, [])

	const [open, setOpen] = useState('1');
	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};
	const [notifyOpen, setNotifyOpen] = useState(false);
	const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
	function sendNotify() {
		client.post(`notify_sue`, {
			'text': search,
			'type': 'aim_request'
		}).then(e => {
			NotificationManager.success("Notification sent");
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Sorry, we couldn't process your request right now");
		})
	}

	function startsWith(str, word) {
		return str.toLowerCase().toString().lastIndexOf(word.toLowerCase(), 0) === 0;
	}

	return (
		<Page background="journey_one" footer={<Footer textToDisplay={flavourText} displayButtons={"true"}></Footer>}>
			<Breadcrumb>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_start", style: { color: "darkblue" } }}>
					Think
				</Breadcrumb.Item>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_options", style: { color: "darkblue" } }}>
					Choose Path
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					Search My Aim
				</Breadcrumb.Item>
			</Breadcrumb>
			<QuickModal
				title="If you're in crisis, click here for people to contact"
				open={crisisModelOpen}
				setOpen={setCrisisModelToggle}
				showSave={false}>
				<a href="https://self-therapist.com/crisis-information" target="_blank" rel="noopener noreferrer">
					Crisis Information
				</a>
			</QuickModal>
			<QuickModal
				title="Notify us to improve"
				open={notifyOpen}
				setOpen={setNotifyOpen}
				saveText={"Send"}
				saveFunction={sendNotify}>
				<Alert color="info">
					<p>This will send a notification to us including your search term for us to review.</p>
					<p>Don't worry, we won't know it was you who searched for it. Your information is still kept entirely private.</p>

				</Alert>
			</QuickModal>
			{/* <StepProgressBar percent={15}/> */}
			<Header className="mb-2" displayIcon={"false"}></Header>
			{/* Specifically left this one outside of the header component as it lines up with common issues, Sue to decide? */}
			<Row className='h-100 mt-2'>
				<Col xs={12} md={8} className='text-center'>
					<h4 className="my-4"><b>{pageHead}</b></h4>
					<div className='align-items-center'>
						<FormGroup>
							<DebouncedInput
								className="aim-text-area"
								type="textarea"
								name="aims"
								id="aims"
								style={aimsBox}
								onChange={handleChange}
								placeholder="Type here. It's completely private. Try to summarise a specific problem or aim that you would like to work on, in a word or a sentence."
							/>
						</FormGroup>
					</div>

					<div className="pb-6">
						{elements.length > 0 ?
							<h4 aria-level="1" className="my-4 text-center"><b>So, my aim is to describe myself this way:</b></h4>
							: ""}
						<Row className="h-100">
							<Col sm={'12'} md={'10'} xl={'9'} className="justify-content-md-center w-100 m-auto" >
								{!!elements ? elements.map((element, index) => (
									<DecisionButton
										furtherClass={"aimbot"}
										keyName={index}
										link={`/aim_reflection/${cipher.obfuscate(element.id)}`}
										name={element.name}
										description={""}
										origin={origin}
										canPersonalise={true}
										element={element}
									/>
								)) : <LoadingSpinner />}
								{elements?.length === 0 && search?.length > 0 && (
									<div className="alert alert-warning text-center p-6" role="alert">
										We're sorry your search term isn't in our database:
									</div>

								)}
								{search?.length > 2 && (
									<button className="btn btn-primary mt-3" onClick={() => setNotifyOpen(true)}>
										Do we need to work on our search engine?
									</button>
								)}
							</Col>
						</Row>

					</div>
				</Col>
				<Col xs={12} md={4} className='text-center mt-0'>
					<h4 className="my-4"><b>Common Issues</b></h4>

					<div>
						<Accordion open={open} toggle={toggle}>

							{!!alphabet ? alphabet.map((letter, index) => (
								<>
									{commonIssueElements.filter(issue => startsWith(issue.common_issue, letter)).length > 0 && (
										<AccordionItem>
											<AccordionHeader targetId={letter}>{letter}</AccordionHeader>
											<AccordionBody className="m-0 p-0" accordionId={letter}>
												{!!commonIssueElements ? commonIssueElements.filter(issue => startsWith(issue.common_issue, letter)).map((issue, index) => (
													<>
														{!!issue.element && (
															<DecisionButton rowClass="border-dark second-btn m-0" baseClass='h-100 p-4 m-0 clicky hover-blue mobile-padlow' furtherClass={"compact aimbot"} keyName={index} link={`/aim_reflection/${cipher.obfuscate(issue.element)}`} name={issue.common_issue} origin={origin} canPersonalise={false} element={issue.element_obj} />
														)}
													</>
												)) : <LoadingSpinner />}
											</AccordionBody>
										</AccordionItem>
									)}

								</>
							)) : <LoadingSpinner />}

						</Accordion>
					</div>

				</Col>
			</Row>
		</Page>
	);
};

export default DecisionTreeIKnow;
