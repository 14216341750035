import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import {
  tokenAtom, userAtom
} from "atoms";
import { userSelector } from "selectors";
import { createClient } from "../hooks/useClient";

export const useUserContext = () => {
  const user = useRecoilValue(userSelector);
  // const refresh_user = useRecoilRefresher_UNSTABLE(userSelector);

  const todoUpdates = useSetRecoilState(userAtom);
  const refreshUser = () => todoUpdates((n) => n + 1);
  // const refreshUser = useRecoilCallback(() => () => {
  //   reset(userSelector);
  // });

  // const forceUpdate = () => todoUpdates((n) => n + 1);

  const clear = useRecoilCallback(({ reset }) => () => {
    reset(tokenAtom);
    reset(userAtom);
  });

  const logout = clear;

  async function getToken(username, password) {
    return createClient()
      .post("api-token-auth", {
        username,
        password,
      })
      .get("data")
      .get("token");
  }

  const login = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) => async (username, password) => {
      const token = await getToken(username, password);

      snapshot = snapshot.map(({ set }) => {
        set(tokenAtom, token);
        return null;
      });
      gotoSnapshot(snapshot);
    }
  );

  return {
    user,
    login,
    logout,
    getToken,
    clear,
    refreshUser,
  };
};

export default useUserContext;
