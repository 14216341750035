import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { LoadingSpinner, Page, QuestionBox, ElementCarousel, Footer, Header, StepProgressBar, Breadcrumbs, cipher } from "components/common";

import { useClient } from 'hooks';
import { Row, Col, Button } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { SubmitButton } from 'components/react-hook-form';

export const QuestionList = () => {
	const client = useClient();
	const params = useParams();
	const navigate = useNavigate();
	const [questions, setQuestions] = useState([]);
	const [chosenElement, setChosenElement] = useState();
	const [personalisedAim, setPersonalisedAim] = useState(null);
	const [letter, setLetter] = useState();
	const [AimId, setAimId] = useState(null);
	const [aimNameOverride, setAimNameOverride] = useState();
	const flavourText = letter === "A" ? "Thinking questions" : letter === "B" ? "Feeling questions" : letter === "C" ? "Questions about drives" : letter === "D" ? "Motivation questions" : letter === "E" ? "New perspective questions" : "";
	const questionImage = letter === "A" ? "thinking-questions.png" : letter === "B" ? "feeling-questions.png" : letter === "C" ? "drivers.png" : letter === "D" ? "motivation.png" : letter === "E" ? "new-perspective.png" : "";
	const [currentSlide, setCurrentSlide] = useState(null);
	const [showFeedbackTwo, setShowFeedbackTwo] = useState(false);
	const [searchWords, setSearchWords] = useState();
	const [loading, setLoading] = useState(true);
	const { state } = useLocation();
	const [question_id, setQuestionId] = useState();

	useEffect(() => {
		setLetter(params['question_type_group']);
		client.get(`element/${cipher.deobfuscate(params['element_id'])}`).then(e => {
			setChosenElement(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve your chosen aim. Please try again.");
		})

		client.get(`personalisedaim?Element_id=${cipher.deobfuscate(params['element_id'])}`).then(e => {
			setAimNameOverride(e.data[0].personalised_aim_text);
			setAimId(e.data[0].id);
			const data = e.data[0]?.notes.reduce((prev, curr, ind) => {
				if (prev[curr.Question]) prev[curr.Question] = [curr, ...prev[curr.Question]];
				else prev[curr.Question] = [curr];
				return prev;
			}, {});
			setPersonalisedAim(data);
		}).catch(exc => {
			console.warn(exc);
		})
		getPopupSearchKeywords();

		client.get(`question?element=${cipher.deobfuscate(params['element_id'])}&question_type_group=${params['question_type_group']}`).then(e => {
			setQuestions(e['data']);
			var index = e['data'].findIndex((q) => q.id == question_id);
			setCurrentSlide(index != -1 ? index : 0);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve the questions. Please try again.");
		})
	}, []);

	useEffect(() => {
		var index = questions.findIndex((q) => q.id == question_id);
		setCurrentSlide(index != -1 ? index : 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question_id]);

	useEffect(() => {
		setQuestionId(params['qid'] ?? state?.question_id)
	}, [params])

	useEffect(() => {
		if (!questions[currentSlide]?.id) return;
		if (params?.qid === questions[currentSlide]?.id) {
			return;
		}
		if (params?.qid) {
			navigate('../' + questions[currentSlide]?.id, { replace: true });
		}
		else {

			navigate('./' + questions[currentSlide]?.id, { replace: true });
		}
	}, [currentSlide]);

	const getPopupSearchKeywords = () => {

		client.get(`popup`).then(e => {
			const data = e.data;
			let searchList = []
			data.forEach(function (word, index) {
				if (word.keywords.split(',')) {
					let multipleWords = word.keywords.toString().split(',')
					multipleWords.forEach(thisKeyword => searchList.push({ 'word': thisKeyword.trim(), 'description': `/guides/${word.id}` }));
				}
				else {
					searchList.push({ 'word': word.keywords, 'description': `/guides/${word.id}` })
				}
			}
			);
			setSearchWords(searchList);
		}).catch(exc => {
			console.warn(exc);
			setSearchWords([]);

		})
	};

	const onSubmit = async (formData) => {
		try {
			if (!chosenElement.personalised_aim) {
				await client.post('personalisedaim', {
					'after_negative': formData.after_negative,
					'after_progress': formData.after_progress,
					'Element_id': chosenElement.id
				});
			}
			else {
				const aim = await client.patch(`personalisedaim/${chosenElement.personalised_aim.id}`, {
					'after_negative': formData.after_negative,
					'after_progress': formData.after_progress,
				}).get("data");
				reset({
					after_negative: `${aim.after_negative}`,
					after_progress: `${aim.after_progress}`
				})
			}

			NotificationManager.success('Your feedback has been saved successfully to your aim.');
			navigate(`/notebook/${chosenElement.personalised_aim.id}`);
		}
		catch (e) {
			NotificationManager.error('An error occurred with the feedback submission.');
		}
	};

	const methods = useForm({
		defaultValues: {
			after_negative: chosenElement?.personalised_aim?.after_negative,
			after_progress: chosenElement?.personalised_aim?.after_progress,
		},
		mode: 'all',
	});

	const { reset, watch, register, handleSubmit } = methods;

	const after_progress = watch('after_progress');
	const after_negative = watch('after_negative');

	useEffect(() => {
		methods.setValue("after_negative", chosenElement?.personalised_aim?.after_negative);
		methods.setValue("after_progress", chosenElement?.personalised_aim?.after_progress);
		//eslint-disable-next-line
	}, [chosenElement]);
	if (!searchWords || !chosenElement) {
		return <LoadingSpinner />
	}
	return (
		<Page background="questions" footer={
			<Footer textToDisplay={flavourText}
				displayButtons={"true"} overrideImage={questionImage} specificAim={AimId}>
			</Footer>}
		>
			<Breadcrumbs parent={'Choose Question Type'} current={'Question List'} parentLink={`/element_chosen/${cipher.obfuscate(chosenElement.id)}`} deepLink={`/question_info/${cipher.obfuscate(chosenElement.id)}`} />
			<Header className="mb-2" displayIcon={"false"}></Header>
			<FormProvider {...methods}>
				<Row className="align-items-center h-100 mt-2 mobile-padless">
					<Col className="text-center mobile-padless">
						{aimNameOverride ? (
							<h5 className="aimbot-black my-4">My specific aim is to: <b className="aimbot my-4">{aimNameOverride}</b></h5>
						) :
							<h5 className="aimbot-black my-4">Aim {chosenElement.key}: <b className="aimbot-blue my-4">{chosenElement.name}</b></h5>
						}
						<Col sm={'12'} md={'12'} className="justify-content-md-center mx-auto mobile-padless" >
							{!!questions && currentSlide !== null && questions.length > 0 && (
								<>
									<StepProgressBar percent={[Math.round(100 / questions.length) * (currentSlide + 1)]} />
									<ElementCarousel arrows={false} slidesToShow={1}
										current={currentSlide} setCurrent={setCurrentSlide}>
										{!!questions ? questions.map((question, index) => (
											<>
												<QuestionBox
													key={index}
													question={question.question}
													whole_question={question}
													userNotes={personalisedAim ? personalisedAim[question.id] : null}
													prompt={question.prompt}
													currentSlide={currentSlide}
													setCurrentSlide={setCurrentSlide}
													follow_up={question.follow_up}
													keyName={index + 1}
													end={questions.length}
													chosenElement={chosenElement}
													client={client}
													searchWords={searchWords}
												/>
											</>
										)) : <LoadingSpinner />
										}


									</ElementCarousel>
								</>)}
						</Col>
					</Col>
				</Row>
				<Row className="flex justify-center h-100 mt-2 mobile-padless text-center">
					<Button color="primary" className="btn-primary main-btn my-3 border-0 text-white h-10"
						onClick={() => setShowFeedbackTwo(x => !x)}
					>
						I am {showFeedbackTwo ? "not" : ""} finished with this aim
					</Button>
					{!!showFeedbackTwo === true && (

						<div id="form-wrapper">
							<form onSubmit={handleSubmit(onSubmit)} className="rating-wrapper rating-form" noValidate>
								<p className="page-header mt-5">
									<b>This is how much this issue is affecting me negatively at the moment:</b>
								</p>
								<Row>
									<Col xs={'6'} className='rating-headers text-left'>
										<b>1 = Hardly at all</b>
									</Col>
									<Col xs={'6'} className='rating-headers text-right'>
										<b>10 = Significantly</b>
									</Col>
								</Row>

								<div className="rating-slider">
									{[...Array(10).keys()].map((value) =>
										<React.Fragment
											key={value}
										>
											<input type="radio" name="rating1" id={"rating1" + value}
												//eslint-disable-next-line
												defaultChecked={value + 1 == after_negative}
												value={value + 1} {...register("after_negative")} required />
											<label htmlFor={"rating1" + value} data-rating={value + 1} />
										</React.Fragment>
									)
									}
									<div id="pos"></div>
								</div>

								<p className="page-header">
									<b>This is how well I can manage this issue at the moment:</b>
								</p>

								<Row>
									<Col xs={'6'} className='rating-headers text-left'>
										<b>1 = Not at all</b>
									</Col>
									<Col xs={'6'} className='rating-headers text-right'>
										<b>10 = Very well</b>
									</Col>
								</Row>

								<div className="rating-slider">
									{[...Array(10).keys()].map((value) =>
										<React.Fragment
											key={value}
										>
											<input type="radio"
												name="after_progress"
												id={`after_progress${value + 1}`}
												//eslint-disable-next-line
												defaultChecked={value + 1 == after_progress}
												value={value + 1}
												{...register("after_progress")}
												required
											/>
											<label htmlFor={`after_progress${value + 1}`} data-rating={value + 1} />
										</React.Fragment>
									)
									}
									<div id="pos"></div>
								</div>
								<div className="flex justify-center">
									<SubmitButton className="btn-primary main-btn my-3 border-0 text-white h-10">
										Save Ratings
									</SubmitButton>
								</div>

							</form>
						</div>
					)}
				</Row>
			</FormProvider>
		</Page>
	);
};

export default QuestionList;
