import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { NotificationManager } from "react-notifications";

import { useClient } from 'hooks';
import { Row, Col, Button } from 'reactstrap';
import { Page, LoadingSpinner, Header } from "components/common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {
	ReOrderableItem,
	ReOrderableList,
} from 'react-reorderable-list';
import "./SelfFeedback.scss"
import { FormProvider, useForm } from 'react-hook-form';
import { SubmitButton } from 'components/react-hook-form';
import { faAngleDoubleUp, faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AimStatementsQuiz = () => {
	const client = useClient();
	const navigate = useNavigate();
	const [qualityStatements, setQualityStatements] = useState([]);
	const [aimStatementAnswers, setAimStatementAnswers] = useState({});
	const [ans, setAns] = useState(null);
	const [sevenAnswers, setSevenAnswers] = useState([]);
	const [elements, setElements] = useState([]);
	const [reorderMode, setReorderMode] = useState(false);
	const [setIsBlocking] = useState(false);
	const pageHead = "Self Assessment";
	const { state } = useLocation();
	const questionIds = state?.questionIds;

	async function load() {
		try {
			const [qualityStatements, answers] = await Promise.all([client.get("qualitystatement?id__in=" + questionIds).get("data"), client.get("aim_statement_answers").get("data")]);
			const S = new Set(questionIds.map(e => e.toString()));
			const newanswers = answers.filter(e => S.has(e.QualityStatement.toString()));
			setQualityStatements(qualityStatements);
			const x = newanswers.reduce((prev, curr) => {
				prev[curr.AimStatement] = curr.answer;
				if (curr.answer) {
					setValue(`rating${curr.AimStatement}`, `${curr.answer}`);
				}
				return prev;
			}, {});
			setAimStatementAnswers(x);
		}
		catch (exc) {
			console.warn(exc);
			NotificationManager.error("Could not retrieve quality statements. Please try again.");
		}
	}

	async function submitAnswers(formData) {
		// get id from 'rating${id}'
		const qids = Object.keys(formData).map(e => e.slice(6));
		const answerMatrix2 = qids.map(q => (
			{ questionId: q, answerSelected: formData[`rating${q}`] ?? '4' }));
		const answers = answerMatrix2.slice().sort(function (a, b) {
			return a.answerSelected - b.answerSelected;
		}).filter(e => e.answerSelected !== "3");
		if (answers.length === 0) {
			NotificationManager.error("Try thinking more deeply about these statements.");
			return;
		}
		if (ans === null) {
			const seven_answers = answers.slice(0, 7);
			const ans = Array.from(new Set(seven_answers)).sort(
				(a, b) => b.answerSelected - a.answerSelected)[0].answerSelected;
			const count = answers.filter(e => e.answerSelected === ans).length;
			const ind = seven_answers.findIndex(e => e.answerSelected === ans);
			let questionLookup = {};
			for (const qsi in qualityStatements) {
				const qs = qualityStatements[qsi];
				for (const asi in qualityStatements[qsi]?.aim_statements) {
					questionLookup[qs?.aim_statements[asi].id] = qs?.aim_statements[asi];
				}
			}
			if (count > 7 - ind) {
				setAns(ans);
				setSevenAnswers(seven_answers);
				const set1 = answers.filter(e => e.answerSelected === ans);

				const set2 = set1.map(x => {
					return questionLookup[x.questionId];
				});
				setElements(set2);
				await client.post("aim_statement_answers/update_answers", answerMatrix2);
				load();
				setReorderMode(true);
				window.scrollTo(0, 0);
			}
			else {
				const questionIds = seven_answers.map(a => a.questionId);
				await client.post("aim_statement_answers/update_answers", answerMatrix2);
				navigate('/quality_statements', { state: { questionIds: questionIds } });
			}
			return;
		}
		else {
			const rest = sevenAnswers.filter(e => e.answerSelected !== ans).map(a => a.questionId);
			const questionIds = [...rest, ...elements.slice(0, 7 - rest.length).map(x => x.id)];
			await client.post("aim_statement_answers/update_answers", answerMatrix2);
			navigate('/quality_statements', { state: { questionIds: questionIds } });
		}
	}

	async function removePreviousAnswers() {
		client.post(`aim_statement_answers/reset_answers`).then(e => {
			NotificationManager.success("Your self-assessment answers have been deleted successfully.");
			setTimeout(function () {
				window.location.reload();
			}, 1000);
		});
	}

	function sendToTop(index) {
		setElements(els => {
			return [els[index], ...els.slice(0, index), ...els.slice(index + 1)];
		});
	}

	function sendToBottom(index) {
		setElements(els => {
			return [...els.slice(0, index), ...els.slice(index + 1), els[index]];
		});
	}

	const methods = useForm({
		defaultValues: {},
		mode: 'all',
	});

	const { handleSubmit, setValue, register, watch } = methods;

	useEffect(() => {
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		return () => {
			let formData = watch();
			setIsBlocking(0);
			const qids = Object.keys(formData).map(e => e.slice(6));
			const answerMatrix2 = qids.map(q => (
				{ questionId: q, answerSelected: formData[`rating${q}`] ?? '4' }));
			client.post("aim_statement_answers/update_answers", answerMatrix2);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Page background="journey_four_aims">
			<Breadcrumb>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_start", style: { color: "darkblue" } }}>
					Think
				</Breadcrumb.Item>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_self_assessment" }}>
					Self Assessment
				</Breadcrumb.Item>
			</Breadcrumb>
			<Header className="mb-2" displayIcon={"false"} setReorderMode={setReorderMode} reorderMode={reorderMode} setAns={setAns} title={pageHead}></Header>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(submitAnswers)} className="rating-wrapper" noValidate>
					<Row className='align-items-center h-100 mt-2'>
						<Col className='i-know-card text-center'>

							{!reorderMode ? (
								<>
									<Col xs={'8'} id="footer-area" className="bg-white rounded border border-dark pb-3 m-auto" style={{ fontSize: "14px" }}>

										<br /><h6><b>Instructions</b></h6>

										These statements go into more detail in the areas you’ve chosen.<br />

										Add an answer for each statement again.

									</Col>
								</>
							) :
								<>
									<Col xs={'8'} id="footer-area" className="bg-white rounded border border-dark pb-3 m-auto" style={{ fontSize: "14px" }}>

										<br /><h6><b>Instructions</b></h6>

										Drag the {7 - sevenAnswers.filter(e => e.answerSelected !== ans).length}{" "} statement(s) that describe(s) you most to the top of the list

									</Col>
								</>
							}

							{reorderMode && (
								<div className="mx-auto pt-5 px-3 lg:max-w-2xl md:max-w-xl">
									<ReOrderableList
										name="reordable-statements"
										list={elements}
										onListUpdate={(n) => setElements(n)}
									>
										{elements.map((statement, idx) => (
											<ReOrderableItem key={`{idx}-item-${idx}`} style={{ background: "#007bff", border: "0px", color: "blue", height: "40px" }}>
												<>
													<div className="text-dprimary reorder clicky my-3 text-center">
														<Button className="p-1" style={{ backgroundColor: "#FFEAE1", color: 'black', border: '0px', borderRadius: '0px' }} onClick={() => sendToBottom(idx)}>
															<FontAwesomeIcon size="lg" icon={faAngleDoubleDown} className="mx-auto" />
														</Button>
														<div className="py-2">{statement?.statement}</div>
														<Button className="p-1" style={{ backgroundColor: "#FFEAE1", color: 'black', border: '0px', borderRadius: '0px' }} onClick={() => sendToTop(idx)}>
															<FontAwesomeIcon size="lg" icon={faAngleDoubleUp} className="mx-auto" />
														</Button>
													</div>
												</>
											</ReOrderableItem>
										))}
									</ReOrderableList>
								</div>
							)}

							<div className="justify-center pt-5">

								{!reorderMode && (
									(qualityStatements.length > 0) ? (qualityStatements.map((statement, index) => (
										<>
											<>
												{statement?.aim_statements.length > 0 ? statement.aim_statements.map((aimStatement, index) => (
													<>
														<p className="page-header"><b>{aimStatement.statement}</b></p>
														<div id="form-wrapper">
															<div className="rating-wrapper rating-form">
																<div className="rating-slider-agreement">

																	<input type="radio" name={"rating" + aimStatement.id} id={"rating" + aimStatement.id + 3} value={"3"} defaultChecked={1 === aimStatementAnswers[aimStatement?.id] ?? null}
																		{...register("rating" + aimStatement.id)} required />

																	<label htmlFor={`rating${aimStatement.id}3`} data-rating="I don't agree" />
																	{/* <label for={"rating" + aimStatement.id + 2} data-rating={"I don't agree"}></label> */}

																	<input type="radio" name={"rating" + aimStatement.id} id={"rating" + aimStatement.id + 2} value={"2"} defaultChecked={"2" === aimStatementAnswers[aimStatement?.id]}

																		{...register("rating" + aimStatement.id)} required />

																	{/* <label for={"rating" + aimStatement.id + 1} data-rating={"I partly agree"}></label> */}
																	<label htmlFor={`rating${aimStatement.id}2`} data-rating="I partly agree" />

																	<input type="radio" name={"rating" + aimStatement.id} id={"rating" + aimStatement.id + 1} value={"1"} defaultChecked={1 === aimStatementAnswers[aimStatement?.id] ?? null}

																		{...register("rating" + aimStatement.id)} required />

																	{/* <label for={"rating" + aimStatement.id + 0} data-rating={"I strongly agree"}></label> */}
																	<label htmlFor={`rating${aimStatement.id}1`} data-rating="I strongly agree" />
																	<input type="radio" name={"rating" + aimStatement.id} id={"rating" + aimStatement.id + 4} value={'4'} defaultChecked={4 === aimStatementAnswers[aimStatement?.id] ?? null}

																		{...register("rating" + aimStatement.id)} required />

																	{/* <label for={"rating" + aimStatement.id + 3} data-rating={"I'm not sure"}></label> */}
																	<label htmlFor={`rating${aimStatement.id}4`} data-rating="I'm not sure" />
																	<div id="pos"></div>
																</div>
															</div>
														</div>
													</>
												)) : null}
											</>
										</>
									))) : <LoadingSpinner />)}
							</div>

							<div className="journey_four_aims" style={{ position: 'sticky', bottom: 0, zIndex: 1000, opacity: 1 }}>

								<Row className="justify-content-center h-100 m-auto" style={{ flexWrap: 'nowrap' }}>
									{!reorderMode && (
										<>
											<Button className="main-btn question-grid-items" style={{ backgroundColor: "white", color: 'black' }} onClick={() => {
												if (window.confirm('Are you sure you want to remove your previous answers?')) { removePreviousAnswers() }
											}
											}
											>
												Clear Form
											</Button>
										</>
									)}
									<SubmitButton className="main-btn question-grid-items">Continue</SubmitButton>
								</Row>
							</div>

						</Col>
					</Row>
				</form>
			</FormProvider>
		</Page>
	);
};

export default AimStatementsQuiz;
