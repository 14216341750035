import React, { useEffect } from 'react';
import { DecisionButton, Page, Header, AimReports } from "components/common";
import { Row, Col } from 'reactstrap';

export const Dashboard = () => {

  useEffect(() => {
    // SUPER ADMIN INFO?
    // client.get(`question_groups_images`).then(e => {
    // 	setQuestionGroupImages(e['data']);
    // }).catch(exc => {
    // 	console.warn(exc);
    // 	NotificationManager.error("Could not retrieve question group images");
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page background="admin">
      <Header className="mb-2" displayIcon={"false"} title={"Super Admin Panel"} />
      <Row className='align-items-center mt-2'>

        <Col sm={'12'} md={'7'} xl={'7'} className='text-center'>
          <div className='alert bg-custom p-4' >
            <img className="w-40 mx-auto" src={'/st-logo-nobg.png'} alt="self-therapist logo" />
          </div>

          <AimReports url="personalisedaim/report" justGraph width={400} />
          <div className="p-3">
            <DecisionButton
              link={`user_reports`}
              name={`User Reports`}
            />
            <DecisionButton
              link={`studys`}
              name={`Academic Studies`}
            />
            <DecisionButton
              link={`organisations`}
              name={`Organisations`}
            />
          </div>
        </Col>

        <Col sm={'12'} md={'5'} xl={'5'}>
          <Row className="justify-content-center">

            <h2 className="text-center w-90">Edit Database</h2>
          </Row>
          <DecisionButton
            link={`categorygroup`}
            name={`Category Groups`}
          />
          <DecisionButton
            link={`category`}
            name={`Categories`}
          />
          <DecisionButton
            link={`quality`}
            name={`Qualities`}
          />
          <DecisionButton
            link={`element`}
            name={`Aims`}
          />
          <DecisionButton
            link={`question`}
            name={`Questions`}
          />
          <DecisionButton
            link={`qualitystatement`}
            name={`Quality Statements`}
          />
          <DecisionButton
            link={`aimstatement`}
            name={`Aim Statements`}
          />
          <DecisionButton
            link={`assessmentstatement`}
            name={`Assessment Statements`}
          />
          <DecisionButton
            link={`popup`}
            name={`Therapy Guides`}
          />
          <DecisionButton
            link={`common_issues`}
            name={`Common Issues`}
          />
        </Col>
      </Row>
    </Page>
  );
};

export default Dashboard;
