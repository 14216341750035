import React from "react";
import CookieConsent, { Cookies, resetCookieConsentValue } from "react-cookie-consent";
import Button from "./Button";

export const ConsentCookie = () => {

    function handleDecline() {
        alert("Our site needs to use functional cookies to work properly. Please navigate away if you're not OK with that.");
        resetCookieConsentValue('acceptedAllCookies');
        window.open('https://self-therapist.com/cookie-refusal', '_blank');
        window.location.reload();
    }

    function handleAccept(all = false) {
        if (all === true) {
            Cookies.set('acceptedAllCookies', 'true');
            localStorage.setItem('acceptedAllCookies', 'true');
            window.location.reload();
        }
        else {
            Cookies.set('acceptedAllCookies', 'false');
            localStorage.setItem('acceptedAllCookies', 'false');
            window.location.reload();
        }
    }
    return (
        <CookieConsent
            overlay
            cookieName="acceptedAllCookies"
            containerClasses="alert alert-info consent-banner"
            buttonStyle={{ display: 'none' }}
            expires
        >
            <p style={{ color: "black" }}>
                We use functional cookies to make our site work, and we also use analytics cookies to tell us how our site is used, to help us to improve it.{" "}
                For more information, see the Cookies section in our <a href={'https://www.self-therapist.com/app-privacy'} target="_blank" rel="noopener noreferrer">{" "}Privacy Policy</a>.{" "}
                Please note that without our functional cookies, the site cannot work properly.
            </p>
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="mr-2" style={{ color: "white", backgroundColor: "#149D04", fontSize: "x-large" }} onClick={() => handleAccept(true)}>Accept all</Button>
                <Button className="mr-2" style={{ color: "white", backgroundColor: "#007BFF", fontSize: "medium" }} onClick={() => handleAccept(false)}>Accept functional</Button>
                <Button className="mr-2" style={{ color: "white", backgroundColor: "red", fontSize: "medium" }} onClick={() => handleDecline()}>Reject all</Button>
            </span>
        </CookieConsent>
    );
};

export default ConsentCookie;