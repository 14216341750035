import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Media, UncontrolledTooltip } from 'reactstrap';
import { isMobile } from "react-device-detect";

import classNames from "classnames/dedupe";
export const OptionsDecisionButton = ({
    keyName,
    furtherClass = "",
    link,
    name,
    optionsPage = false,
    description,
    baseClass = 'h-100 px-6 py-4 m-auto clicky mobile-padlow',
    rowClass = 'border-dark second-btn',
    tag: Tag = Link,
    backgroundColour = '',
}) => {
    const navigate = useNavigate();

    return (
        <>
            <Row className={classNames(rowClass, backgroundColour)}>
                <Col xs={'2'} className={classNames(baseClass, 'justify-content-center', furtherClass)}>
                    <Media
                        className="clicky"
                        object
                        src={'/icons/hover.png'}
                        width={30}
                        height={30}
                        id={`tooltipSelect_${keyName}`}
                        onClick={(e) => e.preventDefault()}
                        aria-label={"Click for more information"}
                    />
                </Col>
                <Col xs={"8"} className={classNames(baseClass, furtherClass)}>
                    <Tag className={classNames(baseClass, furtherClass)} to={link} style={{ color: '#060E92' }}>
                        {name}
                    </Tag>
                </Col>
                {!!optionsPage ? (
                    <Col xs={'2'} className={classNames(baseClass, furtherClass)}>
                        <Media
                            className="clicky"
                            object
                            src={'/icons/browseBig.png'}
                            width={30}
                            height={30}
                            // height={150}
                            onClick={() => navigate('/aims_list')}
                            aria-label={"Click to do to notebook"}
                        />
                    </Col>
                ) :
                    <Col xs="2" className="m-auto"></Col>
                }


                <UncontrolledTooltip placement="auto" target={`tooltipSelect_${keyName}`} trigger={!!isMobile ? ("click") : ("click hover")}>
                    {description}
                </UncontrolledTooltip>
            </Row>
        </>
    );
};

export default OptionsDecisionButton;
