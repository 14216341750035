import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useClient } from "../../hooks";
import { Page, Header } from 'components/common';
import { NotificationManager } from 'react-notifications';

import {
  Input,
  FormGroup,
  Select,
  SubmitButton,
} from "../../components/react-hook-form";

export const EditAssessmentStatement = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;
  const isNew = !id || id === 'create';
  const methods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit, setValue } = methods;
  const client = useClient();
  const [elements, setElements] = useState();
  const [assessmentStatements, setAssessmentStatements] = useState();
  const [data, setData] = useState();
  const booleans = [
    { label: "true", value: "true" },
    { label: "false", value: "false" }
  ];

  useEffect(() => {
    client.get(`element`).then(({ data }) => {
      const selectOptions = data.map(o => ({ value: o.id, label: o.name }))
      setElements(selectOptions);
    }).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Could not retrieve the elements");
    })
    client.get(`assessmentstatement`).then(({ data }) => {
      const selectOptions = data.map(o => ({ value: o.id, label: o.statement }))
      setAssessmentStatements(selectOptions);
    }).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Could not retrieve your assessment statements");
    })
    if (!isNew) {
      client.get(`assessmentstatement/${id}`).then(({ data }) => {
        setData(data);
      }).catch(exc => {
        console.warn(exc);
        NotificationManager.error("Could not retrieve your assessment statement");
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (data) {
      setValue('statement', data.statement);
      setValue('element', data.element.id);
      setValue('is_opposite', { value: data.is_opposite, label: data.is_opposite ? 'true' : 'false' });
      if (!!assessmentStatements && assessmentStatements.length > 0 && data.assessment_statement) {
        setValue('assessment_statement', { value: data.assessment_statement, label: assessmentStatements.find((x) => x.value === data.assessment_statement).label });
      }
      if (!!elements && elements.length > 0) {
        setValue('element', { value: data.element, label: elements.find((x) => x.value === data.element).label });
      }
    }
  }, [client, id, isNew, data, assessmentStatements, elements, setValue]);


  async function onSubmit(x) {
    if (typeof x.is_opposite === "object") {
      x.is_opposite = x.is_opposite.value;
    }
    if (typeof x.assessment_statement === "object") {
      x.assessment_statement = x.assessment_statement.value;
    }
    if (typeof x.element === "object") {
      x.element = x.element.value;
    }
    if (isNew) {
      client
        .post(`assessmentstatement`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    } else {
      client
        .put(`assessmentstatement/${id}`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    }

  }

  return (
    <Page background="admin">
      {!!isNew ? (
        <Header className="mb-2" displayIcon={"false"} title={`New Assessment Statement`} />
      ) : (
        <Header className="mb-2" displayIcon={"false"} title={`Edit Assessment Statement`} />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label={"Statement"} name={"statement"}>
            <Input
              type='textarea'
              className='form-control'
              name={'statement'}
              placeholder='Statement'
              rules={{
                required: false,
                maxLength: 16860
              }}
            />
          </FormGroup>
          <FormGroup label={"Opposite?"} name={"is_opposite"} false>
            <Select
              name={'is_opposite'}
              rules={{ required: false }}
              options={booleans}
            />
          </FormGroup>
          <FormGroup label={"Element"} name={"element"} required>
            <Select
              name="element"
              rules={{ required: true }}
              options={elements}
            />
          </FormGroup>
          <div className="flex justify-between">
            <SubmitButton color="primary">
              {isNew ? "Create" : "Save"}
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};
export default EditAssessmentStatement;
