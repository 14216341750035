import React, { useState } from "react";
import { renderToString } from 'react-dom/server'

export const HighlightModal = ({
  children,
  searchWords = [    {'word': 'self-esteem', 'description': '/self_esteem' }],
  ...props

}) => {
    const [search] = useState(searchWords);
    // const escapeRegExp = (str = '') => (
    //   str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
    // );
    function markMatches(wordObjects, res) {
        let req = '';
        wordObjects.forEach(function(currentValue, index){
          if(currentValue['word'].length > 2){
            req = req + '{!}' + currentValue['word'];
          }
        });

        if (req) {
          const normReq = req
            .toLowerCase()
            .replace(/\s+/g, " ")
            .replace(".", "\\.")
            .trim()
            .split("{!}")
            .sort((a, b) => b.length - a.length);
          function addTooltip(match){
            var desc = wordObjects.find(element => element.word.toLowerCase().trim() === match.toLowerCase().trim() && match.length > 2);
            // return `${match}`;
            if(desc){
              return `<a
                  href='${desc?.description}'
                  >${match.trim()}</a>`;

            }else{
              return match;
            }
          }
          res = res.replace(
            new RegExp(`\\b(${normReq.join("|")})\\b`, "gi"),
            (match) => addTooltip(match)
          );
        }
        return res;
      }

    return <p
          dangerouslySetInnerHTML={{
            __html: markMatches(search, renderToString(children))
          }}
        />
};

export default HighlightModal;
