import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export const Breadcrumbs = ({
    className,
    parent,
    parentLink = null,
    deepLink = null,
    current,
    deep = true,

    ...props
}) => {
    const navigate = useNavigate();

    return (
        <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_options", style: { color: "darkblue" } }}>
                Think
            </Breadcrumb.Item>
            {deep && (
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: deepLink ? deepLink : "", style: { color: "darkblue" }, onClick: () => !deepLink && navigate(-2) }} >
                    ...
                </Breadcrumb.Item>
            )}
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: parentLink ? parentLink : "", style: { color: "darkblue" }, onClick: () => !parentLink && navigate(-1) }} >
                {parent}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                {current}
            </Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default Breadcrumbs;
