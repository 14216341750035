import React from 'react';
import { Row, Col } from 'reactstrap';

import { Page, Header } from 'components/common';

export const ContactUs = () => {
	return (
		<Page background="help-pages">
			<Header className="mb-2" displayIcon={"false"} title={"Contact"}></Header>
			<Row>
				<Col xs={'12'} md={'12'} xl={'12'} >
					<div className='float-center'>
						<p>
							Self-therapist Ltd <br />
							Diamond House <br />
							Diamond Court <br />
							Water St<br />
							Bakewell<br />
							Derbyshire<br />
							DE45 1EW<br /><br />
							Tel. +44 (0)1629 363357<br />
							<a href="https://self-therapist.com">self-therapist.com</a><br />
							Technical Support: <a href="mailto:hello@self-therapist.com" target="_blank" rel="noopener noreferrer">hello@self-therapist.com</a><br />
							Sales: <a href="mailto:info@self-therapist.com" target="_blank" rel="noopener noreferrer">info@self-therapist.com</a>
						</p>
					</div>
				</Col>
			</Row>
		</Page>
	);
};

export default ContactUs;
