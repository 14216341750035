import React, { useEffect, useState } from "react";
import { useClient, useUserContext } from "../../../hooks";
import { useQuery } from "react-query";
import { useForm, FormProvider } from "react-hook-form";
import { SubmitButton, FormGroup, Input } from "../../../components/react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CalculatedPrice } from "./components/CalculatedPrice";
import { NotificationManager } from "react-notifications";
import { PlanField } from "./components/PlanField";
import { usePriceOptions } from "./hooks/usePriceOptions";
import { Page } from "../../../components/common";
import { defaultQueryFn } from "./queries";
import { selectPriceOption } from "./selectors";
import { Alert, Button } from "reactstrap";
export const UpdateSubscription = () => {
  const client = useClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useUserContext();

  const priceOptions = usePriceOptions();
  const [isPending, setIsPending] = useState(false);
  const [finalQuantity, setFinalQuantity] = useState();

  const methods = useForm({
    mode: "all",
  });
  const { handleSubmit, reset, control } = methods;

  const { data: subscription } = useQuery(
    ["stripe/subscription"],
    defaultQueryFn(client),
    {
      slient: true,
      suspense: true,
    }
  );

  useEffect(() => {
    reset({
      price: selectPriceOption(t)(subscription.plan ?? { currency: "GBP", recurring: true, product: "NO PRODUCTS!" }),
      quantity: subscription.quantity,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, subscription]);


  const onSubmit = ({ price, quantity }) => {
    setIsPending(true);

    client.post("stripe/update_subscription", {
      quantity: finalQuantity ?? quantity,
      price: price.id,
    }).then((resp) => {
      const { client_secret: clientSecret, paid } = resp.data;
      navigate("payment-method", {
        state: {
          clientSecret,
          paid,
        }
      });
      // NotificationManager.success(t("Subscription updated successfully"));
      // refreshUser();
      // navigate("/stripe-subscription");
    })
      .catch((err) => {
        // navigate("/subscription");
        const error = err?.response?.data

        // navigate("/stripe-subscription/create_subscription/payment_method")
        if (error?.detail) {
          NotificationManager.error(error.detail);
        } else {
          NotificationManager.error(t("Your subscription was not updated. Please try again."));
        }
      }).finally(function () { setIsPending(false); });
  }

  return (
    <Page title={t("Update Subscription")} translate={false}>
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          {!!user.organisation_user && (

            <FormGroup label={t("How many invitations would you like to purchase?")} required name="quantity">
              <Input
                defaultValue={1}
                className="form-control"
                name="quantity"
                rules={{
                  required: true,
                }}
              />
            </FormGroup>
          )}
          <PlanField
            options={priceOptions}
            orgAccount={!!user.organisation_user ? true : false}
            rules={{
              required: true,
            }}
            name="price"
          />
          {!!user.is_organisation_admin && (
            <p className="mt-2"><b>If you need more than 50 invitations, you may like to <a href="/contact_us">contact us</a> direct to discuss bulk discounts</b></p>
          )}
          <p className='pt-6'></p>
          <CalculatedPrice setQuantity={setFinalQuantity} />
          <p className='pt-6'></p>
          <Alert color="info">
            <Trans>
              Update your number of invitations to the total you need (including your existing invitations and activated users).
              You won't be charged twice, but your additional invitations will be added to your current subscription.
            </Trans>
          </Alert>
          <Alert color="info">
            <Trans>
              <strong>Note:</strong> Subscription changes will be applied and your billing period will be reset.
            </Trans>
          </Alert>
          <div className="flex justify-between">
            <Button className="btn-danger" onClick={() => navigate("../")}>Cancel</Button>
            <SubmitButton control={control} pending={isPending}>
              <Trans>Confirm</Trans>
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};
