import React, { useState } from 'react';
import { FormGroup, Input } from '../../components/react-hook-form';
import { Disclaimers } from '../../components/common/Disclaimers';
import { Header } from '../../components/common';

// CSS
import 'react-slideshow-image/dist/styles.css';
import 'assets/css/style.css';
import { Button, InputGroup } from "reactstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CreateAccount = ({
  methods,
  greyRegister,
  setGreyRegister,
  showStudyCode,
  accountType,
}) => {
  const {
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = methods;

  const [showPassword, setShowPassword] = useState(true);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(true);


  return (
    <>
      <Header className="mb-2" displayIcon={"false"} title={`Create ${accountType} account`}></Header>

      {accountType !== 'an Organisational' &&
        <p style={{ fontSize: '13px' }}>
          We don't need any personal information other than an email address, and you can delete your account at any time.
          An account is only needed so that you can use the notebook feature in the tool.
        </p>
      }
      {!!showStudyCode && (
        <p style={{ fontSize: '13px' }}>
          <b>Please read the <a href='https://www.self-therapist.com/study-consent' target="_blank" rel="noopener noreferrer">Study Consent document</a> before registering an account.</b>
        </p>
      )}
      <FormGroup>
        <label>Email address</label>
        <Input
          type='email'
          className='form-control'
          name={'User.email'}
          placeholder='Enter email'
          rules={{
            required: false,
            validate: {
              emailEqual: (value) => value === getValues().User.email || 'Email confirmation error!',
            },
          }}
          onChange={(e) => {
            const value = e.target.value;
            if (value !== getValues().User.email) {
              clearErrors('User.email');
            }
            setValue('User.email', value);
            setValue('User.username', value);

          }}
        />
        {errors.User?.email && <div className='alert alert-danger mt-1 mb-0 p-2'>{errors.User.email?.message}</div>}
      </FormGroup>
      <FormGroup>
        <label>Password</label>
        <InputGroup>
          <Input
            type={showPassword ? 'password' : 'string'}
            className='form-control'
            name={'User.password'}
            placeholder='Enter password'
            onChange={(e) => {
              const value = e.target.value;
              if (value !== getValues().User.password) {
                clearErrors('User.password');
              }
              setValue('User.password', value);
            }}
          />
          <Button className="password-btn " onClick={() => setShowPassword((v) => !v)} aria-label="Show password">
            {!!showPassword ? (
              <FontAwesomeIcon icon={faEye} className="mx-auto" />
            ) :
              <FontAwesomeIcon icon={faEyeSlash} className="mx-auto" />
            }
          </Button>
        </InputGroup>
      </FormGroup>
      {errors.User?.password && <div className='alert alert-danger mt-1 mb-0 p-2'>{errors.User.password?.message}</div>}

      <FormGroup>
        <label>Confirm Password</label>
        <InputGroup>
          <Input
            type={showPasswordConfirm ? 'password' : 'string'}
            className='form-control'
            name={'User.password_confirm'}
            placeholder='Confirm password'
            rules={{
              required: false,
              validate: {
                passwordEqual: (value) => value === getValues().User.password || 'Passwords do not match!',
              },
            }}
            onChange={(e) => {
              const value = e.target.value;
              if (value !== getValues().User.password_confirm) {
                clearErrors('User.password_confirm');
              }
              setValue('User.password_confirm', value);
            }}
          />
          <Button className="password-btn " onClick={() => setShowPasswordConfirm((v) => !v)} aria-label="Show password confirm">
            {!!showPasswordConfirm ? (
              <FontAwesomeIcon icon={faEye} className="mx-auto" />
            ) :
              <FontAwesomeIcon icon={faEyeSlash} className="mx-auto" />
            }
          </Button>
        </InputGroup>
      </FormGroup>
      {errors.User?.password_confirm && <div className='alert alert-danger mt-1 mb-0 p-2'>{errors.User.password_confirm?.message}</div>}
      {!!showStudyCode && (
        <FormGroup>
          <label>Your Study Code</label>
          <Input
            type='text'
            className='form-control'
            name={'User.study_code'}
            placeholder='XXXX-XXXX-XXXX'
            rules={{
              required: true
            }}
            onChange={(e) => {
              const value = e.target.value;
              if (value !== getValues().User.study_code) {
                clearErrors('User.study_code');
              }
              setValue('User.study_code', value);

            }}
          />
          {errors.User?.study_code && <div className='alert alert-danger mt-1 mb-0 p-2'>{errors.User.study_code?.message}</div>}
        </FormGroup>
      )}
      {accountType !== 'an Organisational' &&
        <Disclaimers accountType={accountType} setGreyRegister={setGreyRegister} />
      }

    </>
  );
};

export default CreateAccount;
