import React, { useState, useEffect } from "react";
import { Header, Footer, Page } from 'components/common';
import { Media, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { usePriceOptions } from "../subscription/stripe/hooks/usePriceOptions";
// CSS
import 'react-slideshow-image/dist/styles.css';
import 'assets/css/style.css';

export const Signup = () => {

	const [showPersonalHelp, setShowPersonalHelp] = useState(false);
	const [showCorporateHelp, setShowCorporateHelp] = useState(false);
	const [showAcademicHelp, setShowAcademicHelp] = useState(false);
	const priceOptions = usePriceOptions("stripe/individual_prices");
	const [individualPricePerMonth, setIndividualPricePerMonth] = useState(5);
	const [individualPricePerYear, setIndividualPricePerYear] = useState(45);

	const flavourText = "If you need any help registering an account, please email us at hello@self-therapist.com"

	useEffect(() => {
		//eslint-disable-next-line
		var year = priceOptions.filter(priceOption => priceOption?.recurring?.interval == 'year');
		if (year[0]) {
			setIndividualPricePerYear(year[0]?.unit_amount / 100)
		}
		//eslint-disable-next-line
		var month = priceOptions.filter(priceOption => priceOption?.recurring?.interval == 'month');
		if (month[0]) {
			setIndividualPricePerMonth(month[0]?.unit_amount / 100)
		}
	}, [priceOptions])

	const SignupType = ({ name, to, desc, help, state = null, additionalStyle }) => {
		return (
			<>
				<div className="px-6 py-4 m-auto border-dark second-btn signup-type-box"
					style={additionalStyle}
				>
					<div className="pb-heading">
						<h4>
							{name}
							<Media
								object
								className="d-inline-block ml-2"
								src={'/icons/hover.png'}
								style={{ maxWidth: "25px", paddingBottom: "5px" }}
								onClick={() => help(x => !x)}
								aria-label={"Press for more information"}
							/>
						</h4>
					</div>
					<div>{desc}</div>
					<div className="d-flex justify-center apply-pad mt-5">
						<Link to={to} state={state} className="h-10 btn btn-primary">
							Get Started
						</Link>
					</div>
				</div>
			</>
		)
	}

	const HelpText = ({ text, link }) => {
		return (
			<Col id="footer-area" className="bg-white rounded border border-dark flex mt-1" style={{ placeContent: 'center', textAlign: '-webkit-center' }}>
				<>
					<div className="footer-row">
						<div className="footer-text-container">
							{text} <a href={link} target="_blank" rel="noopener noreferrer"> more</a>
						</div>
					</div>
				</>
			</Col>
		)
	}

	return (
		<Page background="admin-pages" footer={<Footer textToDisplay={flavourText} displayButtons={"false"}></Footer>}>
			<Header className="mb-2" displayIcon={"false"} title={"What type of account would you like?"}></Header>
			<div className="pricing-wrapper">
				<div className="column flex-d" style={{ textAlign: '-webkit-center' }}>
					<div className="col-lg-9 mb-3 mx-auto">
						<SignupType
							name={"Personal Account"}
							desc={'This account provides unlimited access to the tool. Trial it for free without a subscription'}
							to={'/signup_user'}
							help={setShowPersonalHelp}
						/>
						{!!showPersonalHelp &&
							<HelpText
								text={`This type of account is appropriate if you want individual access to the tool, to work on your self-therapy.
									   Also choose this account if you are a mental health 'helper' and you would like some personal support for your work.  
									   The cost is £${individualPricePerMonth}/month or £${individualPricePerYear}/year -`}
								link={'https://www.self-therapist.com/personal-account'}
							/>
						}
					</div>
					<div className="col-lg-9 mb-3 mx-auto">
						<SignupType
							name={"Organisational Account"}
							desc={'This account is for administrators wanting to provide access for individuals in, or connected to their organisation'}
							to={'/signup_organisation'}
							help={setShowCorporateHelp}
						/>
						{!!showCorporateHelp &&
							<HelpText
								text={`Choose this account if you want access for your employees, patients, residents, co-workers, etc.  
									   This is also the account to use if you are a therapist wanting to provide access for your clients.  
									   Pricing is on a per invitation or per head basis, and depending on numbers, can be as low as £1 per head -`}
								link={'https://www.self-therapist.com/organisational-account'}
							/>
						}
					</div>
					<div className="col-lg-9 mb-3 mx-auto">
						<SignupType
							name={"Study Participant"}
							desc={'If you have been invited to join a clinical trial, use this account'}
							to={'/signup_study'}
							help={setShowAcademicHelp}
							additionalStyle={{ backgroundColor: "#F3F8C9", borderColor: "#F3F8C9" }}
						/>
						{!!showAcademicHelp &&
							<HelpText
								text={`You may have been invited by Self-therapist.com, your therapist, or your organisation to trial this tool in exchange for your feedback. 
									   If so, you should have been given login details and a study access code -`}
								link={'https://www.self-therapist.com/study-account'}
							/>
						}
					</div>
				</div>
			</div>
		</Page>
	);
};

export default Signup;
