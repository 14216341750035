import { useTranslation } from "react-i18next";

export const useStatusMapping = () => {
  const { t } = useTranslation();

  return {
    active: t("Active"),
    past_due: t("Overdue"),
    unpaid: t("Unpaid"),
    canceled: t("Cancelled"),
    incomplete: t("Incomplete"),
    incomplete_expired: t("Incomplete expired"),
    trialing: t("Trialing"),
  };
};
