import React from "react";
import { useNavigate } from 'react-router';
import { useUserContext } from '../../hooks';
import { Link } from 'react-router-dom';
import { FormGroup, Input, SubmitButton } from '../../components/react-hook-form';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import { Footer, Page } from 'components/common';
import moment from 'moment';

export const Disclaimer = ({
  accountType = null,
}) => {
  const access_to_therapist = JSON.parse(localStorage.getItem("access_to_therapist"))?.value;
  const not_in_crisis = JSON.parse(localStorage.getItem("not_in_crisis"))?.value;
  const agreed_to_terms = JSON.parse(localStorage.getItem("agreed_to_terms"))?.value;
  const of_age = JSON.parse(localStorage.getItem("of_age"))?.value;
  const navigate = useNavigate();
  const { user } = useUserContext();
  const flavourText = "Make sure you're happy with these statements before continuing. You'll be asked to agree with them from time to time as you use the tool."
  const methods = useForm({
    defaultValues: {
      'not_in_crisis': not_in_crisis,
      'access_to_therapist': access_to_therapist,
      'agreed_to_terms': agreed_to_terms,
      'of_age': of_age,
    },
    mode: 'all',
  });
  const { handleSubmit } = methods;

  function onSubmit(values) {
    try {
      localStorage.setItem("access_to_therapist", JSON.stringify({ value: values.access_to_therapist, date: new Date().toLocaleDateString() }));
      localStorage.setItem("not_in_crisis", JSON.stringify({ value: values.not_in_crisis, date: new Date().toLocaleDateString() }));
      localStorage.setItem("agreed_to_terms", JSON.stringify({ value: values.agreed_to_terms, date: new Date().toLocaleDateString() }));
      localStorage.setItem("of_age", JSON.stringify({ value: values.of_age, date: new Date().toLocaleDateString() }));
      const accessToTherapist = JSON.parse(localStorage.getItem("access_to_therapist"))?.value;
      const notInCrisis = JSON.parse(localStorage.getItem("not_in_crisis"))?.value;
      const agreedToTerms = JSON.parse(localStorage.getItem("agreed_to_terms"))?.value;
      const ofAge = JSON.parse(localStorage.getItem("of_age"))?.value;
      if (user?.subscription_status === 'active_study') {
        if (!accessToTherapist || !notInCrisis || !agreedToTerms || !ofAge) {
          alert("To be able to continue with the tool, please be sure you agree with each of the statements.");
        }
        else {
          const d = new Date(moment().add(1, 'M'));
          var object = { value: true, date: d }
          localStorage.setItem('all_accepted', JSON.stringify(object));
          navigate(-1);
        }
      }
      else {
        if (!accessToTherapist || !notInCrisis || !ofAge) {
          alert("To be able to continue with the tool, please be sure you agree with each of the statements.");
        }
        else {
          const d = new Date(moment().add(1, 'M'));
          // eslint-disable-next-line
          var object = { value: true, date: d }
          localStorage.setItem('all_accepted', JSON.stringify(object));
          navigate(-1);
        }

      }
    } catch (ex) {
      const status = ex?.response?.status;
      if (status === 400) {
        return;
      } else {
        throw ex;
      }
    }
  }
  return (
    <Page background="admin-pages" footer={<Footer textToDisplay={flavourText} displayButtons={"false"}></Footer>}>
      <Row className='align-items-center h-100 mt-2'>
        <Col className='i-know-card text-center'>
          <FormProvider {...methods}>
            <h3 className='my-4'>Please confirm the following to continue with the Self-therapist tool:</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Row className="justify-content-start">
                  <Col xs={3}>
                    <Input
                      id='not_in_crisis'
                      type='checkbox'
                      className='float-right'
                      name={'not_in_crisis'}
                      rules={{
                        required: false,
                      }}
                      defaultChecked={!!not_in_crisis}
                      style={{ width: "35px", height: "35px" }}
                    />
                  </Col>
                  <label className="col-9 text-left" htmlFor='not_in_crisis'>I am not in a state of mental health crisis <a href='https://www.self-therapist.com/crisis-information' target="_blank" rel="noopener noreferrer">(view our crisis page if you are)</a></label>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className="justify-content-start" style={{ alignItems: 'center' }}>
                  <Col xs={3}>
                    <Input
                      id='access_to_therapist'
                      type='checkbox'
                      className='float-right'
                      name={'access_to_therapist'}
                      rules={{
                        required: false,
                      }}
                      defaultChecked={!!access_to_therapist}
                      style={{ width: "35px", height: "35px" }}
                    />
                  </Col>
                  <label className="col-9 text-left" htmlFor='access_to_therapist'>I have access to mental health support if I need it</label>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className="justify-content-start" style={{ alignItems: 'center' }}>
                  <Col xs={3}>
                    <Input
                      id='of_age'
                      type='checkbox'
                      className='float-right'
                      name={'of_age'}
                      rules={{
                        required: false,
                      }}
                      defaultChecked={!!of_age}
                      style={{ width: "35px", height: "35px" }}
                    />
                  </Col>
                  <label className="col-9 text-left" htmlFor='of_age'>I agree to the Terms of Use, and I am over 18 years of age</label>
                </Row>
              </FormGroup>
              {user?.subscription_status === 'active_study' && (
                <FormGroup>
                  <Row className="justify-content-start" style={{ alignItems: 'center' }}>
                    <Col xs={3}>
                      <Input
                        id='agreed_to_terms'
                        type='checkbox'
                        className='float-right'
                        name={'agreed_to_terms'}
                        rules={{
                          required: false,
                        }}
                        defaultChecked={!!agreed_to_terms}
                        style={{ width: "35px", height: "35px" }}
                      />
                    </Col>
                    <label className="col-9 text-left" htmlFor='agreed_to_terms'>I have read and agree to the Participant Consent statements in the <a href='https://www.self-therapist.com/study-consent' target="_blank" rel="noopener noreferrer">Study Consent document</a></label>
                  </Row>
                </FormGroup>
              )}

              <div className="justify-content-start m-auto" style={{ alignItems: 'center' }}>
                <SubmitButton className="btn-primary main-btn my-3 border-0 text-white h-10">Continue</SubmitButton>
              </div>
            </form>
          </FormProvider>
        </Col>
      </Row>
    </Page>
  );
}

export default Disclaimer;
