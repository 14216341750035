import React, { useState, useEffect } from "react";
import {
  Button, Row, Col, Alert
} from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useClient, useUserContext, useModal } from "../../hooks";
import { Page, Header, LoadingSpinner } from 'components/common';
import { NotificationManager } from 'react-notifications';
import Dropzone from "react-dropzone";
import { InviteUserModal, BlockUserModal, AdminUserList, PendingUserList } from '../Admin'
import {
  Input,
  SubmitButton,
  FormGroup,
} from "../../components/react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const EditOrganisation = () => {

  const methods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit, setValue } = methods;
  const navigate = useNavigate();
  const client = useClient();
  const [organisationDetails, setOrganisationDetails] = useState();
  const [pendingUsers, setPendingUsers] = useState();
  const [logo, setLogo] = useState();
  const params = useParams();
  const { user } = useUserContext();
  const param_id = params?.id;
  const id = param_id ?? user.organisation?.id;
  const { Modal, toggle } = useModal();
  const { Modal: blockModal, toggle: blockToggle } = useModal();

  const url = `organisations/${id}`;

  useEffect(() => {
    if (id === 'create') {
      return;
    }
    client.get(url).then(({ data }) => {
      setOrganisationDetails(data);
      setPendingUsers(data.admin_info.users);
      setLogo(data.logo);
    }).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Could not retrieve your organisation. Please contact sue@suerenger.co.uk for help.");
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    if (organisationDetails) {
      console.log(organisationDetails);
      setValue('name', organisationDetails.name);
      // setValue('onboarding_url', organisationDetails.onboarding_url);
      setValue('popups_enabled', organisationDetails.popups_enabled);
      setValue('non_subscription_organisation', organisationDetails.non_subscription_organisation);
      setValue('show_study_feedback', organisationDetails.show_study_feedback);


    }
  }, [organisationDetails, setValue]);


  async function onSubmit(x) {
    if (id === 'create') {
      client
        .post('organisations', x)
        .then(e => {
          NotificationManager.success("Organisation saved successfully");
          navigate('../' + e.data.id);

        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    } else {
      client
        .patch(url, x)
        .then(e => {
          NotificationManager.success("Organisation saved successfully");
          navigate(0);

        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    }
  }
  if (!organisationDetails && id !== 'create') { return <LoadingSpinner /> }
  return (
    <Page background="admin">
      {!!organisationDetails && (
        <>
          <InviteUserModal toggle={toggle} Modal={Modal} organisation={organisationDetails} />
          <BlockUserModal toggle={blockToggle} Modal={blockModal} organisation={organisationDetails} />
        </>

      )}

      <Header className="mb-2" displayIcon={"false"} title={id === 'create' ? 'Create Organisation' : 'Edit Organisation'}></Header>
      <Row>
        <Col>
          <Alert className="text-center" color={organisationDetails?.subscription_status === 'active' ? 'success' : 'danger'}>
            <b >{organisationDetails?.subscription_status === 'active' ? 'Active' : 'Inactive'} Subscription</b>
            <div className="d-flex justify-content-between">

              <Button color="primary" onClick={toggle}>Invite</Button>
              <Button color="danger" onClick={blockToggle}>Remove User</Button>
            </div>
          </Alert>
          {/* <button className={classNames("btn float-right", organisationDetails?.subscription_status === 'active' ? 'btn-success' : 'btn-danger')}>{organisationDetails?.subscription_status === 'active' ? 'Active' : 'Inactive'} </button> */}

        </Col>
      </Row>
      <FormProvider {...methods}>
        <form onSubmit={(e) => e.preventDefault()}>
          <FormGroup label={"Organisation Name"} name={"name"} required>
            <Input
              type='text'
              className='form-control'
              name={'name'}
              placeholder='Organisation Name'
              rules={{
                required: true,
                maxLength: 255
              }}
            />
          </FormGroup>
          <FormGroup label="Logo" name="logo">

            <Dropzone
              multiple={false}
              className="border border-primary rounded"
              style={{}}
              onDrop={acceptedFiles => console.log(acceptedFiles)}
              onDropAccepted={files => {

                const reader = new FileReader();
                reader.onload = (e) => {
                  setLogo(e.target.result);
                  setValue('logo', e.target.result);
                };
                files.forEach(file => reader.readAsDataURL(file));

                // reader.readAsDataURL(file);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <>
                  {!!logo && (
                    <>
                      <div className="contain_upload my-2">
                        <img
                          alt="logo"
                          className="img-thumbnail d-inline "
                          src={logo}
                        />
                        <Button
                          className="d-inline "
                          onClick={() => {
                            setLogo(null);
                            setValue('logo', null);
                            // dispatch(actions.change("local.logo", null));
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </>

                  )}
                  <div className="m-2 text-center border border-primary rounded">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag and drop, or click here to upload a file</p>
                    </div>

                  </div>
                </>)}
            </Dropzone>

          </FormGroup>
          {/* <FormGroup label={"Onboarding URL"} name={"onboarding_url"}>
            <Input
              type='url'
              className='form-control'
              name={'onboarding_url'}
              placeholder='Organisation URL'
              rules={{
                required: false,
                maxLength: 255
              }}
            />
          </FormGroup> */}
          <FormGroup label={"Enable Self-therapy guides?"} name={"popups_enabled"} check>
            <Input
              type='checkbox'
              className='form-check-input'
              name={'popups_enabled'}
              defaultChecked={organisationDetails ? organisationDetails?.popups_enabled : true}

            />
          </FormGroup>
          {!!user.is_superuser && (
            <>
              <FormGroup label={"Enable free organisation"} name={"non_subscription_organisation"} check>
                <Input
                  type='checkbox'
                  className='form-check-input'
                  name={'non_subscription_organisation'}
                  defaultChecked={organisationDetails?.non_subscription_organisation}

                />
              </FormGroup>

              <FormGroup label={"Show Study Feedback"} name={"show_study_feedback"} check>
                <Input
                  type='checkbox'
                  className='form-check-input'
                  name={'show_study_feedback'}
                  defaultChecked={organisationDetails?.show_study_feedback}

                />
              </FormGroup>
            </>
          )}

          <SubmitButton onClick={handleSubmit(onSubmit)} className="btn-primary my-3 border-0 text-white h-10">
            Save
          </SubmitButton>

        </form>
      </FormProvider>




      {!!organisationDetails && (
        <AdminUserList organisation={organisationDetails} />
      )}

      {!!pendingUsers && (
        <PendingUserList organisation={pendingUsers} />
      )}



    </Page>
  );
};
export default EditOrganisation;
