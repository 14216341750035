import { useModal } from "./index";
import { useGetLatest } from "react-table";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import { Button, ModalFooter } from "reactstrap";
import { SubmitButton } from "../components/react-hook-form";

export const useConfirmModal = ({ confirmText: confirmTextProp } = {}) => {
  const { Modal, isOpen, setIsOpen, toggle } = useModal();
  const getLatest = useGetLatest({ isOpen, Modal, setIsOpen, toggle });
  const methods = useForm();
  const { t } = useTranslation();
  const { control, handleSubmit } = methods;
  const confirmText = confirmTextProp ?? t("Confirm");

  const ConfirmModal = useCallback(
    ({
      children,
      onConfirm,
      submitButtonProps,
      autoClose = true,
      ...props
    }) => {
      const { isOpen, setIsOpen, Modal } = getLatest();
      if (!isOpen) return null;

      async function onSubmit(...args) {
        await onConfirm(...args);

        if (autoClose) {
          setIsOpen(false);
        }
      }

      return (
        <Modal {...props}>
          {children}
          <ModalFooter>
            <Button
              className="mr-2"
              color="link"
              onClick={() => setIsOpen((v) => !v)}
            >
              <Trans>Close</Trans>
            </Button>
            <SubmitButton
              {...submitButtonProps}
              control={control}
              onClick={handleSubmit(onSubmit)}
            >
              {confirmText}
            </SubmitButton>
          </ModalFooter>
        </Modal>
      );
    },
    [getLatest, confirmText, control, handleSubmit]
  );

  return {
    Modal: ConfirmModal,
    setIsOpen,
    isOpen,
    toggle,
  };
};

export default useConfirmModal;
