import React from "react";
import {Button} from 'reactstrap';
import Modal from 'react-bootstrap/Modal';

export const QuickModal = ({
  title,
  open,
  setOpen,
  saveText=false,
  saveFunction,
  children,
  ...props
}) => {


  const handleClose = () => setOpen(false);

  const handleSave = () => {
    saveFunction();
    setOpen(false)
  };

  return(
    <Modal show={open} onHide={handleClose} {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button color="danger" onClick={handleClose}>
          Close
        </Button>
        {!!saveText && (
          <Button color="success" onClick={handleSave}>
          {saveText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}


export default QuickModal;
