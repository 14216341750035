import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import { Row, Col } from 'reactstrap';
import { Page, Header } from 'components/common';

export const OnboardingInformation = () => {

  const [showFollowUp, setShowFollowUp] = useState(false);
  const navigate = useNavigate();


  const changeShowFollowUp = () => {
    if (showFollowUp === false) {
      setShowFollowUp(true);
    }
    else {
      setShowFollowUp(false);
    }
  };

  const completed_onboarding = localStorage.getItem("completed_onboarding");

  useEffect(() => {
    navigate("/decision_tree_options");
    if (completed_onboarding === 'true') {
      navigate("/decision_tree_options");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed_onboarding])

  const moveForwards = () => {
    localStorage.setItem("completed_onboarding", 'true');
    navigate("/decision_tree_options");
  }

  return (
    <Page background="admin-pages">
      <Header className="mb-2" displayIcon={"false"} title={"Instructions for you"}>
        <div onClick={() => moveForwards()} className="btn-primary main-btn question-grid-items my-3 border-0 text-white h-10">
          Continue
        </div>
      </Header>
      <Row className='align-items-center h-100 mt-2'>
        <Col className='i-know-card' style={{ textAlign: '-webkit-center' }}>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="my-1"><b>Instructions for you</b></h4>
        </Col>
      </Row>
      <h6 className="my-4">
        1: The first part helps you to decide what you want to achieve. Do that on your own.<br /><br />
        2: In the second part, there are lots of questions for you to think about. Your helper can listen to you as you answer them. <b>You</b> choose which question you want to think through.
        You can choose them beforehand, or as you go through the tool with your helper.
        It's important that your helper doesn't tell you which question <i>they</i> think you <i>should</i> focus on. <br /><br />
        <b>However</b>, if your helper is a qualified counsellor or psychotherapist, it's fine for them to direct you to certain parts of the tool that might help you.
      </h6>
      <br />

      <h4 className="my-4"><b>Instructions for your helper</b></h4>
      <h6 className="my-4">Make sure your helper knows how to help you. They need to:</h6>
      <h6 className="my-4">
        <li>Be willing to listen patiently and carefully to your answers.</li>
        <li>Try to imagine what it's like to be you.</li>
        <li>Try to understand what you're saying. They can do that by explaining what they think you mean.</li>
        <li>Avoid judging you in any way, or trying to fix things for you.</li>
        <li>Keep confidential anything you say, unless they think you might hurt yourself or someone else.</li>
      </h6>

      <Row className="mt-6">
        <Col xs={'11'} md={'3'} onClick={() => changeShowFollowUp()}>
          <a href="/guides/20">
            More information for helpers
          </a>
        </Col>
        {/* {!!showFollowUp && (
          <Col xs={'11'} md={'7'} className="d-flex align-content-stretch m-auto mobile-padlow">
            <div className="" style={{ fontSize: "1rem" }} >
              If you're interested in developing your skills as a helper or listener, we would recommend 'The Skilled Helper' by Gerard Egan, particularly the chapters on listening
              and empathetic responding. For further help, Kathryn and David Gelard in their book 'Practical Counselling Skills: an Integrative Approach' have a useful chapter on
              the use and abuse of questions.
            </div>
          </Col>
        )} */}
      </Row>

    </Page>
  );
};

export default OnboardingInformation;
