import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { useClient } from 'hooks';
import { Row, Col } from 'reactstrap';
import { Header, Page, Footer, Breadcrumbs, cipher } from 'components/common';

export const QuestionInfo = () => {
	const client = useClient();
	const params = useParams();
	const [chosenElement, setChosenElement] = useState();
	const [AimId, setAimId] = useState(null);
	const [aimNameOverride, setAimNameOverride] = useState();
	const flavourText = ""
	const { state } = useLocation();
	const origin = state?.from
	const pageHead = "";

	useEffect(() => {
		client.get(`element/${cipher.deobfuscate(params['element_id'])}`).then(e => {
			setChosenElement(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve your chosen element. Please try again.");
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		client.get(`personalisedaim?Element_id=${cipher.deobfuscate(params['element_id'])}`).then(e => {
			var dataset = e['data'];
			if (dataset.length > 0) {
				var data = dataset[0];
				setAimNameOverride(data.personalised_aim_text);
				setAimId(data.id);
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Page background="admin-pages" footer={<Footer textToDisplay={flavourText} displayButtons={"true"} specificAim={AimId}></Footer>}>
			<Breadcrumbs parent={'Rate Myself'} current={'Helpful Information'} parentLink={`/self_feedback/${params['element_id']}`} />
			<Header className="mb-2" displayIcon={"false"} title={pageHead}></Header>
			{!!chosenElement && (
				<Row className='align-items-center h-100 mt-2'>
					<Col className='i-know-card text-center'>
						<h5 className="aimbot-black my-4">Aim {chosenElement.key}: <b className="aimbot-blue my-4">{chosenElement.name}</b></h5>
						{aimNameOverride && (
							<h5 className="aimbot-black my-4">My specific aim is to: <b className="aimbot my-4">{aimNameOverride}</b></h5>
						)
						}
						<h6 className="mb-6" style={{ lineHeight: 'inherit' }}><b>Now you have decided exactly what you want to achieve through therapy, the tool will present you with some questions that a therapist might ask you, relating to your aim. Each of the questions could easily take an hour of therapy to talk through. Just thinking them through in detail will activate your 'actualizing tendency', or your self-healing process.</b></h6>
						<li className="text-left">Tailor the question to your personal aim if you have one</li>
						<li className="text-left">Try to think as deeply as possible about the question</li>
						<li className="text-left">Think about what you're experiencing when you're considering the question, and notice any negative reactions you might have</li>
						<li className="text-left">Think about the question over time, in different situations</li>
						<li className="text-left">Make sure you're focusing on what you <i>truly</i> think or feel, rather than what you think the answer 'should' be</li>
						<li className="text-left">Think "What does my answer mean for what I want to achieve?"</li>

						<Row className="justify-content-center p-3">
							<Link to={`/element_chosen/${cipher.obfuscate(chosenElement.id)}`}
								state={{ from: origin }}
								className="flex items-center btn-primary main-btn my-3 border-0 text-white h-10"
							>
								Continue
							</Link>
						</Row>

					</Col>
				</Row>
			)}
		</Page>
	);
};

export default QuestionInfo;
