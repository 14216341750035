import React, { useEffect } from "react";
import {
  Route as BaseRoute,
  Routes,
  Navigate,
  useNavigate
} from "react-router-dom";
import {
  Disclaimer,
  Help,
  Login,
  Signup,
  Home,
  DecisionTree,
  ThinkingPlace,
  Keywords,
  SuperAdmin,
  Admin,
  Invitation,
  Users,
  Welcome,
  VerifyEmail
} from "./Pages";
import { Page, AimReports } from "components/common";
import ReactGA from 'react-ga4';
import { useUserContext, useClient } from 'hooks';

import { useTranslation } from "react-i18next";
import AuthenticatedBase from "./AuthenticatedBase";
import UnauthenticatedBase from "./UnauthenticatedBase";
import { CreateSubscription, UpdateSubscription, SubscriptionView, StripeElement } from "./Pages/subscription"
import { getCookieConsentValue } from "react-cookie-consent";
import { NotificationManager } from 'react-notifications';



const Forbidden = () => {
  return (
    <AuthenticatedBase>
      <Page title="Unauthorised">
        You do not have permission to view this page
      </Page>
    </AuthenticatedBase>
  );
};
const SubscriptionForbidden = () => {
  return (
    <AuthenticatedBase>
      <Page title="Unauthorised">
        <p>
          Oh no, it appears your subscription has been cancelled or has expired.
        </p>
        <p>
          Therefore you do not have permission to view this page, please contact your organisation administrator to renew the membership.
        </p>
      </Page>
    </AuthenticatedBase>
  );
};
const PleaseVerifyEmail = () => {
  const { refreshUser, user } = useUserContext();
  const client = useClient();

  const navigate = useNavigate();

	  function resendVerification() {
    client.post("reverify_email").then(e => {
      refreshUser();
      NotificationManager.success("Email sent!");

    }).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Could not send your email verification, please check it is correct in the My Account area.");
    }); 
  } 

  useEffect(() => {
    const id = setInterval(() => {
      refreshUser();
    }, 10000);
    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthenticatedBase>
      <Page title="Please Verify Your Email">
        <p className={'text-center'}>
          {"A validation link has been sent to your email address. Please follow it and log on. Emails should be delivered instantly. If you don't receive one, check your spam folder or press 'resend verification email'."}
        </p>
        <div className="row pt-5">
          <div className="col-sm-4"><button className="mx-auto d-block" style={{ color: 'blue' }} onClick={() => resendVerification()}>Resend verification email?</button></div>
          <div className="col-sm-4"><button className="mx-auto d-block" style={{ color: 'blue' }} onClick={() => navigate('/my_account')}>Change Email?</button></div>
        </div>
      </Page>
    </AuthenticatedBase>
  );
};
export const NotFound = () => {
  return (
    <Page title="Not found">
      The page you have requested could not be found
    </Page>
  );
};

const Route = ({
  path,
  exact,
  test,
  checkUser,
  element,
  children,
  title = ' ',
  requiresSubscription,
  ...props
}) => {
  const { user } = useUserContext();
  document.title = 'Self-Therapist - ' + title;
  if (user) {
    var all_accepted_date = JSON.parse(localStorage.getItem("all_accepted"))?.date
    var now = new Date();
    if (typeof all_accepted_date == 'undefined' || all_accepted_date < now) {
      localStorage.setItem("access_to_therapist", JSON.stringify({ value: false, date: new Date().toLocaleDateString() }));
      localStorage.setItem("not_in_crisis", JSON.stringify({ value: false, date: new Date().toLocaleDateString() }));
      localStorage.setItem("agreed_to_terms", JSON.stringify({ value: false, date: new Date().toLocaleDateString() }));
      if (!window.location.pathname.includes('disclaimer')) {
        return <Navigate to='/disclaimer' />;
      }
    }
  }
  if (getCookieConsentValue('acceptedAllCookies') === 'true') {
    ReactGA.initialize([
      {
        trackingId: 'G-DN5XNCZ2DB',
      },
    ]);
  }
  // ReactGA.initialize('UA-222138663-1');
  //
  // useEffect(() => {
  //   const collection = document.getElementsByClassName("btn-primary");
  //
  //   for (let i = 0; i < collection.length; i++){
  //     console.log(collection);
  //     if(collection[i]){
  //       // collection[i].classList.remove('btn');
  //       // collection[i].classList.add('main-btn');
  //       // collection[i].classList.remove('btn-primary');
  //     }
  //
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [path])


  window.scrollTo(0, 0);

  // if (!window.location.pathname.includes('disclaimer') && !accessToTherapist && !notInCrisis && !agreedToTerms) {
  //   if(!window.location.pathname.includes('privacy_policy') && !window.location.pathname.includes('terms_of_use') && !window.location.pathname.includes('crisis_page') && !window.location.pathname.includes('consent_agreement') && !window.location.pathname.includes('welcome') && !window.location.pathname.includes('onboarding') && !window.location.pathname.includes('confidentiality')){
  //     return <Navigate to='/disclaimer' />;
  //   }
  // }
  if (checkUser && !user) {
    return <Navigate to='/welcome' />;

  } else if (requiresSubscription && !(user.subscription_status === 'active' || user.subscription_status === 'active_trial' || user.subscription_status === 'active_study') && !user.is_superuser) {
    if (user?.can_access_subscriptions) {
      return <Navigate to='/stripe-subscription' />;
    } else {
      return <SubscriptionForbidden />;
    }
  // } else if (checkUser && !user?.email_verified && path !== 'my_account') {
  //   // I hope she is sure about this.
  //   return <PleaseVerifyEmail />;

  } else if (test !== undefined && !test) {
    return <Forbidden />;
  } else if ((window.location.pathname === '/' || window.location.pathname === '') && user) {
    // This redirect is actually needed
    return <Navigate to='/aims_list' />;
  }
  return (
    <>
      <BaseRoute
        path={path}
        exact={exact}
        element={element}
        {...props}

      >{children}</BaseRoute>
    </>
  );
};


const AuthenticatedRoute = ({ element, children, ...props }) => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });

  return (
    <Route
      checkUser
      element={<AuthenticatedBase>{element}</AuthenticatedBase>}
      {...props}
    >
      {children}
    </Route>
  );
};

const UARoute = ({ element, ...props }) => {
  return (
    <Route
      element={<UnauthenticatedBase>
        {element}</UnauthenticatedBase>}
      {...props}
    />
  );
}

export const AppRoutes = () => {
  const { user } = useUserContext();
  useTranslation();
  return (
    <Routes>
      {/* <Route path="login" element={<Login.Login/>} /> */}
      {/*
        <Route path="login/student" element={<Login.LoginStudent/>} />
        <Route path="signup" element={<Signup.Signup/>} />

        <AuthenticatedRoute
          path="/overview"
          element={<Overview.Overview/>}
          test={user ? true : false}
        />
        <AuthenticatedRoute
          path="users"
          element={<Users.List/>}
          test={user ? user.is_superuser : false}
        />
        */}
      <AuthenticatedRoute
        path="my_account"
        element={<Users.MyAccount />}
        test={user ? true : false}
      />
      <UARoute path="reset_password/:token" element={<Login.ResetPassword />} />
      <UARoute
        path="reset_password_request"
        element={<Login.ResetPasswordRequest />}
      />
      <UARoute path='disclaimer' element={<Disclaimer.Disclaimer />} />
	  {/* <UARoute path="/verify_email/:token" element={<VerifyEmail />} />*/}


      {!!user ?
        <>
          <AuthenticatedRoute path='privacy_policy' element={<Help.PrivacyPolicy />} />
          <AuthenticatedRoute path='terms_of_use' element={<Help.TermsOfUse />} />
          <AuthenticatedRoute path='crisis_page' element={<Help.CrisisPage />} />
          <AuthenticatedRoute path='consent_agreement' element={<Help.ConsentAgreement />} />
          <AuthenticatedRoute path='about_us' element={<Help.AboutUs />} />
          <AuthenticatedRoute path='contact_us' element={<Help.ContactUs />} />
        </>
        :
        <>
          <UARoute path='privacy_policy' element={<Help.PrivacyPolicy />} />
          <UARoute path='terms_of_use' element={<Help.TermsOfUse />} />
          <UARoute path='crisis_page' element={<Help.CrisisPage />} />
          <UARoute path='consent_agreement' element={<Help.ConsentAgreement />} />
          <UARoute path='about_us' element={<Help.AboutUs />} />
          <UARoute path='contact_us' element={<Help.ContactUs />} />
          <UARoute path='help' element={<Help.Help />} />
          <UARoute path='the_research' element={<Help.TheResearch />} />
        </>
      }
      <UARoute path='login' element={<Login.Login />} />
      <UARoute path='signup' element={<Signup.Signup />} />
      <UARoute path='signup_organisation' element={<Signup.SignupOrganisation />} />
      <UARoute path='signup_user' element={<Signup.SignupUser />} />
      <UARoute path='signup_study' element={<Signup.SignupUser showStudyCode={true} accountType={'a Study Participant'} />} />

      <UARoute path='welcome' element={<Welcome.Welcome />} />
      <UARoute path='onboarding' element={<Welcome.Onboarding />} />
      <UARoute path='confidentiality' element={<Welcome.Confidentiality />} />

      <UARoute exact path="invite/:token" element={<Invitation />} />



      <AuthenticatedRoute path='/' element={<Home.Home />} />
      <AuthenticatedRoute path='' element={<Home.Home />} />
      <AuthenticatedRoute path='home' element={<Home.Home />} />

      {/* Pages */}

      <AuthenticatedRoute path='decision_tree_start' requiresSubscription element={<DecisionTree.DecisionTreeStart />} />
      <AuthenticatedRoute path='decision_tree_options' requiresSubscription element={<DecisionTree.DecisionTreeOptions />} />
      <AuthenticatedRoute path='decision_tree_i_know' requiresSubscription element={<DecisionTree.DecisionTreeIKnow />} />
      <AuthenticatedRoute path='decision_tree_i_would_like' requiresSubscription element={<DecisionTree.DecisionTreeWouldLike />} />
      <AuthenticatedRoute path='decision_tree_self_assessment' requiresSubscription element={<DecisionTree.DecisionTreeQuiz />} />
      <AuthenticatedRoute path='aim_statements' requiresSubscription element={<DecisionTree.AimStatementsQuiz />} />
      <AuthenticatedRoute path='quality_statements' requiresSubscription element={<DecisionTree.ElementsQuiz />} />
      <AuthenticatedRoute path='choose_broad' requiresSubscription element={<DecisionTree.ChooseBroad />} />
      <AuthenticatedRoute path='choose_specific/:broad_category_id' requiresSubscription element={<DecisionTree.ChooseSpecific />} />
      <AuthenticatedRoute path='choose_quality/:specific_category_id' requiresSubscription element={<DecisionTree.ChooseQuality />} />
      <AuthenticatedRoute path='choose_element/:quality_id' requiresSubscription element={<DecisionTree.ChooseElement />} />
      <AuthenticatedRoute path='element_chosen/:element_id' requiresSubscription element={<DecisionTree.ElementChosen />} />
      <AuthenticatedRoute path='question_info/:element_id' requiresSubscription element={<DecisionTree.QuestionInfo />} />
      <AuthenticatedRoute path='aim_reflection/:element_id' requiresSubscription element={<DecisionTree.AimReflection />} />
      <AuthenticatedRoute path='self_feedback/:element_id' requiresSubscription element={<DecisionTree.SelfFeedback />} />
      <AuthenticatedRoute path='question_list/:element_id/:question_type_group' requiresSubscription element={<DecisionTree.QuestionList />} />
      <AuthenticatedRoute path='question_list/:element_id/:question_type_group/:qid' requiresSubscription element={<DecisionTree.QuestionList />} />
      <AuthenticatedRoute path='notebook' requiresSubscription element={<ThinkingPlace.ThinkingPlace />} />
      <AuthenticatedRoute path='notebook/:id' requiresSubscription element={<ThinkingPlace.ThinkingPlace />} />
      <AuthenticatedRoute path='aims_list' requiresSubscription element={<ThinkingPlace.AimsTable />} title={"Notes Contents"} />

      <AuthenticatedRoute path='what_do_i_do' element={<Help.WhatDoIDo />} />
      <AuthenticatedRoute path='help' element={<Help.Help />} />
      <AuthenticatedRoute path='how_it_works' element={<Help.HowItWorks />} />
      <AuthenticatedRoute path='the_research' element={<Help.TheResearch />} />
      <AuthenticatedRoute path='onboarding_instructions' element={<Help.OnboardingInstructions />} />
      <AuthenticatedRoute path='onboarding_information' element={<Help.OnboardingInformation />} />

      <AuthenticatedRoute path='guides/:id' requiresSubscription element={<Keywords.PopupPage name="Guide" />} />
      <AuthenticatedRoute path='guides' requiresSubscription element={<Keywords.List name="Self-Therapy Guides" type={'popup'} />} />

      <AuthenticatedRoute path='self_esteem' element={<Keywords.SelfEsteem />} />
      {/* SUPER ADMIN */}
      <AuthenticatedRoute path='super-admin' test={user?.is_superuser} element={<SuperAdmin.Dashboard />} />

      <AuthenticatedRoute path='super-admin/quality' test={user?.is_superuser} element={<SuperAdmin.List name="Quality" type={'quality'} />} />
      <AuthenticatedRoute path='super-admin/quality/:id' test={user?.is_superuser} element={<SuperAdmin.EditQuality type={'quality'} />} />

      <AuthenticatedRoute path='super-admin/category' test={user?.is_superuser} element={<SuperAdmin.List name="Category" type={'category'} />} />
      <AuthenticatedRoute path='super-admin/category/:id' test={user?.is_superuser} element={<SuperAdmin.EditCategory type={'category'} />} />

      <AuthenticatedRoute path='super-admin/categorygroup' test={user?.is_superuser} element={<SuperAdmin.List name="Category Group" type={'categorygroup'} />} />
      <AuthenticatedRoute path='super-admin/categorygroup/:id' test={user?.is_superuser} element={<SuperAdmin.EditCategoryGroup type={'categorygroup'} />} />

      <AuthenticatedRoute path='super-admin/question' test={user?.is_superuser} element={<SuperAdmin.List name="Question" type={'question'} />} />
      <AuthenticatedRoute path='super-admin/question/:id' test={user?.is_superuser} element={<SuperAdmin.EditQuestion type={'question'} />} />

      <AuthenticatedRoute path='super-admin/element' test={user?.is_superuser} element={<SuperAdmin.List name="Element" type={'element'} />} />
      <AuthenticatedRoute path='super-admin/element/:id' test={user?.is_superuser} element={<SuperAdmin.EditElement type={'element'} />} />

      <AuthenticatedRoute path='super-admin/aimstatement' test={user?.is_superuser} element={<SuperAdmin.List name="Aim Statement" type={'aimstatement'} />} />
      <AuthenticatedRoute path='super-admin/aimstatement/:id' test={user?.is_superuser} element={<SuperAdmin.EditAimStatement type={'aimstatement'} />} />

      <AuthenticatedRoute path='super-admin/assessmentstatement' test={user?.is_superuser} element={<SuperAdmin.List name="Assessment Statement" type={'assessmentstatement'} />} />
      <AuthenticatedRoute path='super-admin/assessmentstatement/:id' test={user?.is_superuser} element={<SuperAdmin.EditAssessmentStatement type={'assessmentstatement'} />} />

      <AuthenticatedRoute path='super-admin/popup' test={user?.is_superuser} element={<SuperAdmin.List name="Therapy Guide" type={'popup'} />} />
      <AuthenticatedRoute path='super-admin/popup/:id' test={user?.is_superuser} element={<SuperAdmin.EditPopup type={'popup'} />} />

      <AuthenticatedRoute path='super-admin/qualitystatement' test={user?.is_superuser} element={<SuperAdmin.List name="Quality Statement" type={'qualitystatement'} />} />
      <AuthenticatedRoute path='super-admin/qualitystatement/:id' test={user?.is_superuser} element={<SuperAdmin.EditQualityStatement type={'qualitystatement'} />} />

      <AuthenticatedRoute path='super-admin/common_issues' test={user?.is_superuser} element={<SuperAdmin.List name="Common Issues" type={'common_issues'} />} />
      <AuthenticatedRoute path='super-admin/common_issues/:id' test={user?.is_superuser} element={<SuperAdmin.EditCommonIssues type={'common_issues'} />} />
      <AuthenticatedRoute path='super-admin/studys' test={user?.is_superuser} element={<SuperAdmin.List name="Academic Studies" type={'studys'} />} />
      <AuthenticatedRoute path='super-admin/studys/:id' test={user?.is_superuser} element={<SuperAdmin.EditStudies type={'studys'} />} />
      <AuthenticatedRoute path='super-admin/organisations' test={user?.is_superuser} element={<SuperAdmin.List name="Organisations" type={'organisations'} />} />
      <AuthenticatedRoute path='super-admin/organisations/:id' test={user?.is_superuser} element={<Admin.EditOrganisation />} />



      <AuthenticatedRoute path='super-admin/user_reports' test={user?.is_superuser} element={<AimReports url="personalisedaim/report" />} />


      {/* ORG ADMIN */}
      <AuthenticatedRoute path='admin' test={user?.is_organisation_admin} element={<Admin.Dashboard />} />
      <AuthenticatedRoute path='admin/user-reports' test={user?.is_organisation_admin} element={<AimReports url="organisations/my_personalised_aims" />} />
      <AuthenticatedRoute path='admin/edit_organisation' test={user?.is_organisation_admin} element={<Admin.EditOrganisation />} />

      {/* SUBSCRIPTIONS */}
      <AuthenticatedRoute path={'stripe-subscription'} test={user?.can_access_subscriptions} element={<SubscriptionView />} />
      <AuthenticatedRoute path={'stripe-subscription/create-subscription'} test={user?.can_access_subscriptions} element={<CreateSubscription />} />
      <AuthenticatedRoute path={'stripe-subscription/update-subscription'} test={user?.can_access_subscriptions} element={<UpdateSubscription />} />
      <AuthenticatedRoute path={'stripe-subscription/create-subscription/payment-method'} test={user?.can_access_subscriptions} element={<StripeElement />} />
      <AuthenticatedRoute path={'stripe-subscription/update-subscription/payment-method'} test={user?.can_access_subscriptions} element={<StripeElement />} />

      <UARoute path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
