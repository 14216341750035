import { useEffect } from "react";
import { userSelector } from "./selectors";
import { useRecoilValueLoadable, useRecoilCallback } from "recoil";
import {
  tokenAtom,
} from "./atoms";
import { AuthenticationFailedError } from "./errors";
import { NotificationManager } from "react-notifications";

export const UserAtomEffect = () => {
  const userLoadable = useRecoilValueLoadable(userSelector);
  const { state, contents } = userLoadable;
  const hasError = state === "hasError";

  useEffect(
    useRecoilCallback(({ reset }) => () => {
      if (!hasError) return;

      if (contents instanceof AuthenticationFailedError) {
        NotificationManager.info("You have been logged out");
        reset(tokenAtom);
      }
    }),
    [hasError]
  );

  return null;
};
