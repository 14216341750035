import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { NotificationManager } from "react-notifications";

import { useClient } from 'hooks';
import { Row, Col, Button } from 'reactstrap';
import { Page, LoadingSpinner, Header } from "components/common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "./SelfFeedback.scss";
import {
	ReOrderableItem,
	ReOrderableList,
} from 'react-reorderable-list';
import { FormProvider, useForm } from 'react-hook-form';
import { SubmitButton } from 'components/react-hook-form';
import { faAngleDoubleUp, faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DecisionTreeQuiz = () => {
	const client = useClient();
	const navigate = useNavigate();
	const [qualityStatements, setQualityStatements] = useState([]);
	const [qualityStatementAnswers, setQualityStatementAnswers] = useState({});
	const pageHead = "Self Assessment";
	const [ans, setAns] = useState(null);
	const [sevenAnswers, setSevenAnswers] = useState([]);
	const [elements, setElements] = useState([]);
	const [reorderMode, setReorderMode] = useState(false);
	const [setIsBlocking] = useState(false);

	async function load() {
		try {
			const [qualityStatements, answers] = await Promise.all([client.get("qualitystatement").get("data"), client.get("quality_statement_answers").get("data")]);
			setQualityStatements(qualityStatements);
			const x = answers.reduce((prev, curr) => {
				prev[curr.QualityStatement] = curr.answer;
				if (curr.answer) {
					setValue(`rating${curr.QualityStatement}`, `${curr.answer}`);
				}
				return prev;
			}, {});
			setQualityStatementAnswers(x);
		}
		catch (exc) {
			console.warn(exc);
			NotificationManager.error("Could not retrieve quality statements. Please try again.");
		}
	}

	useEffect(() => {
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	async function submitAnswers(formData) {
		const qids = Object.keys(formData).map(e => e.slice(6));
		const answerMatrix2 = qids.map(q => (
			{ questionId: q, answerSelected: formData[`rating${q}`] ?? '4' }));
		const answers = answerMatrix2.slice().sort(function (a, b) {
			return a.answerSelected - b.answerSelected;
		}).filter(e => e.answerSelected !== "3");
		if (answers.length === 0) {
			NotificationManager.error("Try thinking more deeply about these statements.");
			return;
		}
		if (ans === null) {
			const seven_answers = answers.slice(0, 7);
			const ans = Array.from(new Set(seven_answers)).sort(
				(a, b) => b.answerSelected - a.answerSelected)[0].answerSelected;
			//find the count of the top answer.
			const count = answers.filter(e => e.answerSelected === ans).length;
			const ind = seven_answers.findIndex(e => e.answerSelected === ans);
			const questionLookup = qualityStatements.reduce((prev, curr, idx) => ({ [curr.id]: idx, ...prev }), {});
			if (count > 7 - ind) {
				setAns(ans);
				setSevenAnswers(seven_answers);
				setElements(answers.filter(e => e.answerSelected === ans).map(
					x => qualityStatements[questionLookup[x.questionId]]));
				await client.post("quality_statement_answers/update_answers", answerMatrix2);
				load();
				setReorderMode(true);
				window.scrollTo(0, 0);

			}
			else {
				const questionIds = seven_answers.map(a => a.questionId);
				await client.post("quality_statement_answers/update_answers", answerMatrix2);
				navigate('/aim_statements', { state: { questionIds: questionIds } });
			}
			return;
		}
		else {
			const rest = sevenAnswers.filter(e => e.answerSelected !== ans).map(a => a.questionId);
			const questionIds = [...rest, ...elements.slice(0, 7 - rest.length).map(x => x.id)];
			await client.post("quality_statement_answers/update_answers", answerMatrix2);
			navigate('/aim_statements', { state: { questionIds: questionIds } });
		}
	}

	async function removePreviousAnswers() {
		client.post(`quality_statement_answers/reset_answers`).then(e => {
			NotificationManager.success("Your quiz answers have been deleted successfully.");
			window.location.reload();
		});
	}

	function sendToTop(index) {
		setElements(els => {
			return [els[index], ...els.slice(0, index), ...els.slice(index + 1)];
		});
	}
	function sendToBottom(index) {
		setElements(els => {
			return [...els.slice(0, index), ...els.slice(index + 1), els[index]];
		});
	}
	const methods = useForm({
		defaultValues: {},
		mode: 'all',
	});

	const { handleSubmit, setValue, register, watch } = methods;

	// useEffect(() => {
	// 	client.get("qualitystatement").then(e => {
	// 		setQualityStatements(e['data']);
	// 	}).catch(exc => {
	// 		console.warn(exc);
	// 		NotificationManager.error("Could not retrieve quality statements");
	// 	})
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])

	useEffect(() => {
		return () => {
			let formData = watch();
			setIsBlocking(0);
			const qids = Object.keys(formData).map(e => e.slice(6));
			const answerMatrix2 = qids.map(q => (
				{ questionId: q, answerSelected: formData[`rating${q}`] ?? '4' }));
			client.post("quality_statement_answers/update_answers", answerMatrix2);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Page background="journey_four">

			<Breadcrumb>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_start", style: { color: "darkblue" } }}>
					Think
				</Breadcrumb.Item>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_self_assessment" }}>
					Self Assessment
				</Breadcrumb.Item>
			</Breadcrumb>
			<Header className="mb-2" displayIcon={"false"} setReorderMode={setReorderMode} reorderMode={reorderMode} setAns={setAns} title={pageHead}></Header>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(submitAnswers)} className="rating-wrapper" noValidate>
					<Row className='align-items-center h-100 mt-2'>
						<Col className='i-know-card text-center'>

							{!reorderMode ? (
								<>
									<Col xs={'8'} id="footer-area" className="bg-white rounded border border-dark pb-3 m-auto" style={{ fontSize: "14px" }}>

										<br /><h6><b>Instructions</b></h6>

										Allow some time for this exercise – think through each statement in depth<br />

										Add an answer for each statement:<br /><br />
										<ul>

											<li>
												a. Think of your own view of you, rather than what you think other people see in you
											</li>
											<li>
												b. Think of yourself as you are now, rather than how you were in the past
											</li>
											<li>
												c. If an answer applies in only one situation (for example at work), or with only one person, answer that question with that situation in mind
											</li>
										</ul>
										<br />
									</Col>
								</>
							) :
								<>
									<Col xs={'8'} id="footer-area" className="bg-white rounded border border-dark pb-3 m-auto" style={{ fontSize: "14px" }}>

										<br /><h6><b>Instructions</b></h6>

										Drag the {7 - sevenAnswers.filter(e => e.answerSelected !== ans).length}{" "} statement(s) that describe(s) you most to the top of the list

									</Col>
								</>
							}

							{reorderMode && (
								<div className="mx-auto pt-5 px-3 lg:max-w-2xl md:max-w-xl">
									<ReOrderableList
										name="reordable-statements"
										list={elements}
										onListUpdate={(n) => setElements(n)}
									>
										{elements.map((statement, idx) => (
											<ReOrderableItem key={`{idx}-item-${idx}`} style={{ background: "#007bff", border: "0px", color: "blue", height: "40px" }}>
												<>
													<div className="text-dprimary reorder clicky my-3 text-center">
														<Button className="p-1 quiz-btn" style={{ backgroundColor: "#F5D1C1", color: 'black', border: '0px', borderRadius: '0px' }} onClick={() => sendToBottom(idx)}>
															<FontAwesomeIcon size="lg" icon={faAngleDoubleDown} className="mx-auto" />
														</Button>
														<div className="py-2">{statement?.statement}</div>
														<Button className="p-1 quiz-btn" style={{ backgroundColor: "#F5D1C1", color: 'black', border: '0px', borderRadius: '0px' }} onClick={() => sendToTop(idx)}>
															<FontAwesomeIcon size="lg" icon={faAngleDoubleUp} className="mx-auto" />
														</Button>
													</div>
												</>
											</ReOrderableItem>
										))}
									</ReOrderableList>
								</div>
							)}

							<div className="justify-center pt-5">

								{!reorderMode && (
									(qualityStatements.length > 0 ? qualityStatements.map((qualityStatement, index) => (
										<div key={index}>
											<p className="page-header"><b>{qualityStatement.statement}</b></p>
											<div id="form-wrapper">
												<div className="rating-wrapper rating-form">
													<div className="rating-slider-agreement">


														<input type="radio" name={"rating" + qualityStatement.id} id={"rating" + qualityStatement.id + 3} value={"3"} defaultChecked={1 === qualityStatementAnswers[qualityStatement?.id] ?? null}
															{...register("rating" + qualityStatement.id)} required />
														<label htmlFor={`rating${qualityStatement.id}3`} data-rating="I don't agree" />

														<input type="radio" name={"rating" + qualityStatement.id} id={"rating" + qualityStatement.id + 2} value={"2"} defaultChecked={"2" === qualityStatementAnswers[qualityStatement?.id]}
															{...register("rating" + qualityStatement.id)} required />
														<label htmlFor={`rating${qualityStatement.id}2`} data-rating="I partly agree" />

														<input type="radio" name={"rating" + qualityStatement.id} id={"rating" + qualityStatement.id + 1} value={"1"} defaultChecked={1 === qualityStatementAnswers[qualityStatement?.id] ?? null}
															{...register("rating" + qualityStatement.id)} required />
														<label htmlFor={`rating${qualityStatement.id}1`} data-rating="I strongly agree" />

														<input type="radio" name={"rating" + qualityStatement.id} id={"rating" + qualityStatement.id + 4} value={4} defaultChecked={4 === qualityStatementAnswers[qualityStatement?.id] ?? null}
															{...register("rating" + qualityStatement.id)} required />
														<label htmlFor={`rating${qualityStatement.id}4`} data-rating="I'm not sure" />

														<div id="pos"></div>
													</div>
												</div>
											</div>
										</div>
									)) : <LoadingSpinner />)
								)
								}
							</div>

							<div className="journey_four" style={{ position: 'sticky', bottom: 0, zIndex: 1000, opacity: 1 }}>
								<Row className="justify-content-center h-100 m-auto" style={{ flexWrap: 'nowrap' }}>
									{!reorderMode && (
										<>
											<Button className="main-btn question-grid-items" style={{ backgroundColor: "white", color: 'black' }} onClick={() => {
												if (window.confirm('Are you sure you want to remove your previous answers?')) { removePreviousAnswers() }
											}
											}
											>
												Clear Form
											</Button>
										</>
									)}
									<SubmitButton className="main-btn question-grid-items">Continue</SubmitButton>
								</Row>
							</div>

						</Col>
					</Row>
				</form>
			</FormProvider>
		</Page>
	);
};

export default DecisionTreeQuiz;
