import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentMethod } from "./PaymentMethod";
import { loadStripe } from "@stripe/stripe-js";
import { Page } from "../../../components/common";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe('pk_live_51Ky1G8FTiZxFInS1zGpk67eh3qu2BuL29Nz7eReo6UjmK1c7sD7GgOwhm41olyd5uFeJKvYjPNKo2g2ZyxhgQRq400sTZWtSjp');
export const StripeElement = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-screen-md mx-auto">
      <Page title={t("Create Subscription")} translate={false}>
        <Elements stripe={stripePromise}>
          <PaymentMethod />
        </Elements>
      </Page>
    </div>
  );
};
