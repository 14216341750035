import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import { Page, Footer, Header } from "components/common";

export const Home = () => {
  const flavourText = "This tool will help you to decide on your aims for your therapy and then give you lots of questions to think about, the kind your counsellor might ask you."

  return (
    <Page background="admin-pages" footer={<Footer textToDisplay={flavourText} displayButtons={"true"}></Footer>}>
      <Header displayIcon="false" displayButtons="false"></Header>
      <Row>
        <Col className=''>
          <Col className="m-auto" >

            {/* <Col xs={'12'} md={'8'} lg={'6'} className="justify-content-md-center m-auto" >
                    <Media object src={'/icons/main-logo.png'}/>
                  </Col> */}

            {/* <Col xs={'12'} md={'8'} lg={'6'} className="justify-content-center m-auto" >
                    <Link to="/decision_tree_start">
                      <div className="main-btn" style={{background: "#007bff", color: "white"}}>Start</div>
                    </Link>
                  </Col> */}

            <Col xs={'12'} md={'8'} lg={'6'} className="justify-content-center m-auto" >
              <Link className="btn-primary main-btn" to="/how_it_works">
                Quick Start Guide
              </Link>
            </Col>

            <Col xs={'12'} md={'8'} lg={'6'} className="justify-content-center m-auto" >
              {/* <Link className="main-btn" to="/help">
                User Guide
              </Link> */}
              <a className="main-btn" target="_blank" href="https://www.self-therapist.com/help">User Guide</a>

            </Col>

            <Col xs={'12'} md={'8'} lg={'6'} className="justify-content-center m-auto" >
              <Link className="main-btn" to="/the_research">
                The academic research behind the tool
              </Link>
            </Col>

          </Col>

          <Row className="">
            <Col xs={'6'} md={'4'} lg={'3'}>
              <a
                className="btn btn-primary my-3 py-2 px-4 border-0 text-white text-sm h-10"
                href="https://forms.gle/roDiCP3BepNaCDcn7" target="_blank" rel="noopener noreferrer">
                User's Feedback
              </a>
            </Col>
            <Col xs={'0'} md={'4'} lg={'3'} className="justify-content-md-center m-auto" >
            </Col>
            <Col xs="auto" md="auto" lg="auto">
              <a
                className="btn btn-primary my-3 py-2 px-4 border-0 text-white text-sm h-10"
                href="https://forms.gle/EMfQHR9KYyfg8XgS8" target="_blank" rel="noopener noreferrer">
                Helper's Feedback
              </a>
            </Col>
          </Row>

          {/* <Row>
              <Col>
                <p className='start-button text-center'>
                  <Link to="/decision_tree_start" className="btn btn-primary">Start</Link>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className='what-do-i-do text-center'>
                  <a href='/what_do_i_do'><u>What do I do?</u></a>
                </p>          <Footer textToDisplay={flavourText} displayButtons={"false"}></Footer>
                  <a href='/how_it_works'><u>How it works</u></a>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className='the-research text-center'>
                  <a href='/the_research'><u>The research</u></a>
                </p>
              </Col>
            </Row> */}
          {/* <Row>
              <Col>
                <p className='already-using text-center'>
                  <b>Already using the app? Jump straight to your:</b>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className='thinking-place text-center'>
                  <Link to="/thinking_place" className="btn btn-primary">Thinking Place</Link>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className='save-your-progress text-center'>
                  <a href='/save_progress'><u>Save your progress with an account</u></a>
                  <br />
                </p>
              </Col>
            </Row> */}
        </Col>
      </Row>
    </Page>
  );
}

export default Home;
