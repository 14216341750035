import React from "react";
import Nav from "./Nav";
import ConsentCookie from "./components/common/ConsentCookie";

export const AuthenticatedBase = ({ children }) => {
  const cookieConsented = localStorage.getItem("acceptedAllCookies");
  return (
    <>
      <Nav children={children} />
      {!cookieConsented &&
        <ConsentCookie />
      }
    </>
  );
};

export default AuthenticatedBase;
