import React, { useEffect, useState } from "react";
import { Page, Header } from "components/common";
import { useClient } from "../../hooks";
import { NotificationManager } from 'react-notifications';
import { useParams } from "react-router-dom";

export const PopupPage = () => {

	const client = useClient();
	const params = useParams();
	const { id } = params;
	const [popup, setPopup] = useState();

	useEffect(() => {
		client.get(`popup/${id}`).then((resp) => {
			setPopup(resp.data);
			// eslint-disable-next-line
			const timer = setTimeout(() => {
				var i;
				var coll = document.getElementsByTagName("h1");
				function nextNode(content) {
					if (content.localName === "h1") {
						return;
					}
					var wasHidden = content.hidden;
					content.hidden = !wasHidden;
					var next = content.nextElementSibling;
					if (next && next !== null) {
						nextNode(next);
					}
				}
				function addh1css(h1) {
					h1.classList.add("clicky");
					h1.classList.add("guides_header");
					var content = h1.nextElementSibling;
					if (content && content !== null) {
						nextNode(content);
					}
				}
				for (i = 0; i < coll.length; i++) {
					coll[i].addEventListener("click", function () {
						this.classList.toggle("active");
						var content = this.nextElementSibling;
						if (content && content !== null) {
							nextNode(content);
						}
					});

					addh1css(coll[i]);

				}
			}, 400);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve the therapy guide. Please try again.");
		})
		// eslint-disable-next-line
	}, []);

	return (
		<Page background="questions">
			<Header displayIcon="false" displayButtons="true" title={popup?.name}></Header>
			<div dangerouslySetInnerHTML={{ __html: popup?.html }} />
		</Page>
	);
}

export default PopupPage;
