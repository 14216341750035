import { actions } from "react-table";
import { useCallback } from "react";

actions.setTotal = "setTotal";

export const usePageCountFromTotal = (hooks) => {
  hooks.useInstance.push(useInstance);
  hooks.stateReducers.push(reducer);
};

usePageCountFromTotal.pluginName = "usePageCountFromTotal";

function useInstance(instance) {
  const { dispatch, state } = instance;
  const { total, pageSize } = state;

  const setTotal = useCallback(
    (total) => {
      dispatch({ type: actions.setTotal, total });
    },
    [dispatch]
  );

  const pageCount = Math.ceil(total / pageSize);

  Object.assign(instance, {
    setTotal,
    pageCount,
  });
}

function reducer(state, action, prevState, instance) {
  if (action.type === actions.init) {
    return {
      total: instance.initialState.total ?? 0,
      pageSize: instance.initialState.pageSize ?? 10,
      ...state,
    };
  }

  if (action.type === actions.setTotal) {
    return {
      ...state,
      total: action.total,
    };
  }

  return state;
}
