import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.min.css";
import 'suneditor/dist/css/suneditor.min.css';
import "./scss/index.scss";
import App from "./App";
import Promise from "bluebird";
import "./registerUnhandledRejections";

import "./config/i18n";

global.Promise = Promise;
Promise.config({
  warnings: false,
  longStackTraces: false,
  cancellation: false,
  monitoring: false,
  asyncHooks: false,
});

ReactDOM.render(<App />, document.getElementById("root"));
