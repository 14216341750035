import React from 'react';
import { NotificationManager } from "react-notifications";
import { FormProvider, useForm } from "react-hook-form";
import {
  Input,
  FormGroup,
} from "../../components/react-hook-form";
import { useClient, useUserContext } from 'hooks';
import { Alert, InputGroup } from 'reactstrap';

export const PendingUserList = ({ organisation }) => {
  return (
    <Alert color="info">
      <h6>Pending Users</h6>

      {!!organisation && organisation.map((user, index) => (
        <p>{user}</p>
      ))}
    </Alert>

  );
};

export default PendingUserList;
