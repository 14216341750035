import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { LoadingSpinner, DecisionButton, StepProgressBar, Page, Header, Breadcrumbs, cipher } from "components/common";

import { useClient } from 'hooks';
import { Row, Col } from 'reactstrap';

export const ChooseQuality = () => {
	const client = useClient();
	const params = useParams();
	const [qualities, setQualities] = useState([]);
	const { state } = useLocation();
	const origin = state?.from
	const pageHead = state?.from === "flowTwo" ? "I would like to describe myself this way" : "I could aim to describe myself this way";
	const pageColour = state?.from === "flowTwo" ? "journey_two" : "journey_three_quality";

	useEffect(() => {

		client.get(`quality?category=${cipher.deobfuscate(params['specific_category_id'])}`).then(e => {
			setQualities(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve qualities. Please try again.");
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Page background={pageColour}>
			<Breadcrumbs parent={origin === 'flowTwo' ? 'Decide Category' : 'Browse Categories'} current={origin === 'flowTwo' ? 'Decide Quality' : 'Browse Qualities'} />
			<StepProgressBar percent={75} />
			<Header className="mb-2" displayIcon={"false"} title={pageHead}></Header>
			<Row className='align-items-center h-100 mt-2'>
				<Col className='i-know-card text-center'>
					<Col sm={'12'} md={'8'} xl={'6'} className="justify-content-md-center w-100 m-auto" >
						{!!qualities ? qualities.map((quality, index) => (
							<DecisionButton
								key={index}
								keyName={index}
								link={`/choose_element/${cipher.obfuscate(quality.id)}`}
								name={quality.name}
								description={quality.description}
								origin={origin}
							// furtherClass={"aimbot"}
							/>
						)) : <LoadingSpinner />}
					</Col>
				</Col>
			</Row>
		</Page>
	);
};

export default ChooseQuality;
