import React, { useEffect, useState } from 'react';
import { NotificationManager } from "react-notifications";
import { Page, LoadingSpinner, Header } from "../../components/common";
import { useUserContext, useClient } from 'hooks';
import { Row, Col } from 'reactstrap';
import { FormProvider, useForm } from "react-hook-form";

import BarChart from '../../components/common/BarChart';
import {
  FormGroup,
  Select,
} from "../../components/react-hook-form";
export const AimReports = ({
  url,
  justGraph = false,
  height = 400,
  width = 4 * 150 + 200,
}) => {
  const client = useClient();
  const { user } = useUserContext();
  const [sendUrl, setSendUrl] = useState(url);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [studies, setStudies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("Personalised Aim Improvements")
  const methods = useForm({
    mode: "onBlur",
  });
  useEffect(() => {
    async function load() {
      try {
        setLoading(true);
        const _data = await client.get(sendUrl).get("data");
        console.log('data: ', _data);
        setData(_data?.data);
        setTotal(_data?.total);
        setLoading(false);

      }
      catch (e) {
        NotificationManager.error("Something went wrong when trying to calculate the progress report.");
      }
    }
    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendUrl]);
  useEffect(() => {

    if (user.is_superuser) {
      async function loadOrgs() {
        try {
          const organisations = await client.get('organisations').get("data");
          console.log('organisations: ', organisations);
          const selectOptions = organisations.map(o => ({ value: o.id, label: o.name }))
          setOrganisations(selectOptions);
          setShowFilters(true);
        }
        catch (e) {
          NotificationManager.error("Something went wrong when trying to calculate the progress report.");
        }
      }
      loadOrgs();
      async function loadStudies() {
        try {
          const studys = await client.get('studys').get("data");
          const selectOptions = studys.map(o => ({ value: o.id, label: o.name }))
          setStudies(selectOptions);
          setShowFilters(true);
        }
        catch (e) {
          NotificationManager.error("Something went wrong when trying to calculate the progress report.");
        }
      }
      loadStudies();
    }




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (justGraph) {
    return (
      <BarChart
        title="Personalised Aim Improvements"
        subtitle={`Total aims analysed: ${total}`}
        data={data}
        height={height}
        width={width}
      />
    )
  }
  return (
    <Page background="admin">
      <Header className="mb-2" displayIcon={"false"} title={title}></Header>

      {!!showFilters && (

        <FormProvider {...methods}>
          <form>
            <Row>
              <Col>
                <FormGroup label={"Organisation"} name={"organisation"} required>
                  <Select
                    name="organisation"
                    rules={{ required: true }}
                    options={organisations}
                    onChange={({ label, value }) => { setSendUrl(`organisations/${value}/personalised_aims`); setTitle(`Showing Organisation: ${label}`) }}

                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup label={"Studies"} name={"studies"} required>
                  <Select
                    name="studies"
                    rules={{ required: true }}
                    options={studies}
                    onChange={({ label, value }) => { setSendUrl(`studys/${value}/personalised_aims`); setTitle(`Showing Study: ${label}`) }}

                  />
                </FormGroup>
              </Col>
            </Row>
          </form>
        </FormProvider>
      )}
      <Row form>
        <Col lg>
          {!loading ? (
            <BarChart
              title="Personalised Aim Improvements"
              subtitle={`Total aims analysed: ${total}`}
              data={data}
            />

          ) : <LoadingSpinner />}
        </Col>
      </Row>
    </Page>
  );
};

export default AimReports;
