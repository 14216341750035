import React, { useMemo } from "react";
import { ReportContainer } from ".";
import { Bar } from "react-chartjs-2";


export const BarChart = ({
  data: dataProp,
  title = <div>Personalised Aim Analysis</div>,
  subtitle="",
  height=400,
  width=4 * 150 + 200,
}) => {

  const data = useMemo(() => {
    if (!dataProp) return null;

    return {
      datasets: dataProp?.datasets,
      labels: dataProp?.labels,
    };
  }, [dataProp]);

  if (!data) return <ReportContainer title={title} loading />;

  return (
    <ReportContainer title={title} subtitle={subtitle}>
      <div className="overflow-x-auto max-w-full flex">
        <div className="mx-auto">
          <Bar
            width={width}
            height={height}
            data={data}
            options={{
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                callback: (value) => {
                                    return `${value}`;
                                },
                                suggestedMin: 0,
                            },
                        },
                    ],
                    xAxes: [{
                        // barThickness: 100,
                        categoryPercentage: 0.8,
                            barPercentage: 0.9,

              }]
              },
              responsive: false,
              legend: {
                display: true,
              },
              annotation: {
                events: ["click"],
              },
              plugins: {
                labels: true,
              },
            }}
          />
        </div>
      </div>
    </ReportContainer>
  );
};

export default BarChart;
