import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col } from 'reactstrap';
import { Page, Header, OptionsDecisionButton, DecisionButton } from 'components/common';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export const DecisionTreeOptions = () => {
	const pageHead = "Where would you like to go?";

	const therapistSpecialisms = () => { window.open('https://www.self-therapist.com/therapist-specialisms', '_blank'); };


	return (
		<Page background="admin-pages">
			<Breadcrumb>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: "/decision_tree_start", style: { color: "darkblue" } }}>
					Think
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					Choose Path
				</Breadcrumb.Item>
			</Breadcrumb>
			<Header className="mb-2" displayIcon={"false"} title={pageHead}></Header>
			<Col xs={11} className='i-know-card mx-auto'>
				{/* <h6 className="mb-3 text-center"><b>Rather than working on problems,
					this tool is designed to help you work towards being a 'fully-functioning' person. So, this part of the process
					encourages you to identify a very clear aim to describe what success at the end of therapy looks like for you.</b></h6> */}
				<Row className='align-items-center h-100 mt-2'>
					<Col sm={'6'} className="m-auto">
						<>
							<OptionsDecisionButton
								keyName={1}
								link={`/decision_tree_i_know`}
								name={"Reflective self-therapy"}
								description={"This process will guide you through the kind of questions that therapists use to encourage personal insights"}
								optionsPage={false}
								backgroundColour={"journey_one_noop"}
							/>

							<OptionsDecisionButton
								keyName={2}
								link={`/decision_tree_self_assessment`}
								name={"Self-assessment"}
								description={"This process will help you to establish the root of your issue"}
								optionsPage={false}
								backgroundColour={"journey_four"}
							/>

							<OptionsDecisionButton
								keyName={3}
								link={`/decision_tree_i_would_like`}
								name={"Self-development"}
								description={"How could you be more mentally ‘fully-functioning’?"}
								optionsPage={false}
								backgroundColour={"journey_three_noop"}
							/>

							<OptionsDecisionButton
								keyName={4}
								link={`/aims_list`}
								name={"Saved notes"}
								description={"Private and confidential notes can be saved in this notebook"}
								optionsPage={false}
								backgroundColour={"notes"}
							/>

							<OptionsDecisionButton
								keyName={5}
								link={`/guides`}
								name={"Self-therapy guides"}
								description={"These Self-therapy Guides will teach you everything you need to know about managing your mental health for life"}
								optionsPage={false}
								backgroundColour={"guides"}
							/>

							<OptionsDecisionButton
								keyName={6}
								link={`/guides/25`}
								name={"Supporting other people"}
								description={"Learn basic therapy techniques, whether you are a mental-health first-aider, or you just want to support a friend/relative"}
								optionsPage={false}
								backgroundColour={"supporting"}
							/>

							<div
								onClick={therapistSpecialisms}
							>
								<OptionsDecisionButton
									keyName={7}
									link={`/decision_tree_options`}
									name={"Therapist contributors by specialism"}
									description={"These therapists can be contacted if you need in-person therapy"}
									optionsPage={false}
									backgroundColour={"specialism"}
									/>
							</div>

							{/* Old */}
							{/* 
							<Link to='/decision_tree_i_know' className="border border-dark rounded main-btn">
								I'm clear on what I want to work towards
								<Media id="notebook_link_1" onClick={(e) => e.preventDefault()} object src={'/icons/hover.png'} width={35} height={35} />
							</Link>

							<Link to='/decision_tree_self_assessment' className="border border-dark rounded main-btn">
								I'm not very clear about what I want to work towards
								<Media id="notebook_link_2" onClick={(e) => e.preventDefault()} object src={'/icons/hover.png'} width={35} height={35} />
							</Link>

							<Link to='/decision_tree_i_would_like' className="border border-dark rounded main-btn">
								I would like to browse some aims
								<Media id="notebook_link_3" onClick={(e) => e.preventDefault()} object src={'/icons/hover.png'} width={35} height={35} />
							</Link>

							<Link to='/aims_list' className="border border-dark rounded main-btn">
								I want to continue where I left off
								<div className="m-auto">
									<Media className="h-9 w-9 mt-4" id="notebook_link_4" onClick={(e) => e.preventDefault()} object src={'/icons/hover.png'} />
									<Media className="h-20 w-20" to={'/aims_list'} object src={'/icons/browse.png'} />
								</div>
							</Link>

							<UncontrolledTooltip placement="auto" target={`notebook_link_1`} trigger={!!isMobile ? ("click") : ("click hover")}>
								Use this path if you want to work on one specific issue, for example depression or anxiety
							</UncontrolledTooltip>
							<UncontrolledTooltip placement="auto" target={`notebook_link_2`} trigger={!!isMobile ? ("click") : ("click hover")}>
								This path will help you to understand what the root of your issue might be using an assessment process
							</UncontrolledTooltip>
							<UncontrolledTooltip placement="auto" target={`notebook_link_3`} trigger={!!isMobile ? ("click") : ("click hover")}>
								If you work on each of the aims in this tool over time, you will be on a journey towards becoming psychologically 'fully-functioning'
							</UncontrolledTooltip>
							<UncontrolledTooltip placement="auto" target={`notebook_link_4`} trigger={!!isMobile ? ("click") : ("click hover")}>
								This is your personal notebook in which you can save your thoughts. Only you can see what you write. You can also download your notes if you want to. (If you want to save your place in the questions, just make a note for that question, and you can go back to that point via your notebook next time you log in.)
							</UncontrolledTooltip> */}

						</>
					</Col>
				</Row>
			</Col>
		</Page>
	);
};

export default DecisionTreeOptions;
