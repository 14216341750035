import { Page } from "../../components/common";
import { Disclaimers } from '../../components/common';
import { useTranslation, Trans } from "react-i18next";
import {
  useLocation,
  useParams,

} from "react-router";
import { useClient, useUserContext } from "../../hooks";
import {
  Alert,
  Button,
  InputGroup,
} from "reactstrap";
import { useMatch, useNavigate } from "react-router-dom";
import React, { useMemo, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  FormGroup,
  HelpText,
  Input,
  SubmitButton,
} from "../../components/react-hook-form";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SimpleRegister = ({ invitation, onCancel, url, onCreated }) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues: invitation,
  });
  const { handleSubmit, formState, setError } = methods;
  const { errors } = formState;
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState();
  const client = useClient();
  const { login } = useUserContext();
  const [greyRegister, setGreyRegister] = useState(true);

  async function onSubmit(user) {


    const { username: email, password } = user;

    let resp = null;

    try {
      resp = await client.post(`${url}/accept`, user).get("data");
    } catch (ex) {
      const code = ex?.response?.data?.code;

      if (code === "invalid_password") {
        setError("password", {
          type: "server",
        });
        return;
      }
      throw ex;
    }
    await login(email, password);

    onCreated(resp.Organisation);
  }

  const form = (
    <form className="tw-space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <FormGroup name="email" label={t("Email Address")}>
          <Input name="EmailAddress" className="form-control" />
        </FormGroup>
        <FormGroup name="ContactNumber" label={t("Contact Number")}>
          <Input name="ContactNumber" className="form-control" />
        </FormGroup>
        <FormGroup name="username" label={t("Username")}
          help={
            <HelpText >You can change this in the My Account Page when you sign in.</HelpText>
          }>
          <Input readOnly name="username" className="form-control" />
        </FormGroup>
        <FormGroup
          name="password"
          label={t("Password")}
          help={
            <HelpText error={errors.password?.type === "server"}>
              <Trans>
                Password must be at least 8 characters and be a mix of letters
                and numbers
              </Trans>
            </HelpText>
          }
        >
          <InputGroup>
            <Input
              type={showPassword ? "text" : "password"}
              rules={{ required: true }}
              defaultValue=""
              name="password"
              className="form-control"
            />
            <Button className="password-btn " onClick={() => setShowPassword((v) => !v)}>
              <FontAwesomeIcon icon={!!showPassword ? faEye : faEyeSlash} className="mx-auto" />
            </Button>
          </InputGroup>
        </FormGroup>
        {/* <Row form>
          <Col sm>
            <FormGroup name="FirstName" label={t("First Name")}>
              <Input
                rules={{ required: false }}
                name="FirstName"
                defaultValue=""
                className="form-control"
                defaultValue=""
              />
            </FormGroup>
          </Col>
          <Col sm>
            <FormGroup name="LastName" label={t("Last Name")}>
              <Input
                rules={{ required: false }}
                name="LastName"
                defaultValue=""
                className="form-control"
                defaultValue=""
              />
            </FormGroup>
          </Col>
        </Row> */}
      </div>
      <Disclaimers accountType={invitation?.is_study ? 'study_corporate': 'corporate'} setGreyRegister={setGreyRegister} />
      <div className="tw-flex tw-justify-end">
        <Button onClick={onCancel} color="link" className="mr-2">
          Cancel
        </Button>
        <SubmitButton onClick={handleSubmit(onSubmit)} disabled={greyRegister}>
          <Trans>Create</Trans>
        </SubmitButton>
      </div>
    </form>
  );

  return <FormProvider {...methods}>{form}</FormProvider>;
};

export const Invitation = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const url = `invites/${token}`;
  const client = useClient();
  const { user, refreshUser } = useUserContext();
  const location = useLocation();
  const { pathname } = location;
  const match = useMatch(pathname)
  // const { checked } = qs.parse(search);
  const [registering, setRegistering] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [error, setError] = useState();

  // const { data, error } = useQuery(url, () => client.get(url).get("data"), {
  //   useErrorBoundary: false,
  //   silent: true,
  // });
  useEffect(() => {
    client.get(url).then(e => {
      setData(e['data']);
    }).catch(exc => {
      console.warn(exc);
      setError(exc);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const base = (children) => (
    <Page title={t("Accept invitation")} translate={false} background='admin-pages'>
      {children}
    </Page>
  );

  async function onAccepted(organisation) {
    refreshUser();
    navigate("/onboarding_instructions");
  }

  async function onAccept() {
    const { Organisation } = await client.post(`${url}/accept`).get("data");
    await onAccepted(Organisation);
  }

  const accept = useMemo(() => {
    if (!data) return null;

    if (!data.ExistingAccount) {
      return (
        <Button onClick={() => setRegistering(true)} color="primary">
          <Trans>Accept</Trans>
        </Button>
      );
    } else {
      return (
        <Button onClick={onAccept} color="primary">
          <Trans>Accept</Trans>
        </Button>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, data, match]);

  if (error) {
    const status = error?.response?.status;
    const code = error?.response?.data?.code;

    if (code === "expired_invitation") {
      return base(
        <Alert color="warning">
          <Trans>
            This invitation has expired, please contact the organisation
            subscriber.
          </Trans>
        </Alert>
      );
    } else if (code === "already_used") {
      return base(
        <Alert color="warning">
          <Trans>This invitation has has already been processed.</Trans>
        </Alert>
      );
    } else if (status === 404) {
      return base(
        <Alert color="danger">
          <Trans>
            This invitation has already been used.  Please contact your administrator for a new invitation.
          </Trans>
        </Alert>
      );
    } else {
      throw error;
    }
  }

  if (!data) return null;


  return base(
    <div>
      <Trans>
        {/* Congratulations, you've been invited to use the Self-Therapist tool. Please click accept to join the <strong>{data.Organisation__Name }</strong> organisation. */}
        Congratulations, you've been given access to the Self-therapist tool.  Please accept and create an account.
      </Trans>
      <div>
        <hr />
        {!!registering ? (
          <SimpleRegister
            onCreated={onAccepted}
            url={url}
            invitation={data}
            onCancel={() => setRegistering(false)}
          />
        ) : (
          accept
        )}
      </div>
    </div>
  );
};
