import React, { useState, useMemo } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Page, Header } from 'components/common';
import { DateCell } from 'components/common/Tables';
import { useClient, useModal } from 'hooks';
import { useNavigate, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import {
  Pagination,
  Table,
  Search,
  useListQuery,
  useListTable,
  TableContextProvider,
} from "components/common/Tables";
import AddButton from 'components/common/AddButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { EmailModal } from '../SuperAdmin'

export const List = ({ type, name }) => {
  const client = useClient();
  const url = type;
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { Modal, toggle } = useModal();
  const [modalId, setModalId] = useState();

  const deleteAim = (id) => {
    client
      .delete(`${type}/${id}`)
      .then((responseData) => {
        NotificationManager.success(`This ${type} has been successfully removed`);
        refetch();
      })
      .catch((ex) => {
        console.error(ex);
        NotificationManager.error(`An error occurred deleting this ${type}, please try again.`);
      });
  };
  const openStudyModal = (id) => {
    setModalId(id);
    toggle();
  };
  const EditColumn = () => {
    let col = {
      Header: "",
      accessor: "id",
      maxWidth: "25% !important",
      minWidth: "25% !important",
      width: "0 !important",
      float: "right !important",
      disableSortBy: true,
      Cell: (cellProps) => {
        let id = cellProps.row.values.id ?? null;
        if (!id) {
          return null;
        }
        return (
          <>
            <Button onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (window.confirm(`Are you sure you want to delete this ${type}?`)) { deleteAim(id) }
            }} className="h-10 mx-2" color="danger" style={{ float: 'right' }}>
              <FontAwesomeIcon icon={faTrash} className="mx-auto" fixedWidth />
            </Button>
            <Button className="h-10" color="primary" style={{ float: 'right' }}>
              <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
              Edit
            </Button>
          </>
        );
      }
    }
    return col;
  }
  const basicColumn = (name) => {
    let col = {
      Header: name,
      accessor: name.toLowerCase(),
    };
    if (name === "Name") {
      col.maxWidth = "80% !important";
      col.minWidth = "80% !important";
      col.width = '0 !important';
    }
    col.maxWidth = "80% !important";
    col.minWidth = "80% !important";
    col.width = '0 !important';
    return col;
  }
  const qualityColumns = [
    basicColumn('Key'),
    basicColumn('Name'),
    EditColumn()
  ];
  const elementColumns = [
    basicColumn('Key'),
    basicColumn('Name'),
    EditColumn()
  ];
  const qualityStatementColumns = [
    basicColumn('Key'),
    basicColumn('Statement'),
    EditColumn()
  ];
  const aimStatementColumns = [
    basicColumn('Key'),
    basicColumn('Statement'),
    EditColumn()
  ];
  const categoryColumns = [
    basicColumn('Name'),
    EditColumn()
  ];
  const categoryGroupColumns = [
    basicColumn('Name'),
    EditColumn()
  ];
  const assessmentColumns = [
    basicColumn('Statement'),
    EditColumn()
  ];
  const questionColumns = [
    basicColumn('Key'),
    basicColumn('Question'),
    {
      Header: 'Element',
      accessor: 'element_obj',
      Cell: (cellProps) => {
        let element = cellProps.row.values.element_obj ?? null;
        if (!element) {
          return null;
        }
        return '(' + element.key + ') ' + element.name;
      }
    },
    EditColumn()
  ];
  const popupColumns = [
    basicColumn('Name'),
    basicColumn('Keywords'),
    EditColumn()
  ];
  const issueColumns = [
    {
      Header: 'Common Issues',
      accessor: 'common_issue',
      maxWidth: "30% !important",
      minWidth: "30% !important",
      width: "0 !important",
    },
    {
      Header: 'Element',
      accessor: 'element_obj.name',
    },
    EditColumn()
  ];
  const studyColumns = [
    basicColumn('Name'),
    basicColumn('Code'),
    {
      Header: 'Expiry',
      accessor: 'expiry',
      Cell: ({ value }) => <DateCell value={value} />,
      maxWidth: "25% !important",
      minWidth: "25% !important",
      width: "0 !important",
    },
    {
      Header: 'Users',
      accessor: 'Users__count',
      maxWidth: "25% !important",
      minWidth: "25% !important",
      width: "0 !important",
      disableSortBy: true,
    },
    {
      Header: 'Email Participants',
      accessor: 'ids',
      disableSortBy: true,

      Cell: ({ value, row }) =>
        <Button onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          openStudyModal(row.original.id);
        }} className="h-10 mx-2" >
          <FontAwesomeIcon icon={faEnvelope} className="mx-auto" fixedWidth >Email Participant</FontAwesomeIcon>
        </Button>
    },
    EditColumn()
  ];
  const orgColumns = [
    basicColumn('Name'),
    {
      Header: 'Subscription',
      accessor: 'subscription_status',
      disableSortBy: true,
    },
    {
      Header: 'Users',
      accessor: 'admin_info.users_activated',
      disableSortBy: true
    },
    {
      Header: 'Users paid for',
      accessor: 'admin_info.maximum_users',
      disableSortBy: true,
      Cell: ({ value }) => <span> {value ?? 'Free'}</span>
    },
    EditColumn()
  ];
  const columns = useMemo(() =>
    ['question'].includes(type) ? questionColumns :
      ['element'].includes(type) ? elementColumns :
        ['category'].includes(type) ? categoryColumns :
          ['categorygroup'].includes(type) ? categoryGroupColumns :
            ['aimstatement'].includes(type) ? aimStatementColumns :
              ['qualitystatement'].includes(type) ? qualityStatementColumns :
                ['assessmentstatement'].includes(type) ? assessmentColumns :
                  ['popup'].includes(type) ? popupColumns :
                    ['common_issues'].includes(type) ? issueColumns :
                      ['studys'].includes(type) ? studyColumns :
                        ['organisations'].includes(type) ? orgColumns :

                          qualityColumns
    // eslint-disable-next-line
    , [type])

  const table = useListTable({
    columns: columns,
    data,
    initialState: {
      pageSize: 20,
    },
  });

  const { refetch } = useListQuery({
    setData,
    table,
    url: url,
    // query_arguments:{refetchInterval: 5000},
  });
  const getRowProps = (row) => {
    return {
      onClick: async () => {
        navigate('../' + type + '/' + row.original.id);
      },
      style: { cursor: "pointer" },
    };
  };
  return (
    <Page background="admin">
      <EmailModal toggle={toggle} Modal={Modal} id={modalId} />

      <Header className="mb-2" displayIcon={"false"} title={`${name} List`} />
      <Link className='' to={'../' + type + '/create'} state={{ from: origin }}>
      </Link>
      <TableContextProvider table={table} >
        <Row form>
          <Col className="pr-2">
            <Search placeholder="Search" />
          </Col>
          <Col xs="auto" className="pl-0">
            <AddButton color="success" className="h-10" onClick={() => navigate(`../${type}/create`)}>
              New {name}
            </AddButton>
          </Col>
        </Row>
        <Table size="sm" striped getRowProps={getRowProps} />
        <Pagination />
      </TableContextProvider>
    </Page>

  )
}
export default List;
