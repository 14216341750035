import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useClient } from "../../hooks";
import { Page, Header } from 'components/common';
import { NotificationManager } from 'react-notifications';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import {
  Input,
  FormGroup,
  SubmitButton,
} from "../../components/react-hook-form";
import Button from "components/common/Button";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SunEditor from "suneditor-react";
import { Media } from 'reactstrap';


export const EditPopup = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;
  const isNew = !id || id === 'create';
  const methods = useForm({
    mode: "all",
  });
  const { setValue, handleSubmit } = methods;

  const client = useClient();
  const [data, setData] = useState();
  const [activeTab, setActiveTab] = useState('create');

  //For when code view is open, changes aren't registered!!.
  const [codeViewOpen, setCodeViewOpen] = useState(false);


  useEffect(() => {
    if (!isNew) {
      client.get(`popup/${id}`).then(({ data }) => {
        setData(data);
        setValue("content", data?.html);
      }).catch(exc => {
        console.warn(exc);
        NotificationManager.error("Could not retrieve your popup");
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('keywords', data.keywords);
    }

  }, [data, setValue]);

  async function onSubmit(x) {
    if (codeViewOpen) {
      alert("Please close the code view before saving, otherwise changes might be lost.");
      return;
    }
    x.html = x.content;
    if (isNew) {
      client
        .post(`popup`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    } else {
      client
        .put(`popup/${id}`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    }
  }
  function toggleCodeView(isCodeView) {
    setCodeViewOpen(isCodeView);
  }
  function loadFileAsText(fileToLoad) {

    var fileReader = new FileReader();
    fileReader.onload = function (fileLoadedEvent) {
      var textFromFileLoaded = fileLoadedEvent.target.result;
      var { html, ...rest } = data;
      setData({ ...rest, html: textFromFileLoaded });
      setActiveTab("create")
      // document.getElementById("inputTextToSave").value = textFromFileLoaded;
    };

    fileReader.readAsText(fileToLoad, "ISO_8859-1");
  }

  return (
    <Page background="admin">
      {!!isNew ? (
        <Header className="mb-2" displayIcon={"false"} title={`New Therapy Guide`} />
      ) : (
        <Header className="mb-2" displayIcon={"false"} title={`Edit Therapy Guide`} />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label={"Name"} name={"Name"}>
            <Input
              type='textarea'
              className='form-control'
              name={'name'}
              placeholder='Name'
              rules={{
                required: false,
                maxLength: 255
              }}
            />
          </FormGroup>
          <FormGroup label={"Keywords - split multiple keywords with commas"} name={"keywords"}>
            <Input
              type='textarea'
              className='form-control'
              name={'keywords'}
              placeholder='Keywords'
              rules={{
                required: false,
                maxLength: 255
              }}
            />
          </FormGroup>

          <Nav tabs className="nav">
            <NavItem key={1}>
              <NavLink role='button' active={activeTab === 'create'}
                onClick={() => setActiveTab("create")} className="nav-link" to={'create'}>
                Create
              </NavLink>
            </NavItem>
            <NavItem key={2}>
              <NavLink role='button' active={activeTab === 'upload'}
                onClick={() => setActiveTab("upload")} className="nav-link" to={'upload'}>
                Upload
              </NavLink>
            </NavItem>
          </Nav>
          {activeTab === "create" &&
            <>
              <div className="App">
                <header className="App-header">
                  <FormGroup label={"Page Content"} name={"html"} required>
                    <SunEditor
                      name={'html'}
                      height={300}
                      setContents={data?.html}
                      onChange={x => setValue('content', x)}
                      toggleCodeView={toggleCodeView}
                      setOptions={{
                        buttonList: [
                          ['undo', 'redo'],
                          ['font', 'fontSize', 'formatBlock'],
                          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                          ['fontColor', 'hiliteColor', 'textStyle'],
                          ['removeFormat'],
                          ['outdent', 'indent'],
                          ['align', 'horizontalRule', 'list', 'lineHeight'],
                          ['table'],
                          ['codeView', 'preview'],
                          ['-right', 'image', 'video', 'audio', 'link'],
                        ],
                      }}
                    />
                  </FormGroup>

                </header>
              </div>

              <div className="flex justify-between">
                <SubmitButton color="primary">
                  {isNew ? "Create" : "Save"}
                </SubmitButton>
                {!isNew &&
                  <Button color="secondary" onClick={() => navigate("/guides/" + id)}>
                    Go to Guide
                  </Button>
                }
                <Button color="link" onClick={() => navigate("..")}>
                  <Media className="clicky" object src={'/icons/previous.png'} width={72} height={72} style={{ height: '72px', width: '72px' }} />
                </Button>
              </div>
            </>
          }
          {activeTab === "upload" &&
            <>
              <Row>
                <Col md={6}>
                  <input
                    type='file'
                    onChange={(e) => {
                      const file = e.target.files[0]
                      loadFileAsText(file)
                    }}
                  />
                </Col>
              </Row>
            </>
          }


        </form>
      </FormProvider>
    </Page>
  );
};

export default EditPopup;
