import React, { useEffect } from 'react';
import { useNavigate } from "react-router";
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Page, Header } from 'components/common';

export const OnboardingInstructions = () => {

    const navigate = useNavigate();

    const completed_onboarding = localStorage.getItem("completed_onboarding");

    useEffect(() => {
        navigate("/decision_tree_options", { replace: true });
        if (completed_onboarding === 'true') {
            navigate("/decision_tree_options", { replace: true });
        }
        //eslint-disable-next-line
    }, [completed_onboarding])

    const moveForwards = (toolUsage) => {
        console.log(toolUsage);
        localStorage.setItem("completed_onboarding", 'true');

        if (toolUsage === "alone") {
            navigate("/decision_tree_start");
        }
        if (toolUsage === "helper") {
            navigate("/guides/20");
        }
    }

    return (
        <>
            <Page background="admin-pages">
                <Header className="mb-2" displayIcon={"false"} title={"This tool will help you to become your own counsellor if you can't or don't want to see a trained therapist"}></Header>
                <Row className="justify-content-around d-flex align-content-stretch flex-wrap">
                    <Col sm={'3'} md={'3'} xl={'3'} onClick={() => moveForwards("alone")}>
                        <div className="main-btn mx-0" >
                            I'm working on this on my own
                        </div>
                    </Col>
                    <Col sm={'3'} md={'3'} xl={'3'}>
                        <Link className="main-btn mx-0" to="/onboarding_information" id="with_someone" origin="register">
                            Someone is helping me with my therapy
                        </Link>
                    </Col>
                    <Col sm={'3'} md={'3'} xl={'3'} onClick={() => moveForwards("helper")}>
                        <div className="main-btn mx-0" >
                            I'm helping someone with their therapy
                        </div>
                    </Col>
                </Row>

                <UncontrolledTooltip placement="auto" target={'with_someone'} trigger={!!isMobile ? ("click") : ("click hover")}>
                    Like a friend; a relative; a listener; a befriender; a mental health first aider; a counsellor; a psychotherapist
                </UncontrolledTooltip>

                <h5 className="m-4"><b>How does it work?</b></h5>
                <h6 className="m-4">
                    This tool is based mostly on the Person-centred counselling approach.
                    In Person-centred counselling, the counsellor encourages you to think through your problems or issues without advising you or directing you.
                    The counsellor doesn't tell you what to do because they can't possibly know what it means to be you.
                    It uses the 'actualizing tendency', which is a natural process of healing and growth by exploring how you think and feel.
                    This tool replicates that process of self-discovery, where you are in charge of your growth and development. <br /><br />
                    Use it for 10 mins a day, or an hour a week, or to suit you. There's enough questions to keep you going for around 10 years, so you're unlikely to run out of things to think about.
                </h6>


            </Page>
        </>
    );
};

export default OnboardingInstructions;
