import { useFormState } from "react-hook-form";
import classNames from "classnames/dedupe";
import React from "react";
import { get, isString } from "lodash";
import { Trans } from "react-i18next";

function getHelp(help) {
  if (!help) return null;
  if (isString(help)) return <HelpText>{help}</HelpText>;
  return help;
}

export const HelpText = ({ className, error = false, ...props }) => {
  return (
    <p
      className={classNames(
        "form-text tw-text-sm",
        {
          "text-muted": !error,
          "text-danger": error,
        },
        className
      )}
      {...props}
    />
  );
};

export const FormGroup = ({
  name,
  label,
  check,
  className,
  labelClassName,
  children,
  required,
  htmlFor,
  help,
  control,
  optional,
  ...props
}) => {
  const formState = useFormState({ control });
  const { errors } = formState;
  const error = get(errors, name);

  if (check) {
    return (
      <div
        className={classNames("form-check", { "has-danger": error }, className)}
      >
        {children}
        {label ? (
          <label className="form-check-label" htmlFor={htmlFor}>
            {label}
          </label>
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "form-group",
        {
          "has-danger": error,
        },
        className
      )}
      {...props}
    >
      {label ? (
        <label
          htmlFor={htmlFor}
          className={classNames("form-group__label", labelClassName)}
        >
          {label}
          {required ? <span className="text-danger">*</span> : null}
          {optional ? (
            <span className="tw-text-gray-500 tw-text-sm ml-1">
              <Trans>(optional)</Trans>
            </span>
          ) : null}
        </label>
      ) : null}
      {children}
      {getHelp(help)}
    </div>
  );
};
