import React from "react";
import classNames from "classnames/dedupe";
import { useTranslation } from "react-i18next";

export const CardPage = ({
  fluid,
  title,
  actions,
  children,
  className,
  translateTitle,
  background = null,
  footer = false,
}) => {

  return (
    <div
      className={classNames(
        "mx-auto sm:px-6 lg:px-8 py-6",
        fluid ? "max-w-full" : "container",
        className
      )}
    >
      <div className={`shadow sm:rounded-lg ${background} `}>
        {title ? (
          <>
            <h3 className="mt-4 p-6" style={{ textAlign: "center" }}>{title}</h3>
            <div className="ml-4 mt-2 flex-shrink-0">{actions}</div>
          </>
        ) : null}
        <div className="px-4 py-4 sm:p-6">{children}</div>
      </div>
      {!!footer && (footer)}
    </div>
  );
};

export const FlatPage = ({ title, children, translateTitle }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <div>
          <div className="flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div>
              <h3 className="text-lg leading-6 font-normal text-gray-900">
                {translateTitle ? t(title) : title}
              </h3>
            </div>
          </div>
        </div>
        <div className="py-5">{children}</div>
      </div>
    </div>
  );
};

export const Page = ({ flat, ...props }) => {
  return flat ? <FlatPage {...props} /> : <CardPage {...props} />;
};

export default Page;
