import React, { useState } from 'react';
import { Field, useFormikContext, useField } from "formik";
import { Alert, InputGroup, Button } from "reactstrap";
import { Trans } from "react-i18next";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PasswordMatchValidator = ({
  name = "password_mismatch",
  passwordKey = "password",
  confirmPasswordKey = "confirm_password",
}) => {
  const { values } = useFormikContext();

  useField({
    name,
    validate: () => {
      return values[passwordKey] !== values[confirmPasswordKey];
    },
  });

  return null;
};

export const PasswordFields = ({ required = true, showError }) => {
  const { errors, touched, submitCount } = useFormikContext();
  const [showPassword, setShowPassword] = useState(true);
  const [showRepeatPassword, setShowRepeatPassword] = useState(true);

  return (
    <>
      <PasswordMatchValidator required={required} />
      <div className="form-group">

        <label className="input-label">
          Password
        </label>
        <InputGroup>
          <Field
            type={showPassword ? 'password' : 'string'}
            name="password"
            validate={required ? (v) => !v : undefined}
            className="form-control"
          />
          <Button className="password-btn " onClick={() => setShowPassword((v) => !v)}>
            {!!showPassword ? (
              <FontAwesomeIcon icon={faEye} className="mx-auto" />
            ) :
              <FontAwesomeIcon icon={faEyeSlash} className="mx-auto" />
            }
          </Button>
        </InputGroup>
      </div>

      <div className="form-group">
        <label className="input-label">
          Repeat Password
        </label>
        <InputGroup>
          <Field
            type={showRepeatPassword ? 'password' : 'string'}
            name="confirm_password"
            validate={required ? (v) => !v : undefined}
            className="form-control"
          />
          <Button className="password-btn " onClick={() => setShowRepeatPassword((v) => !v)}>
            {!!showRepeatPassword ? (
              <FontAwesomeIcon icon={faEye} className="mx-auto" />
            ) :
              <FontAwesomeIcon icon={faEyeSlash} className="mx-auto" />
            }
          </Button>
        </InputGroup>
      </div>

      {errors.password_mismatch &&
        ((touched.password && touched.confirm_password) || submitCount) ? (
        <Alert color="danger">
          <Trans>Passwords do not match</Trans>
        </Alert>
      ) : null}
    </>
  );
};

export default PasswordFields;
