import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";

export const AddButton = ({
  icon = faPlusCircle,
  tag: Tag = Button,
  children,
  color = "success",
  ...props
}) => {
  return (
    <Tag {...props} color={color}>
      {icon ? <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> : null}
      {children}
    </Tag>
  );
};

export default AddButton;
