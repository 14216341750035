import React, { useState } from "react";
import { useNavigate } from 'react-router';
import { NotificationManager } from "react-notifications";
import { useClient } from '../../hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { SubmitButton } from '../../components/react-hook-form';
import { Row, Col, Button } from 'reactstrap';
import { Page, Footer } from 'components/common';
import { CreateAccount } from './CreateAccount'

// CSS
import 'react-slideshow-image/dist/styles.css';
import 'assets/css/style.css';

export const Signup = ({ showStudyCode = false, accountType = 'a Personal' }) => {
	const methods = useForm({
		defaultValues: {
			// connected_organisation: selectedOrganisation,
		},
		mode: 'all',
	});
	const {
		handleSubmit,

		setError,
	} = methods;

	const flavourText = "If you have any problems signing up, please email us at hello@self-therapist.com. Passwords need to be a minimum of 8 characters and a mix of numbers and letters."
	const client = useClient();
	const navigate = useNavigate();
	const [greyRegister, setGreyRegister] = useState(true);
	const [isPending, setIsPending] = useState(false);

	const onSubmit = (x) => {
		const formData = x.User;
		if (formData.password !== formData.password_confirm) {
			setError('User.password_confirm', { type: 'Password mismatch', message: 'Passwords do not match' });
			return;
		}
		setIsPending(true);
		client
			.post('/signup', formData)
			.then((responseData) => {
				NotificationManager.success("You have successfully signed up, please log in to your account to get started.", "Welcome to Self Therapist.", 20000);
				navigate('/login');
			})
			.catch((ex) => {
				const errorMessages = ex?.response?.data?.detail
				for (const [key, value] of Object.entries(errorMessages)) {
					//eslint-disable-next-line
					if (key == 'username') {
						setError('User.email', { message: value[0] });
					} else {
						setError('User.' + key, { message: value[0] });
					}
				}
				NotificationManager.error('An error occurred with signing up, please contact +44 (0)1629 363357 if this persists.');
			}).finally(function () { setIsPending(false); });
	};

	return (
		<>
			<Page background="admin-pages" footer={<Footer textToDisplay={flavourText} displayButtons={"false"}></Footer>}>
				<Row className='align-items-center h-100'>
					<Col className='p-2 '>
						<FormProvider {...methods}>
							<form onSubmit={(e) => e.preventDefault()}>
								{!!methods && (
									<CreateAccount methods={methods} setGreyRegister={setGreyRegister} showStudyCode={showStudyCode} accountType={accountType} />
								)}
								<SubmitButton onClick={handleSubmit(onSubmit)} pending={isPending} style={{ background: "#007bff", border: "0px", color: "white", height: "40px" }} className="main-btn ml-2 mr-2 question-grid-items" disabled={greyRegister} aria-label="Press to register">Register</SubmitButton>
								<br />
								<br />

								<Row>
									<Col>
										<p className='forgot-password text-left'>
											Already have an account?
											<br />
											<Button color="link" href='login'>Sign In</Button>

										</p>
									</Col>
									<Col>
										<p className='forgot-password text-right'>
											Forgot your password?
											<br />
											<Button color="link" href='reset_password_request'>Reset Password</Button>
										</p>
									</Col>
								</Row>
							</form>
						</FormProvider>
					</Col>
				</Row>
			</Page>
		</>
	);
};

export default Signup;
