import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useClient } from "../../hooks";
import { Page, Header } from 'components/common';
import { NotificationManager } from 'react-notifications';
import { Input as ReactStrapInput } from 'reactstrap';

import {
  Input,
  FormGroup,
  Select,
  SubmitButton,
} from "../../components/react-hook-form";

export const EditElement = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;
  const isNew = !id || id === 'create';
  const methods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit, setValue } = methods;
  const client = useClient();
  const [qualities, setQualities] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    client.get(`quality`).then(({ data }) => {
      const selectOptions = data.map(o => ({ value: o.id, label: o.name }))
      setQualities(selectOptions);
    }).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Could not retrieve the qualities");
    })
    if (!isNew) {
      client.get(`element/${id}`).then(({ data }) => {
        setData(data);
      }).catch(exc => {
        console.warn(exc);
        NotificationManager.error("Could not retrieve your element");
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (data) {
      setValue('key', data.key);
      setValue('name', data.name);
      setValue('keyword', data.keyword);
      setValue('priority_keyword', data.priority_keyword);
      if (!!qualities && qualities.length > 0 && data.quality) {
        setValue('quality', { value: data.quality, label: qualities.find((x) => x.value === data.quality).label });
      }
    }
  }, [data, qualities, setValue]);


  async function onSubmit(x) {
    if (typeof x.quality === "object") {
      x.quality = x.quality.value;
    }
    if (isNew) {
      client
        .post(`element`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    } else {
      client
        .put(`element/${id}`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    }

  }

  return (
    <Page background="admin">
      {!!isNew ? (
        <Header className="mb-2" displayIcon={"false"} title={`New Element`} />
      ) : (
        <Header className="mb-2" displayIcon={"false"} title={`Edit Element`} />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label={"Key"} name={"key"} required>
            <Input
              type='text'
              className='form-control'
              name={'key'}
              placeholder='Key'
              rules={{
                required: true,
                maxLength: 8
              }}
            />
          </FormGroup>
          <FormGroup label={"Name"} name={"name"}>
            <Input
              type='textarea'
              className='form-control'
              name={'name'}
              placeholder='Name'
              rules={{
                required: true,
                maxLength: 2048
              }}
            />
          </FormGroup>
          <FormGroup label={"Quality"} name={"quality"} required>
            <Select
              name="quality"
              rules={{ required: true }}
              options={qualities}
            />
          </FormGroup>
          <FormGroup label={"Keywords"} name={"keyword"}>
            <Input
              component={ReactStrapInput}
              type='textarea'
              className='form-control'
              name={'keyword'}
              rows={5}
              placeholder='Keywords'
              rules={{
                required: false,
                maxLength: 2048
              }}
            />
          </FormGroup>
          <FormGroup label={"Priority Keywords"} name={"priority_keyword"}>
            <Input
              component={ReactStrapInput}
              type='textarea'
              className='form-control'
              name={'priority_keyword'}
              rows={3}
              placeholder='Priority Keywords'
              rules={{
                required: false,
                maxLength: 2048
              }}
            />
          </FormGroup>

          <div className="flex justify-between">
            <SubmitButton color="primary">
              {isNew ? "Create" : "Save"}
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};
export default EditElement;
