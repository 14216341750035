import React from 'react';

import { Page } from 'components/common';

export const Notes = () => {
	return (
		<Page>
			<h1> Notes </h1>
			<p>This is where you can add other stuff :)</p>
		</Page>
	);
};

export default Notes;
