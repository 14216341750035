import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { LoadingSpinner, DecisionButton, StepProgressBar, Page, Header, Breadcrumbs, cipher } from "components/common";

import { useClient } from 'hooks';
import { Row, Col } from 'reactstrap';


export const ChooseSpecific = () => {
	const params = useParams();
	const client = useClient();
	const [categories, setCategories] = useState([]);
	const origin = "flowTwo"
	const pageHead = "I would like to think about...";

	useEffect(() => {

		client.get(`category?group=${cipher.deobfuscate(params['broad_category_id'])}`).then(e => {
			setCategories(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve categories. Please try again.");
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Page background="journey_two">
			<Breadcrumbs parent={'Decide Group'} current={'Decide Category'} />
			<StepProgressBar percent={50} />
			<Header className="mb-2" displayIcon={"false"} title={pageHead}></Header>
			<Row className='align-items-center h-100 mt-2'>
				<Col className='i-know-card text-center'>
					<Col sm={'12'} md={'8'} xl={'6'} className="justify-content-md-center" style={{ height: '100%', width: '100%', margin: 'auto' }} >
						{!!categories ? categories.map((category, index) => (
							<DecisionButton keyName={index} link={`/choose_quality/${cipher.obfuscate(category.id)}`} name={category.name} description={category.description} origin={origin} />
						)) : <LoadingSpinner />}
					</Col>
				</Col>
			</Row>
		</Page>
	);
};

export default ChooseSpecific;
