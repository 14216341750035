import React from 'react';
import { Row, Col } from 'reactstrap';
import { Page, Header } from 'components/common';

export const Confidentiality = () => {

    return (
        <Page background="admin-pages">
            <Header className="mb-2" displayIcon={"false"} title={"Confidentiality"}></Header>
            <Row className='align-items-center h-100 mt-2'>
                <Col className='i-know-card'>
                    <h6 className="my-4">
                        Data security is extremely important to us <br /><br />
                        Your email address will be encrypted and stored securely on our servers. You can delete your account and all the information associated with it at any time. If your account remains inactive for a year, we will delete your information for you. <br /><br />
                        If you are part of a clinical study, your email address may be needed to enable us to communicate with you about the study. However, once that's over, it will be deleted (which should be within 1 year). <br /><br />
                        You are asked within the tool, to rate yourself before and after using it, so that information can be collated (anonymously) with other users, to provide data on its effectiveness. <br /><br />
                        Anything else you type into the tool will be confidential (and encrypted) so that no one can read it except you.
                    </h6>
                    <h3 className="my-4"><b>Feedback</b></h3>
                    <h6 className="my-4">
                        The tool has been developed over a number of years, and it's important to understand whether it works for you. So once you've been using it for a couple of months, please complete the feedback form - The User's feedback form is accessed via the Main Menu. Your feedback will be anonymised as soon as it is received.
                    </h6>
                </Col>
            </Row>
        </Page>
    );
};

export default Confidentiality;
