import React from 'react';
import { NotificationManager } from "react-notifications";
import { FormProvider, useForm } from "react-hook-form";
import {
  Input,
  FormGroup,
} from "../../components/react-hook-form";
import { useClient, useUserContext } from 'hooks';
import { Alert, InputGroup } from 'reactstrap';

export const AdminUserList = ({ organisation }) => {
  const client = useClient();
  const { refreshUser } = useUserContext();
  const methods = useForm({
    defaultValues: {
      'EmailAddress': '',
      'is_admin': true,
      'Organisation': organisation.id
    },
    mode: "onBlur",
  });
  const { handleSubmit, reset } = methods;

  const onEmailSubmit = (formData) => {
    client.post("invites/create_and_send_invite", formData).then((resp) => {
      NotificationManager.success("Invite sent successfully!");
      reset({
        'EmailAddress': '',
        'is_admin': true,
        'Organisation': organisation.id

      })
      refreshUser();
    }).catch(err => {
      console.error(err);
      const detail = err?.response?.data?.detail;

      if (detail) {
        NotificationManager.error(detail);
        return;
      } else {
        NotificationManager.error("Something went wrong. Please try again.");
      }
    });
  }

  return (
    <Alert color="info">
      <h5>Organisation Administrators</h5>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onEmailSubmit)}>
          <FormGroup label={"Add an administrator:"} name={"EmailAddress"} required>
            <InputGroup>
              <Input
                type='text'
                className='form-control'
                name={'EmailAddress'}
                placeholder='Email'
                rules={{
                  required: true,
                }}
              />
              <input className="btn btn-primary" type="submit" value="Submit" />

            </InputGroup>
          </FormGroup>
        </form>
      </FormProvider>
      <h6>Organisation Administrators</h6>

      {!!organisation?.admin_info?.admins && organisation.admin_info.admins.map((user, index) => (
        <p>{user}</p>
      ))}
    </Alert>

  );
};

export default AdminUserList;
