import React from 'react';
import { Row, Col } from 'reactstrap';
import { Page, Header } from 'components/common';

export const ConsentAgreement = () => {

	return (
		<Page background="help-pages">
			<Header className="mb-2" displayIcon={"false"} title={"Study Consent"}></Header>
			<Row>
				<Col xs={'12'} md={'12'} xl={'12'}>
					<h4>Overview</h4>
					<p>
						We are addressing the following question through this study:<br /><br />
						<b>Is effective self-counselling, based on a set of prescribed therapeutic questions, possible in the
							absence of a therapist?</b><br /><br />
						This study is part of a long term research project which has been conducted through Keele University
						and Sheffield Hallam University. This independent stage of the project is looking at the potential for
						you to be your own counsellor.<br /><br />
						<b>Ethical Approval and Data Protection</b><br />
						Research that involves human subjects needs ethical approval, and that has been obtained on the
						basis of the BACP Ethical Guidelines for Research in the Counselling Professions 2018. This research
						is also being undertaken in line with the GDPR 2018 which means that we keep your data private
						and safe.<br /><br />
						Warm regards,<br /><br />

						Dr Sue Renger<br /><br />
						If you need more information, do contact me on: Tel: +44 (0)1629 363357: Email: <a href="mailto:sue@suerenger.co.uk">sue@suerenger.co.uk</a>
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={'12'} md={'12'} xl={'12'} className='mt-5'>
					<h4>Background Information</h4>
					<p>
						This is an invitation for you to participate in a study about self-counselling. We are trying to find out
						whether people can counsel themselves using a bank of questions that a therapist might ask.<br /><br />
						<b>Why me?</b><br />
						You may be interested in managing your mental health alone. You may be working with a therapist/helper who is happy to trial this tool with you.
						You may be a trainee or a newly qualified therapist, or you may be studying mental health and have an interest in the tool.
						Alternatively, you may be an experienced therapist wanting to trial the tool with your clients.<br /><br />
						<b>What do I have to do?</b><br />
						<ol type="1">
							<li>
								You have been given free access to this clinical instrument for a limited amount of time.  It contains 17,000 therapy questions and some self-therapy material to test for your own self-development.
							</li>
							<li>
								After 2 months of using the tool, please complete the questionnaire accessed from the main menu.
							</li>
						</ol>
						<b>Are there any benefits to taking part?</b><br />
						You may make some significant progress with your mental health or personal development by using the tool. If you are using the tool with a therapist, you may make more progress in your therapy by doing work before, or in between sessions. If you are studying therapy or are newly qualified, you may find the questions useful in your therapy.  Alternatively, the process may just not be helpful at all. If the questions trigger any issues for you, you should have access to a therapist or helper for support.
						<br /><br />
						<b>What happens if I am unhappy with the process?</b><br />
						You can withdraw from the process anytime, by just telling your therapist/helper, or by telling us.
						You can also ask for any feedback you give to be changed or deleted. However, once you’ve filled in the questionnaire
						and we’ve analysed all the feedback, we can’t delete your responses then. In the case of any problems,
						the principal researcher will address them, or alternatively the British Association of Counselling and Psychotherapy will assist.<br /><br />
						<b>Will my details and my contributions be confidential?</b><br />
						We will keep your personal information associated with the study for as short a time as possible, which should be less than a
						year. We will also swap your name for a code as soon as we can, so that no one will know who said
						what. We will keep your data safe on password protected PC’s or secure on the host server.
						Ultimately, we may publish some of your data, or use it for further study, but it will be anonymous.<br /><br />
					</p>

					<h4>Participant Consent</h4>
					<p>I would like to participate in this study and I agree with the following statements:</p>
					<li>
						A therapist or helper is available to help me if I need support whilst using the tool.
					</li>
					<li>
						I have read the Background Information for this study and I wish to participate in the study under the conditions set out.
					</li>
					<li>
						I will complete the feedback form once I have used the tool.
					</li>
					<li>
						I consent to the information collected for the purposes of this research study, once anonymised (so that I cannot be identified), to be used for any other research/publication purposes
					</li>
					<li>
						I understand that my data will  be processed in line with the General Data Protection Regulation 2018
					</li>
				</Col>
			</Row>
			<Row className='mt-5'>
				<Col xs={'6'} md={'6'} xl={'6'}>
					<p>
						<b>Further information</b><br />Principal Researcher, Sue Renger:<br />Tel: +44 (0)1629 363357<br />Email: <a href="mailto:sue@suerenger.co.uk">sue@suerenger.co.uk</a>
					</p>
				</Col>
			</Row>
		</Page>
	);
};

export default ConsentAgreement;
