import React, { useLayoutEffect } from 'react';
import { Page, Header } from 'components/common';
import { Container } from 'reactstrap';
import "./Help.scss"
export const PrivacyPolicy = () => {
	useLayoutEffect(() => {
		window.scrollTo(0, 0)
	});
	return (
		<Page background="help-pages">
			<Header className="mb-2" displayIcon={"false"} title={"Privacy Policy"}></Header>
			{/* <Highlight> */}
			<Container>
				<>
					<p>
						This privacy policy applies between you, the User of this Website,
						and&nbsp;Self-Therapist Limited, the owner and provider of this
						Website.&nbsp;Self-Therapist Limited&nbsp;takes the privacy of your
						information very seriously. This privacy policy applies to our use of any
						and all Data collected by us or provided by you in relation to your use of
						the Website.
					</p>
					<p>
						This privacy policy should be read alongside and in addition to our Terms
						and Conditions which can be found
						at:&nbsp;<a href="https://self-therapist.com/terms_of_use">self-therapist.com/terms_of_use</a>
					</p>
					<p>&nbsp;</p>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Definitions and interpretation</strong>
						</span>
					</p>
					<ol>
						<li>
							<p>In this privacy policy, the following definitions are used:</p>
						</li>
					</ol>
					<p>&nbsp;</p>
					<table cellPadding={1} cellSpacing={0}>
						<tbody>
							<tr>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>Data</strong>
											</span>
										</span>
									</p>
								</td>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												collectively all information that you submit to&nbsp;
											</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>Self-Therapist Limited</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												&nbsp;via the Website. This definition incorporates, where
												applicable, the definitions provided in the Data Protection Laws
											</span>
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>Cookies</strong>
											</span>
										</span>
									</p>
								</td>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												a small text file placed on your computer by this Website when
												you visit certain parts of the Website and/or when you use
												certain features of the Website. Details of the cookies used by
												this Website are set out in the clause below (
											</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>Cookies</strong>
											</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>)</span>
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>Data Protection Laws</strong>
											</span>
										</span>
									</p>
								</td>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												any applicable law relating to the processing of personal Data,
												including but not limited to the&nbsp;GDPR, and any UK
												implementing and supplementary laws, regulations and secondary
												legislation
											</span>
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>GDPR</strong>
											</span>
										</span>
									</p>
								</td>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												the UK General Data Protection Regulations
											</span>
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>Self-Therapist Limited,&nbsp;</strong>
											</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>
													&nbsp;
													<br />
													&nbsp;
												</strong>
											</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>we&nbsp;</strong>
											</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>&nbsp;or&nbsp;</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>us</strong>
											</span>
										</span>
									</p>
								</td>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												Self-Therapist Limited, a company incorporated in&nbsp;England
												and Wales&nbsp;with registered number&nbsp;13778366&nbsp;whose
												registered office is at&nbsp;DIAMOND HOUSE, DIAMOND
												COURT,&nbsp;BAKEWELL,&nbsp;DERBYSHIRE,&nbsp;&nbsp;DE45 1EW;
											</span>
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>UK Cookie Law</strong>
											</span>
										</span>
									</p>
								</td>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												the Privacy and Electronic Communications (EC Directive)
												Regulations 2003 as amended by the Privacy and Electronic
												Communications (EC Directive) (Amendment) Regulations 2011 &amp;
												the Privacy and Electronic Communications (EC Directive)
												(Amendment) Regulations 2018
											</span>
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>User</strong>
											</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>&nbsp;or&nbsp;</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>you</strong>
											</span>
										</span>
									</p>
								</td>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												any third party that accesses the Website and is not either (i)
												employed by&nbsp;
											</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>Self-Therapist Limited</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												&nbsp;and acting in the course of their employment or (ii)
												engaged as a consultant or otherwise providing services to&nbsp;
											</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>Self-Therapist Limited</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												&nbsp;and accessing the Website in connection with the provision
												of such services
											</span>
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												<strong>Website</strong>
											</span>
										</span>
									</p>
								</td>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												the website that you are currently using,&nbsp;
											</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												self-therapist.com
											</span>
										</span>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "small" }}>
												, and any sub-domains of this site unless expressly excluded by
												their own terms and conditions.
											</span>
										</span>
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<p>&nbsp;</p>
					<ol start={2}>
						<li>
							<p>
								In this privacy policy, unless the context requires a different
								interpretation:
							</p>
							<ol style={{ listStyleType: "lower-alpha" }}>
								<li>
									<p>the singular includes the plural and vice versa;</p>
								</li>
								<li>
									<p>
										references to sub-clauses, clauses, schedules or appendices are to
										sub-clauses, clauses, schedules or appendices of this privacy
										policy;
									</p>
								</li>
								<li>
									<p>
										a reference to a person includes firms, companies, government
										entities, trusts and partnerships;
									</p>
								</li>
								<li>
									<p>
										reference to any statutory provision includes any modification or
										amendment of it;
									</p>
								</li>
								<li>
									<p>
										the headings and sub-headings do not form part of this privacy
										policy.
									</p>
								</li>
							</ol>
						</li>
					</ol>
					<p>
						&nbsp;
						<span style={{ fontSize: "large" }}>
							<strong>Scope of this privacy policy</strong>
						</span>
					</p>
					<ol start={3}>
						<li>
							<p>
								This privacy policy applies only to the actions of&nbsp;Self-Therapist
								Limited&nbsp;and Users with respect to this Website. It does not extend
								to any websites that can be accessed from this Website including, but
								not limited to, any links we may provide to social media websites.
							</p>
						</li>
					</ol>
					<ol start={4}>
						<li>
							<p>
								For purposes of the applicable Data Protection Laws,&nbsp;Self-Therapist
								Limited&nbsp;is the "data controller". This means
								that&nbsp;Self-Therapist Limited&nbsp;determines the purposes for which,
								and the manner in which, your Data is processed.
							</p>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Data collected</strong>
						</span>
					</p>
					<ol start={5}>
						<li>
							<p>
								We may collect the following Data, which includes personal Data, from
								you:
							</p>
							<ol style={{ listStyleType: "lower-alpha" }}>
								<li>
									<p>email address and a password</p>
								</li>
								<li>
									<p>IP address (automatically collected)</p>
								</li>
								<li>
									<p>web browser type and version (automatically collected)</p>
								</li>
								<li>
									<p>operating system (automatically collected)</p>
								</li>
								<li>
									<p>
										any text entered into the free text boxes (this will be stored
										anonymously and encrypted)
									</p>
								</li>
							</ol>
						</li>
					</ol>
					<p>in each case in accordance with this privacy policy.</p>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>How we collect Data</strong>
						</span>
					</p>
					<ol start={6}>
						<li>
							<p>We collect Data in the following ways:</p>
							<ol style={{ listStyleType: "lower-alpha" }}>
								<li>
									<p>data is given to us by you; and</p>
								</li>
								<li>
									<p>data is collected automatically.</p>
								</li>
							</ol>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Data that is given to us by you</strong>
						</span>
					</p>
					<ol start={7}>
						<li>
							<p>
								Self-Therapist Limited&nbsp;will collect your Data in a number of ways,
								for example:
							</p>
							<ol style={{ listStyleType: "lower-alpha" }}>
								<li>
									<p>
										when you contact us through the Website, by telephone, post, e-mail
										or through any other means;
									</p>
								</li>
								<li>
									<p>
										when you complete surveys that we use for research purposes
										(although you are not obliged to respond to them);
									</p>
								</li>
								<li>
									<p>when you use our services;</p>
								</li>
							</ol>
						</li>
					</ol>
					<p>in each case in accordance with this privacy policy.</p>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Data that is collected automatically</strong>
						</span>
					</p>
					<ol start={8}>
						<li>
							<p>
								To the extent that you access the Website, we will collect your Data
								automatically, for example:
							</p>
							<ol style={{ listStyleType: "lower-alpha" }}>
								<li>
									<p>
										we automatically collect some information about your visit to the
										Website. This information helps us to make improvements to Website
										content and navigation, and includes your IP address, the date,
										times and frequency with which you access the Website and the way
										you use and interact with its content;
									</p>
								</li>
								<li>
									<p>
										we will collect your Data automatically via cookies, in line with
										the cookie settings on your browser. For more information about
										cookies, and how we use them on the Website, see the section below,
										headed "Cookies".
									</p>
								</li>
							</ol>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Our use of Data</strong>
						</span>
					</p>
					<ol start={9}>
						<li>
							<p>
								Any or all of the Data may be required by us from time to time in order
								to provide you with the best possible service and experience when using
								our Website. Specifically, Data may be used by us for the following
								reasons:
							</p>
							<ol style={{ listStyleType: "lower-alpha" }}>
								<li>
									<p>internal record keeping;</p>
								</li>
								<li>
									<p>improvement of our products / services;</p>
								</li>
							</ol>
						</li>
					</ol>
					<p>in each case in accordance with this privacy policy.</p>
					<ol start={9}>
						<li>
							<p>
								We may use your Data for the above purposes if we deem it necessary to
								do so for our legitimate interests. If you are not satisfied with this,
								you have the right to object in certain circumstances (see the section
								headed "Your rights" below).
							</p>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Keeping Data secure</strong>
						</span>
					</p>
					<ol start={11}>
						<li>
							<p>
								We will use technical and organisational measures to safeguard your
								Data, for example:
							</p>
							<ol style={{ listStyleType: "lower-alpha" }}>
								<li>
									<p>
										access to your account is controlled by a password and a user name
										that is unique to you.
									</p>
								</li>
								<li>
									<p>we store your Data on secure servers.</p>
								</li>
							</ol>
						</li>
					</ol>
					<ol start={12}>
						<li>
							<p>
								Technical and organisational measures include measures to deal with any
								suspected data breach. If you suspect any misuse or loss or unauthorised
								access to your Data, please let us know immediately by contacting us via
								this e-mail address:&nbsp;<a href="mailto:info@self-therapist.com">info@self-therapist.com</a>
							</p>
						</li>
						<li>
							<p>
								If you want detailed information from Get Safe Online on how to protect
								your information and your computers and devices against fraud, identity
								theft, viruses and many other online problems, please visit
								www.getsafeonline.org. Get Safe Online is supported by HM Government and
								leading businesses.
							</p>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Data retention</strong>
						</span>
					</p>
					<ol start={14}>
						<li>
							<p>
								Unless a longer retention period is required or permitted by law, we
								will only hold your Data on our systems for the period necessary to
								fulfil the purposes outlined in this privacy policy or until you request
								that the Data be deleted.
							</p>
						</li>
						<li>
							<p>
								Even if we delete your Data, it may persist on backup or archival media
								for legal, tax or regulatory purposes.
							</p>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Your rights</strong>
						</span>
					</p>
					<ol start={16}>
						<li>
							<p>You have the following rights in relation to your Data:</p>
							<ol style={{ listStyleType: "lower-alpha" }}>
								<li>
									<p>
										<strong>Right to access</strong>&nbsp;- the right to request (i)
										copies of the information we hold about you at any time, or (ii)
										that we modify, update or delete such information. If we provide you
										with access to the information we hold about you, we will not charge
										you for this, unless your request is "manifestly unfounded or
										excessive." Where we are legally permitted to do so, we may refuse
										your request. If we refuse your request, we will tell you the
										reasons why.
									</p>
								</li>
								<li>
									<p>
										<strong>Right to correct</strong>&nbsp;- the right to have your Data
										rectified if it is inaccurate or incomplete.
									</p>
								</li>
								<li>
									<p>
										<strong>Right to erase</strong>&nbsp;- the right to request that we
										delete or remove your Data from our systems.
									</p>
								</li>
								<li>
									<p>
										<strong>Right to restrict our use of your Data</strong>&nbsp;- the
										right to "block" us from using your Data or limit the way in which
										we can use it.
									</p>
								</li>
								<li>
									<p>
										<strong>Right to data portability</strong>&nbsp;- the right to
										request that we move, copy or transfer your Data.
									</p>
								</li>
								<li>
									<p>
										<strong>Right to object</strong>&nbsp;- the right to object to our
										use of your Data including where we use it for our legitimate
										interests.
									</p>
								</li>
							</ol>
						</li>
					</ol>
					<ol start={17}>
						<li>
							<p>
								To make enquiries, exercise any of your rights set out above, or
								withdraw your consent to the processing of your Data (where consent is
								our legal basis for processing your Data), please contact us via this
								e-mail address:&nbsp;<a href="mailto:info@self-therapist.com">info@self-therapist.com</a>
							</p>
						</li>
					</ol>
					<ol start={18}>
						<li>
							<p>
								If you are not satisfied with the way a complaint you make in relation
								to your Data is handled by us, you may be able to refer your complaint
								to the relevant data protection authority. For the UK, this is the
								Information Commissioner's Office (ICO). The ICO's contact details can
								be found on their website at https://ico.org.uk/.
							</p>
						</li>
						<li>
							<p>
								It is important that the Data we hold about you is accurate and current.
								Please keep us informed if your Data changes during the period for which
								we hold it.
							</p>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Links to other websites</strong>
						</span>
					</p>
					<ol start={18}>
						<li>
							<p>
								This Website may, from time to time, provide links to other websites. We
								have no control over such websites and are not responsible for the
								content of these websites. This privacy policy does not extend to your
								use of such websites. You are advised to read the privacy policy or
								statement of other websites prior to using them.
							</p>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Changes of business ownership and control</strong>
						</span>
					</p>
					<ol start={21}>
						<li>
							<p>
								Self-Therapist Limited&nbsp;may, from time to time, expand or reduce our
								business and this may involve the sale and/or the transfer of control of
								all or part of&nbsp;Self-Therapist Limited. Data provided by Users will,
								where it is relevant to any part of our business so transferred, be
								transferred along with that part and the new owner or newly controlling
								party will, under the terms of this privacy policy, be permitted to use
								the Data for the purposes for which it was originally supplied to us.
							</p>
						</li>
					</ol>
					<ol start={22}>
						<li>
							<p>
								We may also disclose Data to a prospective purchaser of our business or
								any part of it.
							</p>
						</li>
						<li>
							<p>
								In the above instances, we will take steps with the aim of ensuring your
								privacy is protected.
							</p>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Cookies</strong>
						</span>
					</p>
					<ol start={24}>
						<li>
							<p>
								This Website may place and access certain Cookies on your
								computer.&nbsp;&nbsp;Self-Therapist Limited&nbsp;uses Cookies to improve
								your experience of using the
								Website.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Self-Therapist Limited&nbsp;has
								carefully chosen these Cookies and has taken steps to ensure that your
								privacy is protected and respected at all times.
							</p>
						</li>
					</ol>
					<ol start={25}>
						<li>
							<p>
								All Cookies used by this Website are used in accordance with current UK
								Cookie Law.
							</p>
						</li>
					</ol>
					<ol start={26}>
						<li>
							<p>
								Before the Website places Cookies on your computer, you will be
								presented with a message bar requesting your consent to set those
								Cookies. By giving your consent to the placing of Cookies, you are
								enabling&nbsp;Self-Therapist Limited&nbsp;to provide a better experience
								and service to you. You may, if you wish, deny consent to the placing of
								Cookies; however certain features of the Website may not function fully
								or as intended.
							</p>
						</li>
						<li>
							<p>This Website may place the following Cookies:</p>
						</li>
					</ol>
					<p>&nbsp;</p>
					<table cellPadding={5} cellSpacing={0}>
						<tbody>
							<tr>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "medium" }}>
												<strong>Type of Cookie</strong>
											</span>
										</span>
									</p>
								</td>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "medium" }}>
												<strong>Purpose</strong>
											</span>
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Strictly necessary Cookies</p>
								</td>
								<td>
									<p>
										These are Cookies that are required for the operation of our
										website. They include, for example, Cookies that enable you to log
										into secure areas of our website, use a shopping cart or make use of
										e-billing services.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Analytical and performance cookies</p>
								</td>
								<td>
									<p>
										They allow us to recognise and count the number of visitors and to
										see how visitors move around our website when they are using it.
										This helps us to improve the way our website works, for example, by
										ensuring that users are finding what they are looking for easily.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Functionality Cookies</p>
								</td>
								<td>
									<p>
										These are used to recognise you when you return to our website. This
										enables us to personalise our content for you and
										remember your preferences (for example, your choice of language or
										region). By using the Website, you agree to our placement of
										functionality Cookies.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<p>&nbsp;</p>
					<ol start={28}>
						<li>
							<p>You can find a list of Cookies that we use in the Cookies Schedule.</p>
						</li>
					</ol>
					<ol start={29}>
						<li>
							<p>
								You can&nbsp;choose to enable or disable Cookies in your internet
								browser. By default, most internet browsers accept Cookies but this can
								be changed. For further details, please see the help menu in your
								internet browser. You can switch off Cookies at any time, however, you
								may lose any information that enables you to access the Website more
								quickly and efficiently.
							</p>
						</li>
					</ol>
					<ol start={30}>
						<li>
							<p>
								You can choose to delete Cookies at any time; however, you may lose any
								information that enables you to access the Website more quickly and
								efficiently including, but not limited to, personalisation settings.
							</p>
						</li>
					</ol>
					<ol start={31}>
						<li>
							<p>
								It is recommended that you ensure that your internet browser is
								up-to-date and that you consult the help and guidance provided by the
								developer of your internet browser if you are unsure about adjusting
								your privacy settings.
							</p>
						</li>
						<li>
							<p>
								For more information generally on cookies, including how to disable
								them, please refer to aboutcookies.org. You will also find details on
								how to delete cookies from your computer.
							</p>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>General</strong>
						</span>
					</p>
					<ol start={33}>
						<li>
							<p>
								You may not transfer any of your rights under this privacy policy to any
								other person. We may transfer our rights under this privacy policy where
								we reasonably believe your rights will not be affected.
							</p>
						</li>
					</ol>
					<ol start={34}>
						<li>
							<p>
								If any court or competent authority finds that any provision of this
								privacy policy (or part of any provision) is invalid, illegal or
								unenforceable, that provision or part-provision will, to the extent
								required, be deemed to be deleted, and the validity and enforceability
								of the other provisions of this privacy policy will not be affected.
							</p>
						</li>
					</ol>
					<ol start={35}>
						<li>
							<p>
								Unless otherwise agreed, no delay, act or omission by a party in
								exercising any right or remedy will be deemed a waiver of that, or any
								other, right or remedy.
							</p>
						</li>
						<li>
							<p>
								This Agreement will be governed by and interpreted according to the law
								of&nbsp;England and Wales. All disputes arising under the Agreement will
								be subject to the exclusive jurisdiction of the&nbsp;English and Welsh
								courts.
							</p>
						</li>
					</ol>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Changes to this privacy policy</strong>
						</span>
					</p>
					<ol start={37}>
						<li>
							<p>
								Self-Therapist Limited&nbsp;reserves the right to change this privacy
								policy as we may deem necessary from time to time or as may be required
								by law. Any changes will be immediately posted on the Website and you
								are deemed to have accepted the terms of the privacy policy on your
								first use of the Website following the alterations.&nbsp;&nbsp;
								<br />
								&nbsp;&nbsp;&nbsp;
								<br />
								&nbsp;&nbsp;You may contact&nbsp;Self-Therapist Limited&nbsp;by email
								at&nbsp;<a href="mailto:info@self-therapist.com">info@self-therapist.com</a>
							</p>
						</li>
					</ol>
					<p>
						This privacy policy was created on&nbsp;<strong>01 February 2022</strong>.
					</p>
					<p>&nbsp;</p>
					<p>
						<span style={{ fontSize: "large" }}>
							<strong>Cookies Schedule</strong>
						</span>
					</p>
					<p>
						Below is a list of the cookies that we use. We have tried to ensure this is
						complete and up to date, but if you think that we have missed a cookie or
						there is any discrepancy, please let us know.
					</p>
					<p>
						<strong>Strictly necessary</strong>
					</p>
					<p>We use the following strictly necessary cookies:</p>
					<table cellPadding={1} cellSpacing={1}>
						<tbody>
							<tr>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "medium" }}>
												<strong>Description</strong>
											</span>
										</span>
									</p>
								</td>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "medium" }}>
												<strong>Purpose</strong>
											</span>
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Session cookie</p>
									<p>&nbsp;</p>
								</td>
								<td>
									<p>
										We use a session cookie to remember you and maintain your session
										whilst you are using the website.
									</p>
									<p>&nbsp;</p>
								</td>
							</tr>
						</tbody>
					</table>
					<p>&nbsp;</p>
					<p>
						<strong>Analytical/performance</strong>
					</p>
					<p>We use the following analytical/performance cookies:</p>
					<table cellPadding={1} cellSpacing={1}>
						<tbody>
							<tr>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "medium" }}>
												<strong>Description</strong>
											</span>
										</span>
									</p>
								</td>
								<td>
									<p>
										<span style={{ color: "#000000" }}>
											<span style={{ fontSize: "medium" }}>
												<strong>Purpose</strong>
											</span>
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Google Analytics</p>
									<p>&nbsp;</p>
								</td>
								<td>
									<p>
										We use this cookie to identify your company and analyse traffic
										patterns on the website.
									</p>
									<p>&nbsp;</p>
								</td>
							</tr>
						</tbody>
					</table>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
				</>

			</Container>


			{/* </Highlight> */}
		</Page>
	);
};

export default PrivacyPolicy;
