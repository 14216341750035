import { useQuery } from "react-query";
import { defaultQueryFn } from "../queries";
import { useMemo } from "react";
import { selectPriceOption } from "../selectors";
import { useClient } from "../../../../hooks";
import { useTranslation } from "react-i18next";

export const usePriceOptions = (url = "stripe/prices") => {
  const client = useClient();
  const { t } = useTranslation();

  const { data } = useQuery([url], defaultQueryFn(client), {
    suspense: true,
  });

  return useMemo(() => data.map(selectPriceOption(t)), [t, data]);
};
