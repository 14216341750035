import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Page, Header } from 'components/common';

export const WhatDoIDo = () => {

	return (
		<Page background="help-pages">
			<Header className="mb-2" displayIcon={"false"} title={"What do I do?"}></Header>
			<Container fluid='md' style={{ height: '100%', width: '100%', maxWidth: '1400px', minWidth: '300px' }}>
				<Row className='align-items-left' style={{ height: '100%' }}>
					<Col className='what_do_i_do_card'>
						<Row>
							<Col>
								<p className='start-button text-left'>
									<br />
									<b>1. Decide whether you want to answer the questions in your head, or with someone you trust.</b>
									<br />
									The person you choose to work with should:
									<br /><br />
									<li>Be willing to listen carefully to your answers, and show they understand</li>
									<li>Try to understand what it’s like to be you</li>
									<li>Not judge you in any way</li>
									<li>Be trustworthy enough not to share anything you say with
										anyone else</li>
								</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<p className='start-button text-left'>
									<b>2. Either:</b>
									<br />
									<li><b>A. Tell the app broadly what your aim for counselling is,</b> or</li>
									<li><b>B. Let the app help you decide</b> roughly what to work on, or</li>
									<li><b>C. Choose aims from a list</b> of statements that describe the ‘fully functioning person’ to work on</li>
									<br />
									<b>3. Decide in detail what your aim for therapy is, making it personal to you</b>
								</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<p className='start-button text-left'>
									<b>4. Choose the type of questions you prefer to answer</b> (or work through them in order):
									<br />
									<br />
									<li>Thinking ones</li>
									<li>Feeling ones</li>
									<li>Ones focused on the past</li>
									<li>Motivating ones</li>
									<li>Ones that take a different perspective on the situation</li>
								</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<p className='start-button text-left'>
									<b>5. Think through the questions:</b>
									<br />
									<br />
									<li>Choose only questions that seem helpful</li>
									<li>Ignore ones that don’t seem helpful, or ones that might trigger something that you can’t deal with</li>
									<li>If the question isn’t quite right, change it into one that is</li>
								</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<p className='start-button text-left'>
									<b>6. After you’ve thought about each question</b>, think “So what?” and relate it to your issue
									(The questions are not random, they’ve been chosen for a reason)
									<br />
								</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<p className='start-button text-left'>
									<b>7. Many of the questions are yes/no ones</b>, but think them through in lots of detail
									rather than thinking just “yes” or “no” as an answer.
									<br />
								</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<p className='start-button text-left'>
									<b>8. Allow your self-healing processes to get to work</b> as you mull over your responses to the questions.
								</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<p className='start-button text-left'>
									<b>9. Write your responses in a paper journal if you would like to.</b>
								</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<p className='start-button text-left'>
									<b>10. Discuss your responses with your therapist if you think that would be helpful.</b> Either way, let your therapist know what you thought of the tool.
								</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</Page>
	);
};

export default WhatDoIDo;
