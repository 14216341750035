import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Trans } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useTableContext } from "../hooks";

const defaultShowOptions = [10, 20];

const PaginationButton = ({ hidden, icon, children, className, ...props }) => {
  return (
    <Button
      size="sm"
      color="link"
      className={classNames(
        hidden ? "invisible" : "",
        "btn-link--inline",
        className
      )}
      {...props}
    >
      {icon ? <FontAwesomeIcon icon={icon} size="lg" /> : null}
      {children}
    </Button>
  );
};

export const Pagination = ({ table, showOptions = defaultShowOptions, noShow = false }) => {
  const _table = useTableContext();
  table = table ?? _table;

  const {
    gotoPage,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state,
    setPageSize,
    pageCount,
    rows
  } = table;
  const { pageSize, pageIndex } = state;

  return (
    <div>
      <Row className="text-sm space-y-2 sm:space-y-0 items-center">
        <Col sm="auto">
          <div>
            <span className="text-hc-darker font-medium">
              <Trans>Total results:</Trans>{" "}
            </span>
            <span className="text-gray-500">{rows.length}</span>
          </div>
        </Col>
        {!noShow && <Col sm="auto">
          <div>
            <span className="text-hc-darker font-medium">
              <Trans>Show:</Trans>
            </span>{" "}
            {showOptions.map((i) => (
              <PaginationButton
                key={i}
                disabled={pageSize === i}
                className="ml-2"
                onClick={() => setPageSize(i)}
              >
                {i}
              </PaginationButton>
            ))}
          </div>
        </Col>}
        <Col sm="auto" className="sm:ml-auto">
          <div className="space-x-1">
            <PaginationButton
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              icon={faAngleDoubleLeft}
              aria-label={"Double left"}
            />
            <PaginationButton
              onClick={previousPage}
              disabled={!canPreviousPage}
              icon={faAngleLeft}
              aria-label={"Single left"}
            />
            <span className="text-sm text-white-600">
              <Trans>
                Page {{ pageIndex: pageIndex + 1 }} of {{ pageCount }}
              </Trans>
            </span>
            <PaginationButton
              onClick={nextPage}
              disabled={!canNextPage}
              icon={faAngleRight}
              aria-label={"Single right"}
            />
            <PaginationButton
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              icon={faAngleDoubleRight}
              aria-label={"Double right"}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
