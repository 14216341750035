import { useController } from "react-hook-form";
import React from "react";

export const Input = ({
  component: Component = "input",
  name,
  defaultValue,
  control,
  rules,
  shouldUnregister,
  type,
  ...props
}) => {
  const { field } = useController({
    defaultValue,
    control,
    shouldUnregister,
    name,
    rules,
  });
  const { value, ...rest } = field;

  if (type === "checkbox" || type === "radio") {
    rest.checked = value;
  } else {
    rest.value = value;
  }

  return <Component {...rest} type={type} {...props} />;
};
