import React from "react";
import { useNavigate, useLocation } from "react-router";
import { StripeCard } from "./components/StripeCard";
import { FormGroup, SubmitButton } from "../../../components/react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Alert, Button, Media } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { useClient, useUserContext } from "../../../hooks";

export const PaymentMethod = () => {
  const { state } = useLocation();
  const { refreshUser } = useUserContext();
  const [clientSecret] = useState(state?.clientSecret);
  const [paid] = useState(state?.paid);

  const [error, setError] = useState();
  const methods = useForm({
    mode: "all",
  });
  const { handleSubmit } = methods;
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useClient();

  async function onSubmit() {
    if (!paid) {
      const card = elements.getElement(CardElement);

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card,
          },
        }
      );

      if (error) {
        setError(error.message);
      } else {
        try {
          await client.post("stripe/update_payment_method", {
            payment_method: paymentIntent.payment_method,
          });
        } catch (ex) {
          console.error(ex);
        }
      }
    }

    NotificationManager.success(t("Your subscription has been setup successfully!"));
    refreshUser();
    navigate("/stripe-subscription");
  }

  useEffect(() => {
    if (paid) {
      handleSubmit(onSubmit)();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paid]);

  if (paid) return null;
  if (!clientSecret){
    navigate(-1);

    return null;
  }

  return (
    <div>
    <div className="card text-center card-body">

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <FormGroup label={t("Card details")} name="card" required>
            <StripeCard
              shouldUnregister
              rules={{
                validate: (v) => !!v?.complete,
              }}
              name="card"
            />
          </FormGroup>
          {error ? <Alert color="danger">{error}</Alert> : null}
          <div className="flex justify-between">
            <Button onClick={() => navigate("../")}>Cancel</Button>
            <SubmitButton>
              <Trans>Confirm Subscription</Trans>
            </SubmitButton>
          </div>
        </FormProvider>
      </form>

      </div>
        <div className="alert alert-info text-center p-4 mt-4" role="alert">
          {"A subscription to this service is created using Stripe, a trusted payment service. We do not store any of your credit card details on our system."}

        </div>

        <li className="justify-content-center flex items-center pt-5">
            <Media object src={'/Powered by Stripe - blurple.svg'} width={250} height={250} aria-label="Powered by Stripe" />
        </li>
</div>
  );
};
