import { formatCurrency } from "../../../services/util";

export const selectPriceOption = (t) => (v) => {
  const { currency, recurring, product, nickname, billing_scheme, transform_quantity } = v;
  const { name } = product;
  const interval = recurring?.interval ?? v.interval;
  const unit_amount = v.unit_amount ?? v.amount;
  //eslint-disable-next-line
  const suffix = (billing_scheme == 'per_unit' && transform_quantity) ? `per pack of ${transform_quantity.divide_by} users` : '';
  return {
    ...v,
    interval,
    unit_amount,
    label: t(
      "{{ plan }}: {{ amount }} {{ currency }} {{ suffix }} every {{ interval }} ",
      {
        plan: nickname ?? name,
        currency: currency.toUpperCase(),
        amount: formatCurrency(unit_amount / 100, "0,0[.]00"),
        interval,
        suffix
      }
    ),
  };
};

export const getTotalPrice = (t) => (price, quantity) => {
  const { currency } = price;
  var q = quantity ?? 1;
  var tq = price.transform_quantity ?? price.transform_usage;
  if (tq) {
    var total_temp = (quantity / tq.divide_by)
    if (tq.round === 'up') {
      q = Math.ceil(total_temp);
    }
    if (tq.round === 'down') {
      q = Math.floor(total_temp);
    }
  }
  var x = Number((price.unit_amount ?? price.amount) / 100) * Number(q);
  const total = formatCurrency(
    x,
    "0,0[.]00"
  );
  return  t("{{total}} {{currency}} per {{ interval }} {{suffix}}", {
    interval: price.recurring?.interval ?? price.interval,
    total: total,
    currency: currency.toUpperCase()
  })
};


export const selectTotal = (v) => {
  const { total, starting_balance, ending_balance } = v;
  const creditUsed = ending_balance - starting_balance;
  return total - creditUsed;
};

export const selectPaymentMethodOption = (t) => (v) => {
  const { card } = v;
  const { last4, exp_month, exp_year } = card;
  return {
    ...v,
    label: t("Card ending {{ last4 }} (exp. {{ exp_month }}/{{ exp_year}})", {
      last4,
      exp_month,
      exp_year,
    }),
  };
};

export const selectDocumentProps = (v) => {
  const { invoice: item, qr_code: qrCode, tax_rates: taxRates, ...rest } = v;
  const { InvoiceNumber } = item;

  return [
    `invoice_${InvoiceNumber}.pdf`,
    {
      ...rest,
      qrCode,
      contact: item.Contact,
      taxRates,
      item,
      invoiceDocumentType: "sales",
    },
  ];
};
