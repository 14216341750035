import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: ["en"],
    supportedLngs: ["en"],
    preload: ["en"],
    debug: false,
    backend: {
      loadPath: "/api/static/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    nsSeparator: false,
    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
