import React from 'react';
import moment from "moment";
import numeral from "numeral";
import {
  isString,
  isFinite,
  partition,
  reduce,
  includes,
  omitBy,
  isUndefined,
} from "lodash";
import { isDecimal, isFloat } from "validator";
import invariant from "invariant";
import Big from "big.js";
export const formatLocalDate = (v, format = "DD/MM/YYYY") =>
  v ? moment(v).format(format) : null;
export const formatDate = (v, format = "YYYY-MM-DD") =>
  v ? moment(v).format(format) : null;
export const formatDateTime = (v) =>
  v ? moment(v).format("YYYY-MM-DD HH:mm") : null;
export const formatCurrency = (v, format = "0,0.00") => {
  if (isString(v)) {
    v = parseFloat(v);
    if (!isFinite(v)) return "";
  }
  return formatNumber(v, format);
};
export const formatXeroDate = (v) =>
  `DateTime(${moment(v).format("YYYY, M, D")})`;
export const formatNumber = (v, format) => {
  try {
    v = Big(v ?? null).round(4)
  } catch {}
  return numeral(v).format(format || "0.00");
};
export const formatPercentage = (v, format) => numeral(v).format("0.[00]%");

export const isCurrency = (
  v,
  { decimal_digits = "0,2", min = 0, max } = {}
) => {
  v = (v ?? "").toString();

  return (
    isDecimal(v, omitBy({ decimal_digits }, isUndefined)) &&
    isFloat(v, omitBy({ min, max }, isUndefined))
  );
};

export const formatAccountCurrency = (v) => {
  const num = numeral(v ?? null);
  if (num.value() === null) return "";
  return num.format("(0,0.00");
};

export const calculateTaxRate = (components) => {
  const [compounded, standard] = partition(components, "IsCompound");
  let standardTotal = 1;
  let effective = 0;
  let display = 0;

  for (const i of standard) {
    standardTotal += i.Rate / 100;
    display += i.Rate * 1;
  }

  for (const i of compounded) {
    effective += standardTotal * (1 + i.Rate / 100);
    display += i.Rate * 1;
  }

  return {
    DisplayTaxRate: display.toFixed(2),
    EffectiveRate: ((effective - 1) * 100).toFixed(2),
  };
};

export const calculateTax = (value, taxRate, lineAmountType) => {
  invariant(
    includes(["NoTax", "Inclusive", "Exclusive"], lineAmountType),
    "lineAmountType must be NoTax, Inclusive or Exclusive"
  );

  const rates = {};
  const [compounded, components] = partition(
    taxRate.TaxComponents,
    "IsCompound"
  );
  let total = value;
  let tax = 0;

  for (const i of components) {
    const rate = i.Rate / 100;
    let amount = 0;

    if (lineAmountType === "Exclusive") {
      amount = value * rate;
      total += amount;
    } else if (lineAmountType === "Inclusive") {
      amount = value - value / (rate + 1);
    } else {
      continue;
    }

    rates[rate] = (rates[rate] ?? 0) + amount;
    tax += amount;
  }

  for (const i of compounded) {
    const rate = i.Rate / 100;
    let amount = 0;

    if (lineAmountType === "Exclusive") {
      amount = value * rate;
      total += amount;
    } else if (lineAmountType === "Inclusive") {
      amount = value - value / (rate + 1);
    } else {
      continue;
    }

    rates[rate] += (rates[rate] ?? 0) + amount;
    tax += amount;
  }

  return {
    components: reduce(
      rates,
      (acc, tax, rate) => {
        if (!tax) return acc;
        acc.push({
          rate,
          tax,
        });
        return acc;
      },
      []
    ),
    tax,
    total,
  };
};


export const Div = ({ props, children }) => {
	return (
		<div {...props}>
			{children}
		</div>);
};