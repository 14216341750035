import React from "react";
import { useUserContext } from 'hooks';
import cx from "classnames";

export const Logo = ({
  linkTo = null,
  rounded = false,
  showName,
  classes,
  txtClasses,
  children,
  ...props
}) => {
  const { user } = useUserContext();

  return (
    <div className="flex" {...props}>
      <div className="contain_upload">
        {!!user?.organisation?.logo ? (
          <img className={cx('img-thumbnail', classes)} src={user?.organisation?.logo} alt={""} />
        ) : <img className={cx(classes)} src={'/st-logo-nobg.png'} alt="self-therapist logo" />}
      </div>
      {!!showName && (user?.organisation?.name ? (
        <h2 className={cx('m-auto pl-2', txtClasses)}>{user?.organisation?.name}</h2>
      ) : (
        <h2 className={cx('m-auto pl-2', txtClasses)}>Self Therapist</h2>
      ))}
      {children}

    </div>
  );
};

export default Logo;
