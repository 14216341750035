import React, { useMemo, useCallback } from "react";
import moment from "moment";
import BaseDatePicker from "react-datepicker";
import { useController } from "react-hook-form";

export const DatePicker = ({
  dateFormat = "dd/MM/yyyy",
  modelDateFormat = "YYYY-MM-DD",
  name,
  rules,
  defaultValue,
  control,
  shouldUnregister,
  ...props
}) => {
  const { field } = useController({
    name,
    rules,
    defaultValue,
    shouldUnregister,
    control,
  });
  const { value, onBlur, onChange } = field;

  const selected = useMemo(
    () => (value ? moment(value).toDate() : null),
    [value]
  );

  const _onChange = useCallback(
    (v) => {
      const m = moment(v);
      onChange(m.isValid() ? m.toDate() : null);
      onBlur();
    },
    [onChange, onBlur]
  );
  return (
    <BaseDatePicker
      onChange={_onChange}
      selected={selected}
      dateFormat={dateFormat}
      name={name}
      onBlur={onBlur}
      autoComplete="off"
      {...props}
    />
  );
};
