import React from "react";
import { Spinner } from "reactstrap";

export const LoadingSpinner = () => {
  return(
    <div className="col my-5">
      <div className="row justify-content-center">
        <Spinner animation="border" role="loading">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </div>
  )
}

export default LoadingSpinner;
