import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import { Page, Header } from 'components/common';

export const HowItWorks = () => {

	return (
		<Page background="help-pages">
			<Header className="mb-2" displayIcon={"false"} title={"How does it work?"}></Header>
			<div className="flex justify-content-center" >
				<Link className="btn-primary main-btn question-grid-items my-3 border-0 text-white h-10" to="/decision_tree_start">
					Start
				</Link>
			</div>
			<Row>
				<Col>
					<p className='start-button text-left'>
						<br />
						<b>What is this app for?</b>
						<br />
						This app will enable you to become your own counsellor.
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						<br />
						<b>Why might I need it?</b>
						<br />
						Because:<br /><br />
						<li>You may want to take control of your own
							emotional development</li>
						<li>You might be on a long wait list to see a
							counsellor</li>
						<li>You might want to do some work on your own
							between therapy sessions</li>
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						<br />
						<b>How does it work?</b>
						<br /><br />
						This tool is based mostly on the Person-centred counselling approach. In Person-centred
						counselling, the counsellor encourages you to think through your problems or issues without
						advising you or directing you. The counsellor doesn't tell you what to do because they can't
						possibly know what it means to be you. It uses the 'actualizing tendency', which is a process
						whereby you will naturally grow and change for the better, given the chance to explore how
						you think and feel. This tool replicates that process of self-discovery, where you are in
						charge of your growth and development.
						<br /><br />
						Use it for 10 mins a day, or an hour a week, or to suit you. There's enough questions to keep
						you going for around 10 years, so you're unlikely to run out of things to think about.
					</p>
				</Col>
			</Row>
		</Page>
	);
};

export default HowItWorks;
