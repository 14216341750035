import React from 'react';
import { Row, Col } from 'reactstrap';
import { Page, Header } from 'components/common';

export const TheResearch = () => {

	return (
		<Page background="admin-pages">
			<Header className="mb-2" displayIcon={"false"} title={"The academic background"}></Header>
			<Row>
				<Col>
					<p className='start-button text-left'>
						<br />
						Self-counselling is based on the principle of <b>Therapeutic Learning</b> (Renger & Macaskill, 2021b) which shows how therapy can be managed
						as a self-learning process.  So, in the same way that you can build your
						knowledge or skills without a teacher being present, you can also take
						charge of your own emotional development without a counsellor.  With a
						clear understanding of where you are now, and where you want to get to,
						as you think things over, your natural tendency to develop in a positive
						direction is activated (Rogers, 1995).  You can therefore be your own
						counsellor, any time, anywhere.
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						To enable you to have some idea of where you want to get to, Rogers’
						idea of <b>the ‘Fully Functioning Person’</b> (1995) has been defined in detail
						(Renger & Macaskill, 2021a).  This set of aims is available to
						help you decide where you want to get to if you need it. It doesn’t tell you
						how you ‘should’ be, but offers ideas as a start point.
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						Therapists use <b>questions</b> all the time in therapy (Renger, 2021) and the
						questions in this app have been developed by over 200 experienced
						therapists from around the world. They are designed to suit different
						ways of thinking and experiencing.
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						The tool also includes self-therapy guidance from a wide range of specialist therapists.
						These therapists are experienced in specific subject areas such as addiction, trauma or bereavement for example.
						Their contributions have been written specifically for the self-therapist tool and uniquely explain how some of their therapeutic processes could be implemented without access to a therapist.
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						<br />
						Renger, S. (2021) Therapists’ Views on the Use of Questions in Person-centred Therapy.
						<i> British Journal of Guidance & Counselling</i><br /><br />
						Renger, S., & Macaskill, A. (2021a)
						Simplifying the definition of the fully functioning person for client
						use. <i>Counselling and Psychotherapy Research.</i><br /><br />
						Renger, S., & Macaskill, A. (2021b) Developing the Foundations
						for a Learning Based Humanistic Therapy. <i>Journal of Humanistic
							Psychology</i><br /><br />
						Rogers, C. R. (1995). <i>On becoming a person: A therapist's view of
							psychotherapy.</i> Houghton Mifflin Harcourt.
					</p>
				</Col>
			</Row>
		</Page>
	);
};

export default TheResearch;
