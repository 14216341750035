import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { NotificationManager } from 'react-notifications';
import { useUserContext } from '../../hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { FormGroup, SubmitButton, Input } from '../../components/react-hook-form';
import { Row, Col, Button, InputGroup } from 'reactstrap';
import { Page, Footer, Header } from 'components/common';
import { Link } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import 'react-slideshow-image/dist/styles.css';
import 'assets/css/style.css';

export const Login = () => {
	const methods = useForm({
		defaultValues: {
			// connected_organisation: selectedOrganisation,
		},
		mode: 'all',
	});
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(true);
	const { handleSubmit } = methods;
	const { login } = useUserContext();
	const flavourText = "If you have any problems signing up, please email us at hello@self-therapist.com. Passwords need to be a minimum of 8 characters and a mix of numbers and letters"

	async function onSubmit({ username, password }) {
		try {
			await login(username, password);
		} catch (ex) {
			const status = ex?.response?.status;

			if (status === 400) {
				NotificationManager.error("Invalid username or password. Please try again.");
				return;
			} else {
				throw ex;
			}
		}

		navigate("/onboarding_instructions");
	}

	return (
		<>
			<Page background="admin-pages" footer={<Footer textToDisplay={flavourText} displayButtons={"false"}></Footer>}>
				<Header className="mb-2" displayIcon={"false"} title={"Sign In"}></Header>
				{/* <Container fluid='sm' className='col-sm-8 col-lg-5 h-100'> */}
				<Row className='align-items-center h-100'>
					<Col className='p-2'>
						<FormProvider {...methods}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<FormGroup>
									<label>Email address</label>
									<Input
										type='text'
										className='form-control'
										name={'username'}
										placeholder='Enter email'
										rules={{
											required: true,
										}}
									/>
								</FormGroup>

								<FormGroup>
									<label>Password</label>
									<InputGroup>
										<Input
											type={showPassword ? 'password' : 'string'}
											className='form-control'
											name={'password'}
											placeholder='Enter password'
											rules={{
												required: true,
											}}
										/>
										<Button className="password-btn " onClick={() => setShowPassword((v) => !v)} aria-label="Show password">
											{!!showPassword ? (
												<FontAwesomeIcon icon={faEye} className="mx-auto" />
											) :
												<FontAwesomeIcon icon={faEyeSlash} className="mx-auto" />
											}
										</Button>
									</InputGroup>
								</FormGroup>

								{/* <FormGroup>
										<label>Remember me</label>
										<Input
											type='checkbox'
											className='form-control'
											name={'remember'}
											rules={{
												required: false,
											}}
										/>
									</FormGroup> */}

								{/* <div className='form-group'>
										<div className='custom-control custom-checkbox'>
											<input type='checkbox' className='custom-control-input' id='remember' />
											<label className='custom-control-label' htmlFor='remember'>
												Remember me
											</label>
										</div>
									</div> */}

								<div className="flex justify-content-center mb-3" >
									{/* <div className="mb-3"> */}
									<SubmitButton style={{ background: "#007bff", border: "0px", color: "white", height: "40px" }} className="main-btn ml-2 mr-2 question-grid-items" aria-label="Login">Login</SubmitButton>
								</div>

								<Row>
									<Col>
										<p className='forgot-password text-left'>
											Don't have an account yet?
											<br />
											<Button color="link" href='signup' aria-label="Sign up">Sign Up</Button>
										</p>
									</Col>
									<Col>
										<p className='forgot-password text-right'>
											Forgot your password?
											<br />
											<Link to='/reset_password_request'>
												<Button color="link" aria-label="Reset password">Reset Password</Button>
											</Link>
										</p>
									</Col>
								</Row>
							</form>
						</FormProvider>

						<div className="flex justify-content-center" >
							<Link className="btn-primary main-btn question-grid-items my-3 border-0 text-white h-10" to="/confidentiality">
								Confidentiality
							</Link>
						</div>

					</Col>
				</Row>
				{/* </Container> */}
			</Page>
		</>
	);
};

export default Login;
