import React from "react";
import { useContext, createContext } from "react";

const TableContext = createContext();

export const useTableContext = () => useContext(TableContext);

export const TableContextProvider = ({ table, ...props }) => {
  return <TableContext.Provider value={table} {...props} />;
};
