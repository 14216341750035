import { atom } from "recoil";

export const tokenAtom = atom({
  key: "token",
  default: null,
  persistence_UNSTABLE: {
    type: true,
  },
});
export const organisationAtom = atom({
  key: "organisationId",
  default: null,
  persistence_UNSTABLE: {
    type: true,
  },
});
export const userAtom = atom({
  key: "user",
  default: null,
  persistence_UNSTABLE: {
    type: true,
  },
});

export const persistedAtoms = [
  tokenAtom,
  organisationAtom,
  userAtom
];
