import React from "react";
import { Row, Col } from 'reactstrap';
import ConsentCookie from "./components/common/ConsentCookie";

export const UnauthenticatedBase = ({ children }) => {
	const cookieConsented = localStorage.getItem("acceptedAllCookies");
	return (
		<>
			{children}
			<Row className="fixed-footer">
				<Col>
					<p className="left-side text-right">
						Copyright {" "}
						<a href={'https://www.self-therapist.com'} target="_blank" rel="noopener noreferrer">
							Self-Therapist Ltd
						</a>
					</p>
				</Col>
				<Col>
					<p className='right-side text-left'>
						<a href={'https://www.self-therapist.com/app-terms'} target="_blank" rel="noopener noreferrer">
							Terms of Use |
						</a>
						<a href={'https://www.self-therapist.com/app-privacy'} target="_blank" rel="noopener noreferrer">
							{" "}Privacy Policy
						</a>
					</p>
				</Col>
			</Row>
			{!cookieConsented &&
				<ConsentCookie />
			}
		</>
	);
};

export default UnauthenticatedBase;
