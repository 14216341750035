import React from "react";
// import LoginBase from "../Pages/Login/components/LoginBase";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    console.error(error);
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        // <LoginBase>
        //   Oops, something went wrong. You may try to{" "}
        //   <a
        //     href={window.location.pathname}
        //     onClick={(e) => {
        //       e.preventDefault();
        //       window.location.reload(false);
        //     }}
        //   >
        //     reload
        //   </a>{" "}
        //   the page to see if that resolves the issue.
        // </LoginBase>
        <h1> ERROR </h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
