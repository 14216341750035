import React from 'react';
import { Row, Col } from 'reactstrap';
import { Page, Header } from 'components/common';

export const AboutUs = () => {

	return (
		<Page background="help-pages">
			<Header className="mb-2" displayIcon={"false"} title="About Us"></Header>
			<Row>
				<Col xs={'12'} md={'12'} xl={'12'}>
					<h4>Overview</h4>
					<p>
						This tool is a stand-alone resource to support your mental health management and personal
						growth. If you are unable to access a counsellor/therapist, or if you prefer not to
						talk to anyone about your issues, you can use the tool to make progress alone. Alternatively, if you would like to work with
						a professional helper or friend, this tool will guide you both through the process. In
						summary, the tool replicates the therapy process, and equips you with the knowledge and skills to become your own self-therapist.
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={'12'} md={'12'} xl={'12'}>
					<h3>We are unique</h3>
					<p>
						This tool is the first of its kind to enable you to have the conversations, and engage in the processes, that occur between an experienced counsellor and client.
						It generates a unique process based on your needs, using many therapeutic methods such as Person-centred Therapy, Solution-focused Therapy,
						Motivational Interviewing, Psychoanalysis and Therapeutic Learning. Contrary to mental health apps currently available,
						the content is tailored and directed entirely by you rather than following a prescribed process.
					</p>
					<p>
						It is based on a typical 3 stage counselling process: It begins by helping you to establish what you want or need from therapy, i.e. your <b>aims</b>.
						Stage 2 provides you with a tailored selection of <b>questions</b> that relate to you and your aim (from a bank of 17,000 questions often used by therapists).
						Stage three provides you with more directive and unique <b>self-therapy guidance</b>, authored by <a href="https://www.self-therapist.com/specialist-collaborators">specialist therapist collaborators.</a>
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={'12'} md={'12'} xl={'12'}>
					<h3>Our aims</h3>
					<p>
						<li>
							To enable individuals who can’t or don’t want to work with a counsellor or coach to make
							progress alone.
						</li>
						<li>
							To enable a helper, friend or relative to provide support for an individual when a professional therapist or coach is
							unavailable.
						</li>
						<li>
							To reveal the ‘magic’ behind the counselling process so that individuals can counsel
							themselves using effective therapy techniques.
						</li>
						<li>
							To enable individuals to engage in a life-long process of mental health growth and self-actualization without the need for repeated therapy sessions.
						</li>
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={'12'} md={'12'} xl={'12'}>
					<h3>Our commitment to you</h3>
					<p>
						In all that we do, we aim: <br />
						<li>
							To provide you with the resources, tailored to you as an individual, to help you mentally heal,
							grow and mature.
						</li>
						<li>
							To make sure that any of the processes used in the Self-therapist tool are academically
							researched, so that you know that they are proven to be beneficial.
						</li>
						<li>
							To keep all of your personal data secure, and to encrypt your personal notes so that no one
							can read them.
						</li>
						<li>
							To provide excellent and timely customer service should you need it.
						</li>
						<li>
							To make sure you have crisis support telephone numbers just in case your self-counselling
							leads you down a difficult path.
						</li>
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={'12'} md={'12'} xl={'12'}>
					<h3>Who would benefit from using the tool</h3>
					<p>
						The Self-therapist tool can help the following people:
					</p>

					<ol type="1">
						<li>
							Individuals who wish to work on being mentally and emotionally ‘fully-functioning’ (or the best that
							they can be).
						</li>
						<li>
							Individuals who suffer from mild mental health issues (such as anxiety, low mood, stress or lack
							of direction).
						</li>
						<li>
							Employees, as part of a workplace development or wellbeing programme.
						</li>
						<li>
							Older people who wish to age successfully.
						</li>
					</ol>
					<br />
					<p>
						The tool is not suitable for clients with a diagnosable mental health problem (such as bi-polar
						disorder, or clinical depression for example) unless used under the close supervision of a qualified
						therapist. Neither is the tool suitable for under 18's at the moment.
					</p>
				</Col>
			</Row>

			<Row>
				<Col>
					<p className="mx-auto p-2 text-center" style={{ fontSize: "22px" }}>
						<b>
							<a href="https://www.self-therapist.com/supporters">
								Our Supporters
							</a>
						</b>
					</p>
				</Col>
			</Row>
		</Page >
	);
};

export default AboutUs;
