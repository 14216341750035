import React from "react";
import { Button as BaseButton } from "reactstrap";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Button = ({
  pending,
  color = "secondary",
  disabled,
  children,
  ...props
}) => {
  return (
    <BaseButton disabled={disabled || pending} color={color} {...props}>
      {pending ? (
        <FontAwesomeIcon className="mr-2" icon={faSpinner} spin />
      ) : null}
      {children}
    </BaseButton>
  );
};

export default Button;
