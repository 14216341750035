import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useClient } from "../../hooks";
import { Page, Header } from 'components/common';
import { NotificationManager } from 'react-notifications';

import {
  Input,
  FormGroup,
  Select,
  SubmitButton,
} from "../../components/react-hook-form";

export const EditQuestion = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;
  const isNew = !id || id === 'create';
  const methods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit, setValue } = methods;
  const client = useClient();
  const [elements, setElements] = useState();
  const [data, setData] = useState();
  const booleans = [
    { label: "true", value: "true" },
    { label: "false", value: "false" }
  ];

  useEffect(() => {
    client.get(`element`).then(({ data }) => {
      const selectOptions = data.map(o => ({ value: o.id, label: o.name }))
      setElements(selectOptions);
    }).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Could not retrieve the elements");
    })
    if (!isNew) {
      client.get(`question/${id}`).then(({ data }) => {
        setData(data);
      }).catch(exc => {
        console.warn(exc);
        NotificationManager.error("Could not retrieve your question");
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (data) {

      setValue('key', data.key);
      setValue('question', data.question);
      setValue('question_type', data.question_type);
      setValue('prompt', data.prompt);
      setValue('follow_up', data.follow_up);
      setValue('is_important', data.is_important);
      setValue('element', data.element.id);
      setValue('question_order', data.question_order);
      setValue('question_type_group', data.question_type_group);
      setValue('is_important', { value: data.is_important, label: data.is_important ? 'true' : 'false' });

      if (!!elements && elements.length > 0) {
        setValue('element', { value: data.element, label: elements.find((x) => x.value === data.element).label });
      }
    }
  }, [data, elements, setValue]);


  async function onSubmit(x) {
    if (typeof x.is_important === "object") {
      x.is_important = x.is_important.value;
    }
    if (typeof x.element === "object") {
      x.element = x.element.value;
    }
    if (isNew) {
      client
        .post(`question`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    } else {
      client
        .put(`question/${id}`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    }

  }

  return (
    <Page background="admin">
      {!!isNew ? (
        <Header className="mb-2" displayIcon={"false"} title={`New Question`} />
      ) : (
        <Header className="mb-2" displayIcon={"false"} title={`Edit Question`} />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label={"Key"} name={"key"} required>
            <Input
              type='text'
              className='form-control'
              name={'key'}
              placeholder='Key'
              rules={{
                required: true,
                maxLength: 8
              }}
            />
          </FormGroup>
          <FormGroup label={"Question"} name={"question"}>
            <Input
              type='textarea'
              className='form-control'
              name={'question'}
              placeholder='Question'
              rules={{
                required: true,
                maxLength: 2048
              }}
            />
          </FormGroup>
          <FormGroup label={"Question Type"} name={"question_type"}>
            <Input
              type='textarea'
              className='form-control'
              name={'question_type'}
              placeholder='Question Type'
              rules={{
                required: true,
                maxLength: 255
              }}
            />
          </FormGroup>
          <FormGroup label={"Prompt"} name={"prompt"}>
            <Input
              type='textarea'
              className='form-control'
              name={'prompt'}
              placeholder='Prompt'
              rules={{
                required: false,
                maxLength: 2048
              }}
            />
          </FormGroup>
          <FormGroup label={"Follow Up"} name={"follow_up"}>
            <Input
              type='textarea'
              className='form-control'
              name={'follow_up'}
              placeholder='Follow Up'
              rules={{
                required: false,
                maxLength: 2048
              }}
            />
          </FormGroup>
          <FormGroup label={"Is Important"} name={"is_important"} required>
            <Select
              name={'is_important'}
              rules={{ required: true }}
              options={booleans}
            />
          </FormGroup>
          <FormGroup label={"Element"} name={"element"} required>
            <Select
              name="element"
              rules={{ required: true }}
              options={elements}
            />
          </FormGroup>
          {/* <FormGroup label={"Element ID"} name={"element"}>
          <Input
            type='text'
            className='form-control'
            name={'element'}
            placeholder='Element ID'
            rules={{
              required: true,
              maxLength: 4
            }}
          />
        </FormGroup> */}
          <FormGroup label={"Question Order"} name={"question_order"}>
            <Input
              type='text'
              className='form-control'
              name={'question_order'}
              placeholder='Question Order'
              rules={{
                required: false,
                maxLength: 8
              }}
            />
          </FormGroup>
          <FormGroup label={"Question Type Group"} name={"question_type_group"}>
            <Input
              type='text'
              className='form-control'
              name={'question_type_group'}
              placeholder='Question Type Group'
              rules={{
                required: false,
                maxLength: 255
              }}
            />
          </FormGroup>

          <div className="flex justify-between">
            <SubmitButton color="primary">
              {isNew ? "Create" : "Save"}
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};
export default EditQuestion;
