import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { LoadingSpinner, DecisionButton, Page, Footer, Header, Breadcrumbs, cipher } from "components/common";

import { useClient } from 'hooks';
import { Row, Col } from 'reactstrap';

export const ElementChosen = () => {
	const params = useParams();
	const client = useClient();
	const [chosenElement, setChosenElement] = useState();
	const [questionGroups, setQuestionGroups] = useState([]);
	const [AimId, setAimId] = useState(null);
	const [aimNameOverride, setAimNameOverride] = useState();
	const [questionGroupDescriptions, setQuestionGroupDescriptions] = useState([]);
	const [questionGroupImages, setQuestionGroupImages] = useState([]);
	const flavourText = "The questions on the following pages are in order from section 1 through 5, but you can dip in and out of them in any order, just doing the ones that suit you. If the question seems unhelpful, move on. For more help, click on the question icons."
	const pageHead = "What kind of questions might help	me most?";

	useEffect(() => {

		client.get(`element/${cipher.deobfuscate(params['element_id'])}`).then(e => {
			setChosenElement(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not your retrieve chosen aim. Please try again.");
		})

		client.get(`question_groups`).then(e => {
			setQuestionGroups(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve the question group names. Please try again.");
		})

		client.get(`question_groups_desc`).then(e => {
			setQuestionGroupDescriptions(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve the question group descriptions. Please try again.");
		})

		client.get(`question_groups_images`).then(e => {
			setQuestionGroupImages(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve the question group images. Please try again.");
		})
		client.get(`personalisedaim?Element_id=${cipher.deobfuscate(params['element_id'])}`).then(e => {
			var dataset = e['data'];
			if (dataset.length > 0) {
				var data = dataset[0];
				setAimNameOverride(data.personalised_aim_text);
				setAimId(data.id);
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Page background="question-categories" footer={<Footer textToDisplay={flavourText} displayButtons={"true"} specificAim={AimId}></Footer>}>
			<Breadcrumbs parent={'Helpful Information'} current={'Choose Question Type'} parentLink={`/question_info/${params['element_id']}`} />
			<Header className="mb-2" displayIcon={"false"} title={pageHead}></Header>
			{!!chosenElement && (
				<Row className='align-items-center mt-2'>
					<Col className='i-know-card text-center'>
						<h5 className="aimbot-black my-4">Aim {chosenElement.key}: <b className="aimbot-blue my-4">{chosenElement.name}</b></h5>
						{aimNameOverride && (
							<>
								<h5 className="aimbot-black my-4">My specific aim is to: <b className="aimbot my-4">{aimNameOverride}</b></h5>
							</>
						)
						}
						<Row className="justify-content-around d-flex align-content-stretch flex-wrap">
							{!!questionGroups ? questionGroups.map((questionGroup, index) => (
								<Col sm={'5'} md={'5'} xl={'5'} key={index}>
									<DecisionButton
										keyName={index}
										overrideImage={`${questionGroupImages[index] && questionGroupImages[index][1]}`}
										link={`/question_list/${cipher.obfuscate(chosenElement.id)}/${questionGroup[0]}`}
										name={`${index + 1} ${questionGroup[1]}`}
										description={`${questionGroupDescriptions[index] && questionGroupDescriptions[index][1]}`}
									/>
								</Col>
							)) : <LoadingSpinner />}
						</Row>
					</Col>
				</Row>
			)}
		</Page>
	);
};

export default ElementChosen;
