import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { useForm, FormProvider } from 'react-hook-form';
import { SubmitButton } from '../../components/react-hook-form';
import { useClient } from 'hooks';
import { Row, Col } from 'reactstrap';
import { Header, Page, Footer, Breadcrumbs, cipher } from 'components/common';

export const AimReflection = () => {
	const methods = useForm({
		defaultValues: {
			reflection: '',
		},
		mode: 'all',
	});
	const navigate = useNavigate();
	const client = useClient();
	const { handleSubmit, setValue, register } = methods;
	const params = useParams();
	const [chosenElement, setChosenElement] = useState();
	const [AimId, setAimId] = useState(null);
	const flavourText =
		<p>You might like to make the suggested aim more specific to you. For example, you might like to change “I manage stress” to “I manage stress at work”.
			Or you might like to change “I can say ‘No’ if I need to”, to “I can say ‘No’ to my Mum if I need to”.
			And, make sure your aim says exactly how you would <i>like</i> to be, not how you think you <i>should</i> be.</p>
	const { state } = useLocation();
	const origin = state?.from
	const pageHead = "My aim for therapy";

	const onSubmit = async (formData) => {


		try {
			if (!AimId) {
				await client.post('personalisedaim', {
					'personalised_aim_text': formData.reflection,
					'Element_id': chosenElement.id
				}).catch(exc => {
					console.warn(exc);
				});
			}
			else {
				await client.patch(`personalisedaim/${AimId}`, {
					'personalised_aim_text': formData.reflection,
					'Element_id': chosenElement.id
				}).catch(exc => {
					console.warn(exc);
				});
			}
			var tok = cipher.obfuscate(chosenElement.id);

			if (chosenElement && cipher) {
				navigate('/self_feedback/' + tok, { state: { from: origin } });
			}
		}
		catch (e) {
			NotificationManager.error('An error occurred with the Reflection box.');
		}
	};

	useEffect(() => {
		client.get(`element/${cipher.deobfuscate(params['element_id'])}`).then(e => {
			setChosenElement(e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve your chosen element. Please try again.");
		})

		client.get(`personalisedaim?Element_id=${cipher.deobfuscate(params['element_id'])}`).then(e => {
			setValue('reflection', e['data'][0].personalised_aim_text);
			setAimId(e['data'][0].id);
		}).catch(exc => {
			console.warn(exc);
			// NotificationManager.error("Could not retrieve your reflection");
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Page background="admin-pages" footer={<Footer textToDisplay={flavourText} displayButtons={"true"} specificAim={AimId}></Footer>}>
			<Breadcrumbs
				parent={origin === 'flowOne' ? 'Search My Aim' : origin === 'quiz' ? 'Self Assessment' : 'Decide Aim'}
				current={'Aim Reflection'}
				parentLink={origin === 'flowOne' ? '/decision_tree_i_know' : origin === 'quiz' ? '/quality_statements' : null}
			/>

			<Header className="mb-2" displayIcon={"false"} title={pageHead} />
			<FormProvider {...methods}>
				{!!chosenElement && (
					<Row className='align-items-center h-100 mt-2'>
						<Col className='i-know-card text-center'>
							<h5 className="aimbot-black">Aim {chosenElement.key}: <b className="aimbot-blue my-4">{chosenElement.name}</b></h5>
							<h5 className="my-4"><b>Would I like to make this aim more specific to me?</b></h5>

							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="d-flex align-content-stretch justify-content-center flex-wrap">
									<textarea
										style={{ color: '#495057' }}
										rows={8}
										cols={50}
										className="p-6"
										placeholder="My specific aim is to..."
										{...register("reflection", {})}
									/>

								</div>
								<SubmitButton className="btn-primary main-btn my-3 border-0 text-white h-10">
									Continue
								</SubmitButton>
							</form>
						</Col>
					</Row>
				)}
			</FormProvider>
		</Page>
	);
};

export default AimReflection;
