import React from "react";
import { useFormState, useWatch } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../services/util";
import { Alert } from 'reactstrap';
export const CalculatedPrice = ({setQuantity, showLabel=true}) => {
  const [price, quantity] = useWatch({
    name: ["price", "quantity"],
  });

  const { isValid } = useFormState();
  const { t } = useTranslation();

  if (!isValid) return null;

  const { currency } = price;
  var q = quantity ?? 1;
  var tq = price.transform_quantity ?? price.transform_usage;
  if (tq) {
    var total_temp = (quantity / tq.divide_by)
    if (tq.round === 'up') {
      q = Math.ceil(total_temp);
    }
    if (tq.round === 'down') {
      q = Math.floor(total_temp);
    }
    setQuantity(q * tq.divide_by);
  } else {
    setQuantity(q);
  }
  var x = Number((price.unit_amount ?? price.amount) / 100) * Number(q);
  const total = formatCurrency(
    x,
    "0,0[.]00"
  );

  return (
    <div>
      {!!showLabel && <>
      <strong>
        <Trans>Subscription cost:</Trans>
      </strong>{" "}
      </>
      }
      {total} {currency.toUpperCase()}{" "}
      {t("per {{ interval }} {{suffix}}", {
        interval: price.recurring?.interval ?? price.interval,
      })}{" "}
      {!!tq && (
        <>
          {`(${q} packs of ${tq.divide_by})`}
          {/* {(quantity % tq.divide_by) !== 0 && (<Alert color="warning">You have {quantity} users but have bought up to {q * tq.divide_by} </Alert>)} */}
        </>
      )}
    </div>
  );
};
