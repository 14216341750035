import React, { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";

import { Form, Formik, Field } from "formik";
import { useNavigate } from 'react-router-dom';
import { Page, EditModal } from "components/common";
import { useClient, useUserContext } from "hooks";
import PendingButton from "components/common/PendingButton";
import Button from "components/common/Button";
import DebouncedInput from "components/common/DebouncedInput";
import { InputGroup } from "reactstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import download from "downloadjs";

export const MyAccount = () => {

  const client = useClient();
  const { user, refreshUser } = useUserContext();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [passwordOk, setPasswordOk] = useState(false)
  const [initialValues, setInitialValues] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      setInitialValues({
        username: user.username,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        contact_number: user.contact_number,
      });
    }

  }, [user])
  function deleteSelf() {

    client.delete(`myaccount`).then(e => {
      NotificationManager.success("Your account has been deleted successfully.");
      navigate('/login');
    }
    ).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Could not delete your account at this time. Please try again.");
    });
  }
  const DeleteModalHandler = () => {
    setIsDeleteModal(!isDeleteModal);
  }

  // function cookieOptOut() {
  //   alert("To be able to continue with the tool, please be sure you agree with each of the statements.");
  //   resetCookieConsentValue('acceptedAllCookies');
  //   window.location.reload();
  // }

  const ActionButtons = () => {
    return (
      <div className="row justify-content-end mx-0">
        {/* <Button className="rounded-md mr-2" color="warning" onClick={() => cookieOptOut()}>Opt out of Cookies</Button> */}
        <Button className="h-12 float-right mr-2" onClick={onDownload} style={{ background: "#007bff", border: "0px", color: "white", height: "40px" }} aria-label="Download notes">Download Notes{" "}</Button>
        <Button className="rounded-md mr-2" color="danger" onClick={() => DeleteModalHandler()} aria-label="Delete my account">Delete My Account</Button>
      </div>
    )
  }

  const onDownload = () => {
    client
      .get(`personalisedaim/download_csv`)
      .then((responseData) => {
        let csvContent = "";
        responseData.data.forEach(function (rowArray) {
          const row = rowArray
            .map(v => v?.replaceAll('"', '""'))  // escape double colons
            .map(v => `"${v}"`)  // quote it.
            .join(",");
          csvContent += row + "\r\n";
        });

        download(csvContent, 'self-therapist-aims.csv', 'text/csv');
        NotificationManager.success('Successfully downloaded CSV');
      })
      .catch((ex) => {
        console.error(ex);
        NotificationManager.error('An error occurred downloading the aims CSV');
      });
  };

  function onSubmit(values) {

    client.patch(`myaccount`, values).then(e => {
      NotificationManager.success("Your account has been updated!");
      refreshUser();
    }).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Failed to update your account at this time, please try again.");
    })
  }
  function setPasswordToConfirm(e) {
    setIsFetching(true);
    client.post("api-token-auth", {
      username: user.username,
      password: e.target.value,
    }).then(e => {
      setPasswordOk(true);
    }).catch(exc => {
      setPasswordOk(false);
    }).finally(() => setIsFetching(false));
  }
  return (
    <Page title="My Account" actions={initialValues && <ActionButtons />}>
      <EditModal title={"Delete Your Account"} open={isDeleteModal} setOpen={setIsDeleteModal}>
        <p>
          Of course, no problem. Before you go, you might like to download any notes you've made, as these will be deleted too.
          All your account information will be finally deleted in 30 days, (giving you a little time to reinstate your account if you change your mind).
          Use the password reset on the login page if you'd like to reinstate your account.
        </p>
        <p>
          Thanks for using the Self-therapist tool.  We hope you’ve found it valuable.  Before you go, would you be kind enough to fill out the User Feedback form, which can be accessed from the Main Menu?
          It will help us to improve the tool for other people.
        </p>
        <p>
          Thanks so much.
        </p>

        <p>Please enter your password to confirm.</p>
        <div className=" row">
          <div className="col-md-3">
            Password:
          </div>
          <div className="col-md-9">
            <InputGroup>
              <DebouncedInput className="form-control" type={showPassword ? 'password' : 'string'} name="password" onChange={(e) => setPasswordToConfirm(e)} />
              <Button className="password-btn " onClick={() => setShowPassword((v) => !v)}>
                {!!showPassword ? (
                  <FontAwesomeIcon icon={faEye} className="mx-auto" />
                ) :
                  <FontAwesomeIcon icon={faEyeSlash} className="mx-auto" />
                }
              </Button>
            </InputGroup>
          </div>
        </div>

        <div className="row justify-content-end mx-0 py-2">
          <PendingButton color="danger" pending={isFetching} className="rounded-md" disabled={!passwordOk} onClick={() => deleteSelf()}>
            {passwordOk ? "Delete" : "Password required"}
          </PendingButton>
        </div>
      </EditModal>
      {initialValues && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        >{({ values, errors, setFieldValue, handleSubmit, isSubmitting }) => (
          <Form>
            <div className="input-wrapper">
              <label className="input-label" for="first_name">
                First Name
              </label>
              <Field
                id="first_name"
                className="input"
                name="first_name"
              />
            </div>

            <div className="input-wrapper">
              <label className="input-label" for="last_name">
                Last Name
              </label>
              <Field
                id="last_name"
                className="input"
                name="last_name"
              />
            </div>

            <div className="input-wrapper">
              <label className="input-label" for="username">
                Username<label style={{ color: 'red' }}>*</label>
              </label>
              <Field
                id="username"
                required
                className="input"
                name="username"
              />
            </div>
            <div className="alert alert-info text-center p-2" role="alert">
              <p>
                {"The username can be whatever you'd like. But remember, it's what you sign in with. "}
              </p>
              <p>
                {"By default. It's your email address"}
              </p>
            </div>
            <div className="input-wrapper">
              <label className="input-label" for="email">
                Email<label style={{ color: 'red' }}>*</label>
              </label>
              <Field
                id="email"
                required
                className="input"
                name="email"
              />
            </div>
            <div className="input-wrapper">
              <label className="input-label" for="contact_number">
                Phone Number
              </label>
              <Field
                id="contact_number"
                className="input"
                name="contact_number"
              />
            </div>
            <div className="row justify-content-end mx-0">
              <PendingButton className="rounded-md h-10" color="success" pending={isSubmitting}>
                Submit
              </PendingButton>
            </div>
          </Form>
        )}</Formik>
      )}
    </Page>
  )
}

export default MyAccount;
