import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { useForm, FormProvider } from 'react-hook-form';
import { SubmitButton } from '../../components/react-hook-form';
import { useClient } from 'hooks';
import { Row, Col } from 'reactstrap';
import { Header, Page, Footer, Breadcrumbs, cipher } from 'components/common';
import "./SelfFeedback.scss"

export const SelfFeedback = () => {
	const client = useClient();
	const navigate = useNavigate();
	const params = useParams();
	const [chosenElement, setChosenElement] = useState();
	const [AimId, setAimId] = useState(null);
	const [aimNameOverride, setAimNameOverride] = useState();
	const flavourText = "You can rate yourself before you start working on your aim, and then when you've finished.  Seeing progress can be encouraging.  Your ratings will be saved in your notebook."
	const { state } = useLocation();
	const origin = state?.from
	const pageHead = "Self-Rating";
	const [before_progress, setBeforeProgress] = useState(null);
	const [before_negative, setBeforeNegative] = useState(null);


	const methods = useForm({
		defaultValues: {
			before_negative,
			before_progress,
		},
		mode: 'all',
	});

	const { handleSubmit, setValue, register } = methods;

	useEffect(() => {

		// eslint-disable-next-line react-hooks/exhaustive-deps

		client.get(`personalisedaim?Element_id=${cipher.deobfuscate(params['element_id'])}`).then(e => {
			var dataset = e['data'];
			if (dataset.length > 0) {
				var data = dataset[0];
				setValue('before_negative', data.before_negative);
				setValue('before_progress', data.before_progress);
				setBeforeNegative(data.before_negative);
				setBeforeProgress(data.before_progress);

				setAimId(data.id);
				setAimNameOverride(data.personalised_aim_text);
				setChosenElement(data.Element);

			} else {
				client.get(`element/${cipher.deobfuscate(params['element_id'])}`).then(e => {
					setChosenElement(e['data']);
				}).catch(exc => {
					console.warn(exc);
					NotificationManager.error("Could not retrieve your chosen element. Please try again.");
				})
			}
		}).catch(exc => {
			console.warn(exc);
			// NotificationManager.error("Could not retrieve your reflection");
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])



	const onSubmit = async (formData) => {
		try {
			if (!AimId) {
				await client.post('personalisedaim', {
					'before_negative': formData.before_negative,
					'before_progress': formData.before_progress,
					'Element_id': chosenElement.id
				}).catch(exc => {
					console.warn(exc);
				});;
			}
			else {
				await client.patch(`personalisedaim/${AimId}`, {
					'before_negative': formData.before_negative,
					'before_progress': formData.before_progress,
					'Element_id': chosenElement.id
				}).catch(exc => {
					console.warn(exc);
				});
			}
			var tok = cipher.obfuscate(chosenElement.id);

			if (chosenElement && cipher) {
				navigate('/question_info/' + tok, { state: { from: origin } });
			}
		}
		catch (e) {
			NotificationManager.error('An error occurred with the Self Feedback submission.');
		}
	};

	return (
		<Page background="admin-pages" footer={<Footer textToDisplay={flavourText} displayButtons={"true"} specificAim={AimId}></Footer>}>
			<Breadcrumbs parent={'Aim Reflection'} current={'Rate Myself'} parentLink={`/aim_reflection/${params['element_id']}`} />
			<Header className="mb-2" displayIcon={"false"} title={pageHead}></Header>
			{!!chosenElement && (
				<Row className='align-items-center h-100 m-auto'>
					<Col sm={'12'} md={'12'} lg={'12'} xl={'12'} className='i-know-card text-center'>
						{aimNameOverride ? (
							<h5 className="aimbot-black my-4">My specific aim is to: <b className="aimbot my-4">{aimNameOverride}</b></h5>
						) :
							<h5 className="aimbot-black my-4">Aim {chosenElement.key}: <b className="aimbot-blue my-4">{chosenElement.name}</b></h5>
						}

						<FormProvider {...methods}>
							<div id="form-wrapper">
								<form onSubmit={handleSubmit(onSubmit)} className="rating-wrapper rating-form" noValidate>
									<p className="page-header"><b>This is how much this issue is affecting me negatively at the moment:</b></p>

									{/* <Col sm={'12'} md={'12'} lg={'12'} xl={'12'} className='text-left'>
									</Col> */}
									<Row>
										<Col xs={'6'} className='rating-headers text-left'>
											<b>1 = Hardly at all</b>
										</Col>
										<Col xs={'6'} className='rating-headers text-right'>
											<b>10 = Significantly</b>
										</Col>
									</Row>

									<div className="rating-slider">
										{[...Array(10).keys()].map((value) =>
											<React.Fragment
												key={value}
											>
												<input
													type="radio"
													name="rating1"
													id={"rating1" + value}
													value={value + 1}
													defaultChecked={value + 1 === before_negative}

													{...register("before_negative")}
													required
												/>
												<label htmlFor={"rating1" + value} data-rating={value + 1}></label>
											</React.Fragment >
										)
										}
										<div id="pos"></div>
									</div>

									<p className="page-header"><b>This is how well I can manage this issue at the moment:</b></p>

									<Row>
										<Col xs={'6'} className='rating-headers text-left'>
											<b>1 = Not at all</b>
										</Col>
										<Col xs={'6'} className='rating-headers text-right'>
											<b>10 = Very well</b>
										</Col>
									</Row>

									<div className="rating-slider">
										{[...Array(10).keys()].map((value) =>
											<React.Fragment
												key={value}
											>
												<input type="radio"

													name="rating2"
													id={"rating2" + value}
													defaultChecked={value + 1 === before_progress}
													value={value + 1}
													{...register("before_progress")}
													required />
												<label htmlFor={"rating2" + value} data-rating={value + 1}></label>
											</React.Fragment>
										)
										}
										<div id="pos"></div>
									</div>
									<SubmitButton className="btn-primary main-btn my-3 border-0 text-white h-10">Continue</SubmitButton>

								</form>
							</div>
						</FormProvider>

						{/* <Row className="justify-content-center">
							<a className="btn-primary h-100 p-3 w-50 rounded" href="https://forms.gle/roDiCP3BepNaCDcn7" target="_blank" rel="noopener noreferrer">Visit the client feedback to record</a>
						</Row> */}

						{/* <Row className="justify-content-center h-100 p-3 m-auto">
							<Link to={`/question_info/${cipher.obfuscate(chosenElement.id)}`} state={{from:origin}} className="main-btn question-grid-items" style={{background: "#007bff", color: "white"}}>Continue</Link>
						</Row> */}

					</Col>
				</Row>

			)}
		</Page>
	);
};

export default SelfFeedback;
