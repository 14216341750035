import React from 'react';
import { Row, Col } from 'reactstrap';
import { Page, Header } from 'components/common';

export const Help = () => {

	return (
		<Page background="admin-pages">
			<Header className="mb-2" displayIcon={"false"} title={'What do I do?'}></Header>

			<Row>
				<Col>
					<p className='start-button text-left'>
						<br />
						1. First, you’ll be asked whether you want to work on the tool <b>in your head, or out loud with someone you trust.</b>
						<br />
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						2. Then you’ll be encouraged to decide on your aim(s) for your self-therapy by:
						<br />
						<li><b>A. Telling the tool broadly what your aim for counselling is,</b> or</li>
						<li><b>B. Letting the tool help you decide</b> what to work on, or</li>
						<li><b>C. Browsing a list of statements</b> that describe the ‘fully functioning person’ to work on</li>
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						3. You can <b>choose the type of questions</b> that you prefer to think about:
						<br />
						<li>Thinking ones</li>
						<li>Feeling ones</li>
						<li>Ones focused on the past</li>
						<li>Motivating ones</li>
						<li>Ones that take a different perspective on the situation</li>
						If you have time, the most useful approach is to work through all the different types in order.
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						4. Allow your <b>self-healing processes</b> to get to work as you think through the questions.<br />You can:
						<br />
						<li>Choose questions that seem helpful</li>
						<li>Ignore ones that don’t seem helpful, or ones that might trigger something that you can’t deal with</li>
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						5. Write your responses in your <b>notebook</b> if you would like to.
						<br />
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						6. You can also do some in-depth self-therapy using the <b>Self-therapy guides</b> (if they are enabled).
						<br />
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p className='start-button text-left'>
						7. <b>Discuss your thoughts with a therapist or helper</b> if you think that would be useful.
					</p>
				</Col>
			</Row>
		</Page>
	);
};

export default Help;
