import React from 'react';
import { Row, Col } from 'reactstrap';
import { Page, Header } from 'components/common';

export const CrisisPage = () => {

	return (
		<Page background="help-pages">
			<Header className="mb-2" displayIcon={"false"} title={"Crisis Information"}></Header>
			<Row>
				<Col xs={'12'} md={'12'} xl={'12'}>
					<p>
						<h6>
							<b>If you are experiencing thoughts or feelings that are overwhelming you right now, contact one of the numbers below.
								If this issue has been going on for a while, contact your GP, (or usual medical services) for face to face support.
								There are also links (in the Study Guides) to therapists who can provide paid online support for long term issues.</b>
						</h6>
						<br />
						For help controlling your thoughts try <a href="https://www.self-therapist.com/fairies">this</a>. <br /><br />
						<b>MIND</b><br />
						Tel. 0300 123 3393, (weekdays 9am - 6pm). Their Infoline provides an information and signposting
						service. Alternatively, text 86463.<br /><br />
						<b>Shout</b><br />
						Text 85258. This is a free, confidential, 24/7 text messaging support service for anyone who is
						struggling to cope.<br /><br />
						<b>Samaritans</b><br />
						Tel. 116 123, or email jo@samaritans.org - Whatever you&#39;re going through, you can contact the
						Samaritans for support.<br /><br />
						<b>Sane</b><br />
						(4.30 - 10.30pm daily). They provide free, confidential emotional support. You can leave a message
						on tel. 07984 967 708 giving your first name and a contact number, and one of their professionals or
						senior volunteers will call you back as soon as practicable. You can also email them
						at support@sane.org.uk<br /><br />

						<b>Papyrus UK</b><br />
						This charity focuses on the prevention of young suicide (35yrs and under). Tel. 0800 068 4141, or
						text 07860 039967.<br /><br />

						<b>Campaign against living miserably (CALM)</b><br />
						Tel. 0800 58 58 58, 5pm-midnight every day.<br /><br />

						<b>Kooth</b><br />
						<a href="https://www.kooth.com">www.kooth.com</a> is an online mental health and wellbeing support service for young adults –
						also, their app for adults (Quell) provides access to counselling services <a href="https://www.qwell.io">www.qwell.io</a><br /><br />

						<b>YoungMinds</b><br />
						Tel. 0808 802 5544, (Mon–Fri 9.30am-4pm). They provide a free, 24/7 text support for young people
						experiencing a mental health crisis.<br /><br />
					</p>
				</Col>
			</Row>
		</Page>
	);
};

export default CrisisPage;
