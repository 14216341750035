import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Media, UncontrolledTooltip } from 'reactstrap';
import { useClient } from '../../hooks';
import { isMobile } from "react-device-detect";
import { StatementsModal } from "components/common";
import { NotificationManager } from "react-notifications";

import classNames from "classnames/dedupe";
export const DecisionButton = ({
  pending,
  color = "secondary",
  keyName,
  overrideImage,
  furtherClass = "",
  link,
  name,
  description,
  children,
  origin,
  canPersonalise = false,
  optionsPage = false,
  element = null,
  baseClass = 'h-100 px-6 py-4 m-auto clicky mobile-padlow',
  rowClass = 'border-dark second-btn',
  tag: Tag = Link,
}) => {
  const client = useClient();
  const formData = {};
  const [statementsModalOpen, setStatementsModalOpen] = useState(false);
  const [saved, setSaved] = useState(false);

  const navigate = useNavigate();
  function goToLink() {
    navigate(link, { state: { from: origin } });
  }
  const addAimToNotebook = () => {
    if (element?.personalised_aim) {
      NotificationManager.info('This aim is already in your notebook!');
    } else {
      formData['Element_id'] = element?.id;
      client.post('personalisedaim', formData).then((resp) => {
        setSaved(true);
        NotificationManager.success('This aim has been added to your notebook!');
      }).catch(exc => {
        NotificationManager.info('This aim is already in your notebook!');
      })
    }
  }
  useEffect(() => {
    setSaved(!!(element?.personalised_aim))
  }, [element]);

  return (
    <>
      <Row className={classNames(rowClass)}>
        {!!description ?
          <>
            <Col className={classNames(baseClass, furtherClass, "col-4")}>

              {!!overrideImage ?

                <Media
                  object
                  src={`/icons/${overrideImage}`}
                  style={{ maxWidth: "75px", paddingBottom: "5px" }}
                  id={`tooltipSelect_${keyName}`} onClick={(e) => e.preventDefault()}
                  aria-label={"Click for more information"}
                />

                :

                <Media
                  object
                  src={'/icons/hover.png'}
                  style={{ maxWidth: "35px", paddingBottom: "5px" }}
                  id={`tooltipSelect_${keyName}`} onClick={(e) => e.preventDefault()}
                  aria-label={"Click for more information"}
                />

              }


              <UncontrolledTooltip placement="auto" target={`tooltipSelect_${keyName}`} trigger={!!isMobile ? ("click") : ("click hover")}>
                {description}
              </UncontrolledTooltip>
            </Col>

            <Tag className={classNames("text-dprimary col-8 align-text-center", baseClass, furtherClass)} to={link} state={{ from: origin }}>
              {name}
            </Tag>
          </>
          :
          !!canPersonalise ?
            <>
              {!!element && element.statements.length > 0 ? (

                <Col xs={'2'} className={classNames(baseClass, 'justify-content-center', furtherClass)}>
                  <Media className="clicky" object src={'/icons/outside-pink-hover.png'} width={30} height={30} onClick={() => setStatementsModalOpen(true)} aria-label={"Click for more information"} />
                </Col>
              ) :
                <Col xs="2" className="m-auto"></Col>
              }
              <Col xs="8" className="m-auto">
                <Tag className={classNames(baseClass, furtherClass)} to={link} state={{ from: origin }}>
                  {name}
                </Tag>
              </Col>
              <Col xs={'2'} className={classNames(baseClass, furtherClass)}>
                <Media className="clicky" object src={saved ? '/icons8-done-50.png' : '/PlusBook.png'} width={45} height={45} onClick={() => addAimToNotebook()} aria-label={"Click to add to notebook"} />

              </Col>
            </>
            :
            (

              <Col md={'12'} className={classNames(baseClass, furtherClass, 'hover-blue')} onClick={() => goToLink()}>
                {name}
              </Col>
            )
        }
      </Row>

      <StatementsModal
        key={keyName}
        open={statementsModalOpen}
        setOpen={setStatementsModalOpen}
        parentElement={!!element ? element : 1}
        // !!isMobile ? ("click") : ("click hover")
        onSubmitParent={() => { }} />
      {/* {!!canPersonalise && !!setEditFeedbackAimModalOpen && element?.personalised_aim && (
        <EditFeedbackAimModal
          open={editFeedbackAimModalOpen}
          setOpen={setEditFeedbackAimModalOpen}
          selectedPersonalisedAim={element?.personalised_aim}
          element_id={element?.id}
          // !!isMobile ? ("click") : ("click hover")
          onSubmitParent={() => { }} />
      )} */}
    </>
  );
};

export default DecisionButton;
