import { useMemo } from "react";

export const useServerFilterParameters = (hooks) => {
  hooks.useControlledState.push(useControlledState);
};

useServerFilterParameters.pluginName = "useServerFilterParameters";

function useControlledState(state) {
  const { pageIndex, pageSize, sortBy, globalFilter } = state;
  const offset = pageIndex * pageSize;
  const ordering = useMemo(
    () => sortBy.map((i) => `${i.desc ? "-" : ""}${i.id}`).join(","),
    [sortBy]
  );
  const limit = pageSize;
  const search = globalFilter;

  return useMemo(
    () => ({
      ...state,
      offset,
      limit,
      ordering: ordering || undefined,
      search: search || undefined,
    }),
    [state, offset, limit, ordering, search]
  );
}
