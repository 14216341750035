import React, { useState } from 'react';
import { NotificationManager } from "react-notifications";
import { FormProvider, useForm } from "react-hook-form";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  Input,
  FormGroup,
  SubmitButton
} from "../../components/react-hook-form";
import { useClient, useUserContext } from 'hooks';
import { Button, Alert, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const InviteUserModal = ({ Modal, toggle, organisation }) => {
  const client = useClient();
  const { refreshUser } = useUserContext();
  const methods = useForm({
    defaultValues: {
      'Organisation': organisation.id,
      'EmailAddress': '',
      'ContactNumber': ''
    },
    mode: "onBlur",
  });
  const { handleSubmit, reset, setValue, control } = methods;
  const [key, setKey] = useState('email');
  const [invitesCount, setInvitesCount] = useState(1);
  const [failures, setFailures] = useState();
  const [successes, setSuccesses] = useState();
  const [isPending, setIsPending] = useState(false);

  function checkUsers(invites) {
    return organisation?.admin_info?.users_left === null || organisation?.admin_info?.users_left >= invites;
  }


  const onEmailSubmit = (formData) => {
    setIsPending(true);

    setFailures();
    setSuccesses();

    client.post("invites/create_and_send_invite", formData).then((resp) => {
      if (invitesCount > 1) {
        setSuccesses(resp.data.success)

        setFailures(resp.data.failures)
        if (resp.data.ok > 0) {
          NotificationManager.success(`${resp.data.successes} invitations sent successfully`);
        }
        if (resp.data.failures > 0) {
          NotificationManager.error(`${resp.data.failed} invitations failed`);
        }
      } else {
        NotificationManager.success("Invitation sent successfully");

      }
      setInvitesCount(1);
      reset({
        'Organisation': organisation.id,
        'EmailAddress': '',
        'ContactNumber': ''
      })
      // toggle();
      refreshUser();
    }).catch(err => {
      console.error(err);
      const detail = err?.response?.data?.detail;

      if (detail) {
        NotificationManager.error(detail);
        return;
      } else {
        NotificationManager.error("Something went wrong. Please try again.");
      }
    }).finally(function () { setIsPending(false); });
    
  }

  return (
    <Modal size={'lg'}>
      <ModalHeader closeButton>Invite User</ModalHeader>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
          reset({
            'Organisation': organisation.id,
            'EmailAddress': null,
            'ContactNumber': null
          })
        }}
        className="mb-3"
      >
        <Tab eventKey="email" title="Email">
          <ModalBody>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onEmailSubmit)}>
                <FormGroup label={"Email"} name={"EmailAddress"} required>
                  <Input
                    type='text'
                    className='form-control'
                    name={'EmailAddress'}
                    onChange={((e) => {
                      var email = e.target.value;
                      setValue('EmailAddress', email);
                      setInvitesCount(email.split(',').length);
                    })}
                    placeholder='Email'
                    rules={{
                      required: false,
                    }}
                  />
                </FormGroup>

                <Alert color={checkUsers(invitesCount) ? "info" : "danger"}>
                  {invitesCount === 1 ? (
                    <p>An email will be sent inviting them to use one of your subscriptions. If you want to invite multiple users at once, please separate them using commas.</p>
                  ) : (
                    <>
                      {checkUsers(invitesCount) ? (
                        <p>This will invite {invitesCount} users.</p>
                      ) :
                        (
                          <p>The number of users you've entered is more than your maximum.</p>
                        )}

                    </>
                  )}
                </Alert>
                <SubmitButton control={control} pending={isPending}>
                        Submit
                </SubmitButton>
              </form>
            </FormProvider>

          </ModalBody>
        </Tab>
        <Tab eventKey="phone" title="Phone">
          <ModalBody>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onEmailSubmit)}>
                <FormGroup label={"Phone Number"} name={"ContactNumber"} required>
                  <Input
                    type='tel'
                    className='form-control'
                    name={'ContactNumber'}
                    placeholder='+44 (0) 00000 000 000'
                    rules={{
                      required: false,
                    }}
                  />
                </FormGroup>
                <SubmitButton control={control} pending={isPending}>
                        Submit
                </SubmitButton>
              </form>
            </FormProvider>

          </ModalBody>
        </Tab>
      </Tabs>
      <ModalFooter>
        {!!successes && successes.length > 0 && (
          <Alert className="w-100" color="success">
            <strong>{successes.length} Successful invitations</strong>
          </Alert>
        )}
        {!!failures && failures.length > 0 && (
          <Alert className="w-100" color="danger">
            <strong>Failures</strong>
            <table className="table table-borderless">
              <thead>
                <th>Email</th>
                <th>Reason</th>
              </thead>
              {failures.map((failure, index) => (
                <>
                  <tr>
                    <td>{failure.email}</td>
                    <td>{failure.reason}</td>
                  </tr>
                </>
              ))}
            </table>
          </Alert>
        )}

        <Alert className="w-100" color={checkUsers(1) ? 'info' : 'danger'}>
          <strong>{checkUsers(1) ? 'Note: ' : 'Warning: '} </strong>
          Your organisation currently has  {organisation?.admin_info?.users_activated}{organisation?.admin_info?.maximum_users === null ? '' : '/'}{organisation?.admin_info?.maximum_users} users,
          with {organisation?.admin_info?.users_pending} pending acceptance of their invitation.
        </Alert>
        <Button variant="secondary" onClick={() => toggle()}>Close</Button>

      </ModalFooter>
    </Modal>
  );
};

export default InviteUserModal;
