import React, { useLayoutEffect } from 'react';
import { Page, Header } from 'components/common';

export const TermsOfUse = () => {
	useLayoutEffect(() => {
		window.scrollTo(0, 0)
	});

	return (
		<Page background="help-pages">
			<Header className="mb-2" displayIcon={"false"} title={"Terms and conditions of use"}></Header>
			<>
				<p>
					<span style={{ fontSize: "large" }}>
						<strong>
							IF YOU ARE EXPERIENCING A MENTAL HEALTH CRISIS, HAVING THOUGHTS OF
							SUICIDE, OR THINK YOU MAY PRESENT A DANGER TO YOURSELF OR OTHERS, PLEASE
							CONTACT YOUR DOCTOR OR THE APPROPRIATE EMERGENCY SERVICES OR AUTHORITIES
							PROMPTLY. SELF-THERAPIST IS DESIGNED TO BE A SUPPLEMENT TO THE
							HEALTHCARE YOU ARE RECEIVING AND IS NOT A REPLACEMENT FOR ANY CARE YOU
							MAY BE RECEIVING ELSEWHERE
						</strong>
					</span>
				</p>
				<p>
					<span style={{ fontSize: "large" }}>
						<strong>Introduction</strong>
					</span>
				</p>
				<p>
					These terms and conditions apply between you, the User of this Website (
					<span style={{ color: "#0563c1" }}>
						<a href="https://self-therapist.co.uk/">self-therapist.co.uk</a>
					</span>{" "}
					including any sub-domains, unless expressly excluded by their own terms and
					conditions), and&nbsp;Self-Therapist Limited,&nbsp;the owner and operator of
					this Website. Please read these terms and conditions carefully, as they
					affect your legal rights. Your agreement to comply with and be bound by
					these terms and conditions is deemed to occur upon your first use of the
					Website. If you do not agree to be bound by these terms and conditions, you
					should stop using the Website immediately.
				</p>
				<p>
					In these terms and conditions&nbsp;<strong>User</strong>&nbsp;or&nbsp;
					<strong>Users</strong>&nbsp;means any third party that accesses the Website
					and is not either (i) employed by&nbsp;Self-Therapist Limited&nbsp;and
					acting in the course of their employment or (ii) engaged as a consultant or
					otherwise providing services to&nbsp;Self-Therapist Limited and accessing
					the Website in connection with the provision of such services.
				</p>
				<p>
					You must be at least 18 years of age to use this Website. By using the
					Website and agreeing to these terms and conditions, you represent and
					warrant that you are at least 18 years of age.
				</p>
				<p>
					The information in the service should not be relied on to suggest an
					authoritative course of action for an individual with medical, or mental
					health concerns or to replace a consultation with a qualified expert in the
					field, such as a doctor or health care provider. All Users should consult
					with a doctor before engaging in changes to diet, physical activity levels
					or other similar lifestyle or behavioural changes.
				</p>
				<p>
					By using the Website and agreeing to these terms and conditions, you
					represent and warrant that you are not experiencing a mental health crisis,
					having thoughts of suicide, or think you may present a danger to yourself or
					others.
				</p>
				<p>
					<span style={{ fontSize: "large" }}>
						<strong>Intellectual property and acceptable use</strong>
					</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								<span>
									<span>
										<span>
											All Content included on the Website, unless uploaded by Users,
											is the property of&nbsp;Self-Therapist Limited,&nbsp;our
											affiliates or other relevant third parties. In these terms and
											conditions, Content means questions, aims, any text, graphics,
											images, audio, video, software, data compilations, page layout,
											underlying code and software and any other form of information
											capable of being stored in a computer that appears on or forms
											part of this Website, including any such content uploaded by
											Users. By continuing to use the Website you acknowledge that
											such Content is protected by copyright, trademarks, database
											rights and other intellectual property rights. Nothing on this
											site shall be construed as granting, by implication, estoppel,
											or otherwise, any licence or right to use any trademark, logo or
											service mark displayed on the site without the owner's prior
											written permission.
										</span>
									</span>
								</span>
							</span>
						</p>
					</li>
				</ol>
				<ol start={2}>
					<li>
						<p>
							<span>
								<span>
									<span>
										<span>
											You may, for your own personal, non-commercial use only
											retrieve, display and view the Content on a computer screen.
										</span>
									</span>
								</span>
							</span>
						</p>
					</li>
				</ol>
				<ol start={3}>
					<li>
						<p>
							<span>
								<span>
									<span>
										<span>
											You must not otherwise reproduce, modify, copy, distribute or
											use for commercial purposes any Content without the expressed
											written permission of&nbsp;Self-Therapist Limited.&nbsp;
										</span>
									</span>
								</span>
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span style={{ fontSize: "large" }}>
						<strong>Prohibited use</strong>
					</span>
				</p>
				<ol start={4}>
					<li>
						<p>
							You may not use the Website or its Content for any of the following
							purposes:
						</p>
						<ol style={{ listStyleType: "lower-alpha" }}>
							<li>
								<p>
									in any way which causes, or may cause, damage to the Website or
									interferes with any other person's use or enjoyment of the Website;
								</p>
							</li>
							<li>
								<p>
									in any way which is harmful, unlawful, illegal, abusive, harassing,
									threatening or otherwise objectionable or in breach of any
									applicable law, regulation, governmental order;
								</p>
							</li>
							<li>
								<p>making, transmitting or storing electronic copies of Content.</p>
							</li>
						</ol>
					</li>
				</ol>
				<p>
					<span style={{ fontSize: "large" }}>
						<strong>Links to other websites</strong>
					</span>
				</p>
				<ol start={5}>
					<li>
						<p>
							This Website may contain links to other sites. Unless expressly stated,
							these sites are not under the control of&nbsp;Self-Therapist
							Limited&nbsp;or that of our affiliates.
						</p>
					</li>
				</ol>
				<ol start={6}>
					<li>
						<p>
							We assume no responsibility for the content of such Websites and
							disclaim liability for any and all forms of loss or damage arising out
							of the use of them.
						</p>
					</li>
				</ol>
				<ol start={7}>
					<li>
						<p>
							The inclusion of a link to another site on this Website does not imply
							any endorsement of the sites themselves or of those in control of them.
						</p>
					</li>
				</ol>
				<p>
					<span style={{ fontSize: "large" }}>
						<strong>Privacy Policy</strong>
					</span>
				</p>
				<ol start={8}>
					<li>
						<p>
							Use of the Website is also governed by our Privacy Policy, which is
							incorporated into these terms and conditions by this reference. To view
							the Privacy Policy please
							see&nbsp;<a href="https://self-therapist.co.uk/privacy_policy">self-therapist.co.uk/privacy_policy</a>
						</p>
					</li>
				</ol>
				<p>
					<span style={{ fontSize: "large" }}>
						<strong>Availability of the Website and disclaimers</strong>
					</span>
				</p>
				<ol start={9}>
					<li>
						<p>
							Any online facilities, tools, services or information
							that&nbsp;Self-Therapist Limited&nbsp;makes available through the
							Website (the&nbsp;<strong>Service</strong>) is provided "as is" and on
							an "as available" basis. We give no warranty that the Service will be
							free of defects and/or faults. To the maximum extent permitted by the
							law, we provide no warranties (express or implied) of fitness for a
							particular purpose, accuracy of information, compatibility and
							satisfactory quality.&nbsp;Self-Therapist Limited&nbsp;is under no
							obligation to update information on the Website.
						</p>
					</li>
					<li>
						<p>
							Whilst&nbsp;Self-Therapist Limited&nbsp;uses reasonable endeavours to
							ensure that the Website is secure and free of errors, viruses and other
							malware, we give no warranty or guarantee in that regard and all Users
							take responsibility for their own security, that of their personal
							details and their computers.
						</p>
					</li>
					<li>
						<p>
							Self-Therapist Limited&nbsp;accepts no liability for any disruption or
							non-availability of the Website.
						</p>
					</li>
					<li>
						<p>
							Self-Therapist Limited&nbsp;reserves the right to alter, suspend or
							discontinue any part (or the whole of) the Website including, but not
							limited to, any products and/or services available. These terms and
							conditions shall continue to apply to any modified version of the
							Website unless it is expressly stated otherwise.
						</p>
					</li>
				</ol>
				<p>
					<span style={{ fontSize: "large" }}>
						<strong>Limitation of liability</strong>
					</span>
				</p>
				<ol start={13}>
					<li>
						<p>
							<span>
								<span>
									<span>
										<span>
											You use the Website at your own risk. To the full extent
											permitted by law, you agree that Self-Therapist Ltd and its
											agents, contractors, officers, and employees shall have no
											liability for claims by, or loss, injury, cost, expense or
											damages of any kind whatsoever to a User of this Website or any
											other person, arising in any way from use of this Website or any
											decision or action taken or not taken in reliance on the
											information contained on this Website.{" "}
										</span>
									</span>
								</span>
							</span>
						</p>
					</li>
					<li>
						<p>
							Self-Therapist Ltd neither make nor give any representation or warranty
							as to the accuracy, completeness, currency, correctness, reliability,
							integrity, quality, fitness for purpose or originality of any content of
							the service, and to the fullest extent permitted by law, all implied
							warranties, conditions or other terms of any kind are hereby excluded to
							the fullest extent permitted by law. We accept no liability for any loss
							or damage of any kind incurred as a result of you or anyone else relying
							on the content of the Website.
						</p>
					</li>
					<li>
						<p>
							We will not be liable to you in respect of any losses arising out of
							events beyond our reasonable control.
						</p>
					</li>
					<li>
						<p>
							To the maximum extent permitted by law,&nbsp;Self-Therapist
							Limited&nbsp;accepts no liability for any of the following:
						</p>
					</li>

					<ol style={{ listStyleType: "lower-alpha" }}>
						<li>
							<p>
								any business losses, such as loss of profits, income, revenue,
								anticipated savings, business, contracts, goodwill or commercial
								opportunities;
							</p>
						</li>
						<li>
							<p>loss or corruption of any data, database or software;</p>
						</li>
						<li>
							<p>any special, indirect or consequential loss or damage.</p>
						</li>
					</ol>

				</ol>
				<p>
					<span style={{ fontSize: "large" }}>
						<strong>General</strong>
					</span>
				</p>
				<ol start={17}>
					<li>
						<p>
							You may not transfer any of your rights under these terms and conditions
							to any other person. We may transfer our rights under these terms and
							conditions where we reasonably believe your rights will not be affected.
						</p>
					</li>
				</ol>
				<ol start={18}>
					<li>
						<p>
							These terms and conditions may be varied by us from time to time. Such
							revised terms will apply to the Website from the date of publication.
							Users should check the terms and conditions regularly to ensure
							familiarity with the then current version.
						</p>
					</li>
				</ol>
				<ol start={19}>
					<li>
						<p>
							These terms and conditions&nbsp;together with the Privacy Policy contain
							the whole agreement between the parties relating to its subject matter
							and supercede all prior discussions, arrangements or agreements that
							might have taken place in relation to the terms and conditions.
						</p>
					</li>
				</ol>
				<ol start={20}>
					<li>
						<p>
							The&nbsp;Contracts (Rights of Third Parties) Act 1999&nbsp;shall not
							apply to these terms and conditions and no third party will have any
							right to enforce or rely on any provision of these terms and conditions.
						</p>
					</li>
				</ol>
				<ol start={21}>
					<li>
						<p>
							If any court or competent authority finds that any provision of these
							terms and conditions (or part of any provision) is invalid, illegal or
							unenforceable, that provision or part-provision will, to the extent
							required, be deemed to be deleted, and the validity and enforceability
							of the other provisions of these terms and conditions will not be
							affected.
						</p>
					</li>
				</ol>
				<ol start={22}>
					<li>
						<p>
							Unless otherwise agreed, no delay, act or omission by a party in
							exercising any right or remedy will be deemed a waiver of that, or any
							other, right or remedy.
						</p>
					</li>
					<li>
						<p>
							This Agreement shall be governed by and interpreted according to the law
							of&nbsp;England and Wales&nbsp;and all disputes arising under the
							Agreement (including non-contractual disputes or claims) shall be
							subject to the exclusive jurisdiction of the&nbsp;English and
							Welsh&nbsp;courts.
						</p>
					</li>
				</ol>
				<p>
					&nbsp;
					<span style={{ fontSize: "large" }}>
						<strong>Self-Therapist Limited&nbsp;</strong>
					</span>
					<span style={{ fontSize: "large" }}>
						<strong>details</strong>
					</span>
				</p>
				<p>
					Self-Therapist Limited&nbsp;is a company incorporated in&nbsp;England and
					Wales&nbsp;with registered number&nbsp;13778366&nbsp;whose registered
					address is DIAMOND HOUSE, DIAMOND COURT, BAKEWELL, DERBYSHIRE, DE45 1EW and it operates the
					Website <a href="https://self-therapist.co.uk">self-therapist.co.uk</a>
				</p>
				<p>
					<br />
					&nbsp;
				</p>
				<p>
					You can contact&nbsp;Self-Therapist Limited&nbsp;by email
					on&nbsp;<a href="mailto:info@self-therapist.co.uk">info@self-therapist.co.uk</a>
				</p>
				<p>
					<br />
					&nbsp;
				</p>
			</>

		</Page>
	);
};

export default TermsOfUse;
