import React, { useState } from 'react';
import { NotificationManager } from "react-notifications";
import { FormProvider, useForm } from "react-hook-form";
import {
  FormGroup,
} from "../../components/react-hook-form";
import { useClient } from 'hooks';
import { Button,  ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SunEditor from "suneditor-react";

export const EmailModal = ({ Modal, toggle, id }) => {
  const client = useClient();
  const methods = useForm({
    defaultValues: {
      'email': ''
    },
    mode: "onBlur",
  });
  const { handleSubmit, reset, setValue } = methods;
  const [codeViewOpen, setCodeViewOpen] = useState(false);
  function toggleCodeView(isCodeView) {
    setCodeViewOpen(isCodeView);
  }
  const onEmailSubmit = (formData) => {
    if (codeViewOpen) {
      alert("Please close the code view before saving, otherwise changes might be lost.");
      return;
    }
    client.post(`studys/${id}/email_participants`, formData).then((resp) => {
      if (resp.data.ok > 0) {
        NotificationManager.success(`${resp.data.ok} emails sent successfully`);
      }
      if (resp.data.failed > 0) {
        NotificationManager.error(`${resp.data.failed} emails failed`);
      }

      reset({
        'email': ''
      })
      toggle();
    }).catch(err => {
      console.error(err);
      const detail = err?.response?.data?.detail;

      if (detail) {
        NotificationManager.error(detail);
        return;
      } else {
        NotificationManager.error("Something went wrong. Please try again.");
      }
    });
  }

  return (
    <Modal size={'lg'}>
      <ModalHeader closeButton>Email Participants</ModalHeader>
      <ModalBody>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onEmailSubmit)}>
            <FormGroup label={"Email"} name={"email"} required>
              <SunEditor
                name={'email'}
                height={300}
                setContents={""}
                onChange={x => setValue('email', x)}
                toggleCodeView={toggleCodeView}
                setOptions={{
                  buttonList: [
                    ['undo', 'redo'],
                    ['font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                    ['fontColor', 'hiliteColor', 'textStyle'],
                    ['removeFormat'],
                    ['outdent', 'indent'],
                    ['align', 'horizontalRule', 'list', 'lineHeight'],
                    ['table'],
                    ['codeView', 'preview'],
                    ['link'],
                  ],
                }}
              />
            </FormGroup>
            <input className="btn btn-primary float-right" type="submit" value="Submit" />
          </form>
        </FormProvider>

      </ModalBody>
      <ModalFooter>

        <Button variant="danger" onClick={() => toggle()}>Cancel</Button>

      </ModalFooter>
    </Modal>
  );
};

export default EmailModal;
