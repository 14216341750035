import React from "react";
import Button from "./Button";
import Modal from 'react-bootstrap/Modal';

export const EditModal = ({
  title,
  open,
  setOpen,
  save=false,
  children,
  ...props
}) => {


  const handleClose = () => setOpen(false);


  return(
    <Modal show={open} onHide={handleClose} {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button className="h-10" color="danger" onClick={handleClose}>
          Close
        </Button>
        {save && (
          <Button className="h-10" color="success" onClick={save}>
          Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}


export default EditModal;
