import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { useController } from "react-hook-form";

export const StripeCard = ({
  control,
  shouldUnregister,
  defaultValue,
  name,
  rules,
}) => {
  const { field } = useController({
    control,
    shouldUnregister,
    defaultValue,
    name,
    rules,
  });
  const { onChange } = field;

  return (
    <CardElement
      onChange={(v) => {
        onChange(v);
      }}
    />
  );
};
