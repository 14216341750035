import React, { useState } from 'react';
import { Row, Col, Button, Media, UncontrolledTooltip } from 'reactstrap';
import { Page, Header } from 'components/common';
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import {
  Pagination,
  Table,
  Search,
  useListQuery,
  useListTable,
  TableContextProvider,
} from "components/common/Tables";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const basicColumn = (name) => {
  let col = {
    Header: name,
    accessor: name.toLowerCase(),
  };
  if (name === "Name") {
    col.maxWidth = "80% !important";
    col.minWidth = "80% !important";
    col.width = '0 !important';
  }
  return col;
}
const ViewColumn =
{
  Header: "",
  accessor: "id",
  maxWidth: "5% !important",
  minWidth: "5% !important",
  width: "0 !important",
  float: "right !important",
  disableSortBy: true,
  Cell: (cellProps) => {
    let id = cellProps.row.values.id ?? null;
    if (!id) {
      return null;
    }
    return (
      <Button className="h-10" color="primary" style={{ float: 'right' }} aria-label="Press to view therapy guide">
        <FontAwesomeIcon icon={faEye} className="mr-2 mobile-icons" />
        View
      </Button>
    );
  }
}
const popupColumns = [
  basicColumn('Name'),
  // basicColumn('Keywords'),
  ViewColumn
];


export const List = ({ type, name }) => {
  const url = type;
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const table = useListTable({
    columns: popupColumns,
    data,
    initialState: {
      pageSize: 20,
    },
  });

  useListQuery({
    setData,
    table,
    url: url,
  });
  const getRowProps = (row) => {
    return {
      onClick: async () => {
        navigate('../guides/' + row.original.id);
      },
      style: { cursor: "pointer" },
    };
  };
  return (
    <Page background="guides">
      <Header displayIcon="false" title={name}>
        <div className="d-flex m-auto" style={{ justifyContent: "center" }}>

          <h4 style={{ textAlign: "center", textTransform: 'capitalize' }}><b>{name}</b></h4>
          <Media
            object
            src={'/icons/hover.png'}
            style={{ width: '30px', height: "30px", marginLeft: "15px" }}
            id={`guides`} onClick={(e) => e.preventDefault()}
            aria-label={"Press for more information"}
          />
        </div>
        <UncontrolledTooltip placement="auto" target={`guides`} trigger={!!isMobile ? ("click") : ("click hover")}>
          These guides provide you with the knowledge and wisdom that a professional therapist would utilise, presented in a way that can be used for self-therapy
        </UncontrolledTooltip>
      </Header>
      <TableContextProvider table={table} >
        <Row form>
          <Col className="pr-2">
            <Search placeholder="Search" />
          </Col>
        </Row>
        <Table size="sm" striped getRowProps={getRowProps} />
        <Pagination />
      </TableContextProvider>
    </Page>

  )
}
export default List;
