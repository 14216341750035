import React from "react";
import { FormGroup, Select } from "../../../../components/react-hook-form";
import { useTranslation } from "react-i18next";

export const PlanField = ({ name, options, rules, defaultValue, orgAccount }) => {
  const { t } = useTranslation();

  return (
    <FormGroup label={!!orgAccount === true ? t("And what plan would you like to use?") : t("What plan would you like to use?")} name={name} required>
      <Select
        defaultValue={defaultValue}
        name={name}
        valueKey="id"
        rules={rules}
        options={options}
      />
    </FormGroup>
  );
};
