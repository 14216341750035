import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useClient } from "../../hooks";
import { Page, Header } from 'components/common';
import { Alert } from 'reactstrap';
import { NotificationManager } from 'react-notifications';

import {
  Input,
  FormGroup,
  SubmitButton,
  DatePicker,
} from "../../components/react-hook-form";

export const EditStudies = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;
  const isNew = !id || id === 'create';
  const methods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit, setValue } = methods;
  const client = useClient();
  const [data, setData] = useState();

  useEffect(() => {
    if (!isNew) {
      client.get(`studys/${id}`).then(({ data }) => {
        setData(data);
      }).catch(exc => {
        console.warn(exc);
        NotificationManager.error("Could not retrieve your common issue");
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (data) {

      setValue('code', data.code);
      setValue('name', data.name);
      setValue('expiry', data.expiry);

    }
  }, [data, setValue]);


  async function onSubmit(x) {

    if (isNew) {
      client
        .post(`studys`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");
          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    } else {
      client
        .put(`studys/${id}`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");
          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    }

  }

  return (
    <Page background="admin">
      {!!isNew ? (
        <Header className="mb-2" displayIcon={"false"} title={`New Study`} />
      ) : (
        <Header className="mb-2" displayIcon={"false"} title={`Edit Study`} />
      )}
      <Alert color="info">
        Inviting a participant to the study will enable them to use the system without charge.
      </Alert>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label={"Study Code"} name={"code"}>
            <div style={{ fontSize: "10px" }} className="mt-1">{'Use this to invite users to sign up with to be a part of the study'}</div>
            <Input
              type='text'
              className='form-control'
              name={'code'}
              placeholder='Code'
              rules={{
                required: true,
                maxLength: 255
              }}
            />
          </FormGroup>
          <FormGroup label={"Name of Study"} name={"name"}>
            <Input
              type='text'
              className='form-control'
              name={'name'}
              placeholder='Study'
              rules={{
                required: true,
                maxLength: 255
              }}
            />
          </FormGroup>

          <FormGroup label={"Expiry of Study"} name={"expiry"}>
            <DatePicker
              name="expiry"
              className="form-control"
              placeholderText="DD/MM/YYYY"
              rules={{ required: true }}
            />
          </FormGroup>

          <div className="flex justify-between">
            <SubmitButton color="primary">
              {isNew ? "Create" : "Save"}
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};
export default EditStudies;
