import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useClient } from "../../hooks";
import { Page, Header } from 'components/common';
import { NotificationManager } from 'react-notifications';

import {
  Input,
  FormGroup,
  Select,
  SubmitButton,
} from "../../components/react-hook-form";

export const EditAimStatement = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;
  const isNew = !id || id === 'create';
  const methods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit, setValue } = methods;
  const client = useClient();
  const [elements, setElements] = useState();
  const [qualityStatements, setQualityStatements] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    client.get(`element`).then(({ data }) => {
      const selectOptions = data.map(o => ({ value: o.id, label: o.name }))
      setElements(selectOptions);
    }).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Could not retrieve the elements");
    })
    client.get(`qualitystatement`).then(({ data }) => {
      const selectOptions = data.map(o => ({ value: o.id, label: o.statement }))
      setQualityStatements(selectOptions);
    }).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Could not retrieve your quality statements");
    })
    if (!isNew) {
      client.get(`aimstatement/${id}`).then(({ data }) => {
        setData(data);
      }).catch(exc => {
        console.warn(exc);
        NotificationManager.error("Could not retrieve your aim statement");
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setValue('key', data.key);
      setValue('statement', data.statement);
      setValue('element', data.element?.id);
      if (!!qualityStatements && qualityStatements.length > 0 && data.quality_statement) {
        setValue('quality_statement', {
          value: data.quality_statement, label: qualityStatements.find((x) => x.value === data.quality_statement).label
        });
        if (!!elements && elements.length > 0 && data.element) {
          setValue('element', { value: data.element, label: elements.find((x) => x.value === data.element).label });
        }
      }
    }
  }, [data, elements, qualityStatements, setValue]);


  async function onSubmit(x) {
    if (typeof x.quality_statement === "object") {
      x.quality_statement = x.quality_statement.value;
    }
    if (typeof x.element === "object") {
      x.element = x.element.value;
    }
    if (isNew) {
      client
        .post(`aimstatement`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    } else {
      client
        .put(`aimstatement/${id}`, x)
        .then(e => {
          const id = e.data?.id;
          NotificationManager.success("Saved successfully");

          navigate(`../${id}`);
        })
        .catch((ex) => {
          console.error(ex);
          NotificationManager.error('An error occurred sending the request to the server.');
        });
    }

  }

  return (
    <Page background="admin">
      {!!isNew ? (
        <Header className="mb-2" displayIcon={"false"} title={`New Aim Statement`} />
      ) : (
        <Header className="mb-2" displayIcon={"false"} title={`Edit Aim Statement`} />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup label={"Key"} name={"key"} required>
            <Input
              type='text'
              className='form-control'
              name={'key'}
              placeholder='Key'
              rules={{
                required: true,
                maxLength: 255
              }}
            />
          </FormGroup>
          <FormGroup label={"Statement"} name={"statement"}>
            <Input
              type='textarea'
              className='form-control'
              name={'statement'}
              placeholder='Statement'
              rules={{
                required: false,
                maxLength: 16860
              }}
            />
          </FormGroup>
          <FormGroup label={"Quality Statement"} name={"quality_statement"} required>
            <Select
              name="quality_statement"
              rules={{ required: false }}
              options={qualityStatements}
            />
          </FormGroup>

          <FormGroup label={"Element"} name={"element"} required>
            <Select
              name="element"
              rules={{ required: true }}
              options={elements}
            />
          </FormGroup>

          <div className="flex justify-between">
            <SubmitButton color="primary">
              {isNew ? "Create" : "Save"}
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Page>
  );
};
export default EditAimStatement;
