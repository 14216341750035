import { useFormState } from "react-hook-form";
import React from "react";
import { PendingButton } from "components/common";

export const SubmitButton = ({ pending, control, children, ...props }) => {
  const { isSubmitting } = useFormState({
    control,
  });

  return (
    <PendingButton
      type="submit"
      pending={!!(pending || isSubmitting)}
      color="primary"
      translate={false}
      {...props}
    >
      {children}
    </PendingButton>
  );
};
