import React, { useEffect } from 'react';
import { DecisionButton, Page, Header, Logo, Footer } from "components/common";
import { useUserContext, useModal } from 'hooks';
import { Row, Col, Button, Alert } from 'reactstrap';
import InviteUserModal from './InviteUserModal';
import BlockUserModal from './BlockUserModal';

export const Dashboard = () => {
  const { user, refreshUser } = useUserContext();
  const { Modal, toggle } = useModal();
  const { Modal: blockModal, toggle: blockToggle } = useModal();

  const flavourText =
    <p>Once you have sent invitations to users, they will appear as pending on your account.
      Reminder invitations can be sent to the same person twice without reducing your available invitations.
      If you would like to purchase more invitations, you can add more users to your existing subscription and you will be charged pro rata for them.
    </p>

  useEffect(() => {
    // SUPER ADMIN INFO?
    // client.get(`question_groups_images`).then(e => {
    // 	setQuestionGroupImages(e['data']);
    // }).catch(exc => {
    // 	console.warn(exc);
    // 	NotificationManager.error("Could not retrieve question group images");
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refreshUser();
  }, [])



  return (
    <>
      <InviteUserModal toggle={toggle} Modal={Modal} organisation={user?.organisation} />
      <BlockUserModal toggle={blockToggle} Modal={blockModal} organisation={user?.organisation} />
      <Page background="admin p-4" footer={<Footer textToDisplay={flavourText} displayButtons={"false"}></Footer>}>

        <Header
          className="mb-2"
          displayIcon={"false"} title={'Admin Dashboard for ' + user?.organisation?.name}
        />
        <Row className='mt-2'>
          <Col className='text-center'>
            {/* PLACEHOLDER FOR REPORTS?*/}
            <div className='alert alert-info p-4' >
              <Logo showName />
            </div>

            {!!user?.organisation?.admin_info && (
              <Alert className="text-center" color={user?.organisation?.admin_info?.free || user?.organisation?.admin_info?.users_left > 0 ? 'info' : 'danger'}>
                <div className="d-flex justify-content-between">
                  <Button color="primary" onClick={toggle}>Invite</Button>
                  <Button color="danger" onClick={blockToggle}>Remove User</Button>

                </div>
                {!!user?.organisation?.admin_info?.maximum_users && (
                  <p><b>Maximum users: </b>{user?.organisation?.admin_info?.maximum_users}</p>
                )}
                {!!user?.organisation?.admin_info?.free ? (
                  <p><b>You have a free organisational account</b></p>
                ) : (
                  <>
                    <p><b>Activated users: </b>{user?.organisation?.admin_info?.users_activated}</p>
                    <p><b>Pending invitations: </b>{user?.organisation?.admin_info?.users_pending}</p>
                    {!!user?.organisation?.admin_info?.maximum_users && (
                      <p><b>Remaining invitations: </b>{user?.organisation?.admin_info?.users_left}</p>
                    )}

                  </>
                )}

              </Alert>
            )}

            <DecisionButton
              link={`/stripe-subscription`}
              name={`Subscription`}
            />

            <DecisionButton
              link={`user-reports`}
              name={`User Reports`}
            />
            <DecisionButton
              link={`edit_organisation`}
              name={`Edit Organisation`}
            />

          </Col>
        </Row>
      </Page>
    </>
  );
};

export default Dashboard;
