import React from "react";
import { Link } from 'react-router-dom';
import { FormGroup, Input } from '../../components/react-hook-form';
import { Row, Col } from 'reactstrap';
import 'assets/css/style.css';
import moment from 'moment';

export const Disclaimers = ({
    accountType = null,
    setGreyRegister: setRegisterDisabled,
}) => {
    const access_to_therapist = JSON.parse(localStorage.getItem("access_to_therapist"))?.value;
    const not_in_crisis = JSON.parse(localStorage.getItem("not_in_crisis"))?.value;
    const agreed_to_terms = JSON.parse(localStorage.getItem("agreed_to_terms"))?.value;
    const of_age = JSON.parse(localStorage.getItem("of_age"))?.value;
    const size = '28px';
    const handleChange = (e) => {
        if (e !== 'skip') {
            const { id, checked } = e.target;
            var object = { value: checked, date: new Date().toLocaleDateString() }
            localStorage.setItem(id, JSON.stringify(object));
        }
        const accessToTherapist = JSON.parse(localStorage.getItem("access_to_therapist"))?.value;
        const notInCrisis = JSON.parse(localStorage.getItem("not_in_crisis"))?.value;
        const agreedToTerms = JSON.parse(localStorage.getItem("agreed_to_terms"))?.value;
        const ofAge = JSON.parse(localStorage.getItem("of_age"))?.value;
        const termsRequiredAndAgreed = agreedToTerms || !(accountType === 'a Study Participant' || accountType === 'study_corporate');
        var all = accessToTherapist && notInCrisis && ofAge && termsRequiredAndAgreed;
        setRegisterDisabled(!all);
        const d = new Date(moment().add(1, 'M'));
        var newObject = { value: all, date: d };
        localStorage.setItem('all_accepted', JSON.stringify(newObject));
    }
    handleChange('skip');
    return (
        <>
            <Row className='h-100 mt-2'>
                <Col className='i-know-card'>
                    <FormGroup>
                        <Row className="justify-content-start">
                            <Col xs={3} md={2}>
                                <Input
                                    id='not_in_crisis'
                                    type='checkbox'
                                    className='float-right'
                                    name={'not_in_crisis'}
                                    rules={{
                                        required: false,
                                    }}
                                    defaultChecked={!!not_in_crisis}
                                    style={{ width: size, height: size }}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col xs={9} md={10}>
                                <label className="text-left" htmlFor='not_in_crisis'>I am not in a state of mental health crisis  <a href='https://www.self-therapist.com/crisis-information' target="_blank" rel="noopener noreferrer">(view our crisis page if you are)</a></label>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row className="justify-content-start" style={{ alignItems: 'center' }}>
                            <Col xs={3} md={2}>
                                <Input
                                    id='access_to_therapist'
                                    type='checkbox'
                                    className='float-right'
                                    name={'access_to_therapist'}
                                    rules={{
                                        required: false,
                                    }}
                                    defaultChecked={!!access_to_therapist}
                                    style={{ width: size, height: size }}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col xs={9} md={10}>
                                <label className="text-left" htmlFor='access_to_therapist'>I have access to mental health support if I need it</label>
                            </Col>

                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row className="justify-content-start" style={{ alignItems: 'center' }}>
                            <Col xs={3} md={2}>
                                <Input
                                    id='of_age'
                                    type='checkbox'
                                    className='float-right'
                                    name={'of_age'}
                                    rules={{
                                        required: false,
                                    }}
                                    defaultChecked={!!of_age}
                                    style={{ width: size, height: size }}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col xs={9} md={10}>
                                <label className="text-left" htmlFor='of_age'>I agree to the Terms of Use, and I am over 18 years of age</label>
                            </Col>

                        </Row>
                    </FormGroup>
                    {(accountType === 'a Study Participant' || accountType === 'study_corporate') && (
                        <FormGroup>
                            <Row className="justify-content-start" style={{ alignItems: 'center' }}>
                                <Col xs={3} md={2}>
                                    <Input
                                        id='agreed_to_terms'
                                        type='checkbox'
                                        className='float-right'
                                        name={'agreed_to_terms'}
                                        rules={{
                                            required: false,
                                        }}
                                        defaultChecked={!!agreed_to_terms}
                                        style={{ width: size, height: size }}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={9} md={10}>
                                    <label className="text-left" htmlFor='agreed_to_terms'>I have read and agree to the Participant Consent statements in the <a href='https://www.self-therapist.com/study-consent' target="_blank" rel="noopener noreferrer">Study Consent document</a></label>
                                </Col>
                            </Row>
                        </FormGroup>
                    )}
                </Col>
            </Row>
            <Row className="justify-content-start">
                <Col xs={3} md={2}>
                </Col>
                <Col xs={9} md={10}>
                    <p style={{ fontSize: '14px' }} className='float-left'>
                        <b>
                            You need to agree to all the disclaimers above before being able to register an account
                        </b>
                    </p>
                </Col>
            </Row>
        </>
    );
}

export default Disclaimers;
