import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { useForm, FormProvider } from 'react-hook-form';
import { SubmitButton } from '../../components/react-hook-form';
import { Page, Footer, Header } from "components/common";
import { useClient } from 'hooks';
import { Row, Col, Container } from 'reactstrap';
import download from "downloadjs";
import Button from 'components/common/Button';
import { Pagination, Table, Search, useListQuery, useListTable, TableContextProvider } from '../../components/common/Tables';
import { faCircleNotch, faPencilAlt, faArrowRight, faCloudDownloadAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
const arrow = (
  <FontAwesomeIcon
    icon={faArrowRight}
    size="sm"
  />
)


export const AimsTable = () => {
  const client = useClient();
  const placeholder = "Only you can see notes you make here..";
  const flavourText = "This is the contents page for your notebook."
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const methods = useForm({
    defaultValues: {
      'first_thoughts': '',
    },
    mode: 'all'
  });
  const { handleSubmit, setValue, register } = methods;
  const columns = useMemo(() => {
    return [
      {
        Header: 'My Aims',
        accessor: 'personalised_aim_text',
        width: '100%',
        Cell: ({ row }) => {
          let text = row.values.personalised_aim_text;
          let ename = row.original.Element?.name;
          const id = row.original.id;

          let myaim = (text === '' || text === null) ? ename : text;
          let bn = row.original.before_negative;
          let bp = row.original.before_progress;
          let an = row.original.after_negative;
          let ap = row.original.after_progress;
          let l = row.original.notes.length;

          return (

            <>
              <Row>

                <Col xs={'8'} sm={'8'} md={'8'} lg={'10'}>

                  <div className="d-flex align-content-between justify-content-between flex-wrap">
                    <div>
                      <h6 className="notebook_table-text"><b style={{ color: text ? '#0e7d74' : 'darkblue' }}>{myaim}</b></h6>
                      <p className="notebook_table-text">I have written {l} notes </p>
                      <p className="notebook_table-text">{"Affects me:"}       <b style={{ color: bn === 0 ? 'red' : 'black' }}>{bn}</b> <b>{arrow}</b> <b style={{ color: an === 0 ? 'red' : 'black' }}>{an}</b></p>
                      <p className="notebook_table-text">{"I'm managing:"}     <b style={{ color: bp === 0 ? 'red' : 'black' }}>{bp}</b> <b>{arrow}</b> <b style={{ color: ap === 0 ? 'red' : 'black' }}>{ap}</b></p>

                    </div>
                  </div>

                </Col>

                <Col xs={'4'} sm={'4'} md={'4'} lg={'2'}>

                  <div>
                    <Button className="btn-sm" color="primary" aria-label="Press to edit aim">
                      <FontAwesomeIcon icon={faPencilAlt} className="mx-auto" fixedWidth />
                    </Button>
                    <Button aria-label="Press to delete aim" onClick={(e) => {
                      e.stopPropagation();
                      if (window.confirm('Are you sure you want to delete this aim? This will also delete all your personal notes.')) { deleteAim(id) }
                    }} style={{ minWidth: '35.5px' }} className="btn-sm trash-btn" color="danger">
                      <FontAwesomeIcon icon={faTrashAlt} className="mx-auto" />
                    </Button>
                  </div>

                </Col>

                <div className="squiggle-holder">
                  <div className="squiggle-tinyLine squiggle-tinyLine1"></div>
                  <div className="squiggle-tinyLine squiggle-tinyLine2"></div>
                </div>
              </Row>
            </>
          )
        }
      },
    ];
    // eslint-disable-next-line
  }, [client]);
  const table = useListTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageSize: 20,
      },
    },
  );
  const { isFetching, refetch } = useListQuery({
    setData,
    table,
    url: "personalisedaim",
    // query_arguments:{refetchInterval: 5000},
  });
  const getRowProps = (row) => {
    return {
      onClick: async () => {
        navigate('/notebook/' + row.original.id);
      },
      style: { cursor: "pointer" },
    };
  };
  useEffect(() => {
    client.get(`first_thoughts`).then(e => {
      setValue('first_thoughts', e['data']);
    }).catch(exc => {
      console.warn(exc);
      NotificationManager.error("Could not retrieve your first thoughts. Please try again.");
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const deleteAim = (id) => {
    client
      .delete(`personalisedaim/${id}`)
      .then((responseData) => {
        NotificationManager.success('This aim has been successfully removed');
        refetch();
      })
      .catch((ex) => {
        console.error(ex);
        NotificationManager.error('An error occurred deleting this aim, please try again.');
      });
  };
  const onSubmit = (formData) => {
    client
      .post(`first_thoughts`, {
        'first_thoughts': formData.first_thoughts,
      })
      .then((responseData) => {
        NotificationManager.success('Your first thoughts have been saved!');
      })
      .catch((ex) => {
        console.error(ex);
        NotificationManager.error('An error occurred with the First Thoughts box.');
      });

  };
  const onDownload = () => {
    client
      .get(`personalisedaim/download_csv`)
      .then((responseData) => {
        let csvContent = "";
        responseData.data.forEach(function (rowArray) {
          const row = rowArray
            .map(v => v?.replaceAll('"', '""'))  // escape double colons
            .map(v => `"${v}"`)  // quote it.
            .join(",");
          csvContent += row + "\r\n";
        });

        download(csvContent, 'self-therapist-aims.csv', 'text/csv');
        NotificationManager.success('Successfully downloaded CSV');
      })
      .catch((ex) => {
        console.error(ex);
        NotificationManager.error('An error occurred downloading the aims CSV');
      });
  };

  return (
    <Page background="notebook" footer={<Footer textToDisplay={flavourText} displayButtons={"false"}></Footer>}>
      <Header className="my-4" iconSize={72} displayIcon={"false"} title={"Notebook Contents Page"}></Header>
      <TableContextProvider table={table} >

        <Row>
          <Col xs={12} lg={9}>

            <Search placeholder="Search" />
            {isFetching &&
              <FontAwesomeIcon
                icon={faCircleNotch}
                spin
                className={classNames(
                  "absolute inset-y-2 opacity-50",
                  "right-0 mr-3"
                )}
                size="xl"
              />}
          </Col>
          <Col xs={12} lg={3} className="d-flex justify-content-between">
            <Button className="h-12 float-right" onClick={onDownload} style={{ background: "#007bff", border: "0px", color: "white", height: "40px" }} aria-label="Press to download your notes">
              Download{" "}
              <FontAwesomeIcon icon={faCloudDownloadAlt} />
            </Button>
            <Button className="h-12 float-right" onClick={() => navigate("/decision_tree_options")} style={{ background: "#007bff", border: "0px", color: "white", height: "40px" }} aria-label="Press to start the process">
              Start{" "}
            </Button>
          </Col>

        </Row>
        <Table borderless className="mt-3 notebook_table" getRowProps={getRowProps} />
        <Pagination />
      </TableContextProvider>

      <Container className="thinking-notes-container pb-4">
        <Row className='align-items-center mt-2'>
          <Col className='i-know-card text-center'>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <h5 className="my-2"><b>First thoughts</b></h5>
                <div className="d-flex align-content-stretch justify-content-center flex-wrap">
                  <textarea
                    rows={8}
                    cols={50}
                    className="p-6"
                    placeholder={placeholder}
                    {...register("first_thoughts", {})}
                  />
                </div>
                <SubmitButton className="btn-primary my-3 border-0 text-white h-10">Save</SubmitButton>
              </form>
            </FormProvider>

          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default AimsTable;
