import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { Table as BaseTable } from "reactstrap";
import { useTableContext } from "../hooks";

export const Table = ({ table, clientSidePagination=false,...props }) => {
  const _table = useTableContext();
  table = table ?? _table;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
  } = table;
  const defaultPropGetter = () => ({});
  return (
    <BaseTable {...getTableProps()} {...props}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} {...column.getHeaderProps({
                style: { maxWidth: column.maxWidth, minWidth: column.minWidth, width: column.width },
              })}>
                {column.render("Header")}
                {column.canSort ? (
                  <span className="ml-2">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} />
                    )}
                  </span>
                ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {(clientSidePagination ? page : rows).map((row) => {
          prepareRow(row);

          return (
            <tr
              {...row.getRowProps(
                props.getRowProps ? props.getRowProps(row) : defaultPropGetter
              )}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} {...cell.getCellProps({
                  style: {
                    maxWidth: cell.column.maxWidth,
                    minWidth: cell.column.minWidth,
                    width: cell.column.width,
                  },
                })}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </BaseTable>
  );
};
