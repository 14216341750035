import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { useForm, FormProvider } from 'react-hook-form';
import { SubmitButton } from '../../components/react-hook-form';
import { useClient } from 'hooks';
import { Row, Col } from 'reactstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { Page, Footer, Header } from 'components/common';

export const DecisionTreeStart = () => {
	const methods = useForm({
		defaultValues: {
			first_thoughts: '',
		},
		mode: 'all',
	});
	const navigate = useNavigate();
	const client = useClient();
	const { handleSubmit, setValue, register } = methods;
	const flavourText = "Imagine this is your first counselling	session, and the therapist says “What brings you here today?”"
	const pageHead = "You might like to download your thoughts about your problems or aims here";
	const placeholder = `Only you can see notes you make here..`;
	useEffect(() => {
		client.get(`first_thoughts`).then(e => {
			setValue('first_thoughts', e['data']);
		}).catch(exc => {
			console.warn(exc);
			NotificationManager.error("Could not retrieve your first thoughts. Please try again.");
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onSubmit = (formData) => {

		client
			.post(`first_thoughts`, {
				'first_thoughts': formData.first_thoughts,
			})
			.then((responseData) => {
				navigate('/decision_tree_options');
			})
			.catch((ex) => {
				console.error(ex);
				NotificationManager.error('An error occurred with the First Thoughts box.');
			});
	};

	return (
		<>
			<Page background="admin-pages" footer={<Footer textToDisplay={flavourText} displayButtons={"false"}></Footer>}>
				<Breadcrumb>
					<Breadcrumb.Item>
						Think
					</Breadcrumb.Item>
				</Breadcrumb>
				<Header className="mb-2" displayIcon={"false"} title={pageHead}></Header>
				<Row className=' h-100 mt-2'>
					<Col className=' text-center'>
						<FormProvider {...methods}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="d-flex align-content-stretch justify-content-center flex-wrap">
									<textarea
										rows={8}
										cols={50}
										className="p-6"
										placeholder={placeholder}
										{...register("first_thoughts", {})}
									/>

								</div>
								<SubmitButton className="btn-primary my-3 border-0 text-white h-10">
									Continue
								</SubmitButton>
							</form>
						</FormProvider>
					</Col>
				</Row>
			</Page>
		</>
	);
};

export default DecisionTreeStart;
