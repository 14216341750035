import React, { useState, useEffect } from "react";
// import { Button } from 'reactstrap';
import { Button, Input as ReactStrapInput } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import { useClient } from '../../hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { FormGroup, Input } from '../../components/react-hook-form';
import { NotificationManager } from "react-notifications";

export const EditFeedbackAimModal = ({
  open,
  setOpen,
  selectedPersonalisedAim,
  element_id = null,
  onSubmitParent,
  reloadAim,
  children,
  ...props
}) => {
  const client = useClient();
  const [AimId, setAimId] = useState(null);
  const methods = useForm({
    defaultValues: {
      after_negative: selectedPersonalisedAim?.after_negative === 0 ? null : selectedPersonalisedAim?.after_negative,
      after_progress: selectedPersonalisedAim?.after_progress === 0 ? null : selectedPersonalisedAim?.after_progress,
      before_negative: selectedPersonalisedAim?.before_negative === 0 ? null : selectedPersonalisedAim?.before_negative,
      before_progress: selectedPersonalisedAim?.before_progress === 0 ? null : selectedPersonalisedAim?.before_progress,
      personalised_aim_text: selectedPersonalisedAim?.personalised_aim_text

    },
    mode: 'all',
  });
  const {
    handleSubmit,
    setValue,
    reset,
  } = methods;

  const handleClose = () => {
    setOpen(false);
    if (reloadAim) reloadAim(selectedPersonalisedAim?.id);
    if (onSubmitParent) onSubmitParent();
  };

  useEffect(() => {
    reset({
      after_negative: selectedPersonalisedAim?.after_negative === 0 ? null : selectedPersonalisedAim?.after_negative,
      after_progress: selectedPersonalisedAim?.after_progress === 0 ? null : selectedPersonalisedAim?.after_progress,
      before_negative: selectedPersonalisedAim?.before_negative === 0 ? null : selectedPersonalisedAim?.before_negative,
      before_progress: selectedPersonalisedAim?.before_progress === 0 ? null : selectedPersonalisedAim?.before_progress,
      personalised_aim_text: selectedPersonalisedAim?.personalised_aim_text
    });
    setAimId(selectedPersonalisedAim?.id);
  }, [reset, setValue, selectedPersonalisedAim, open])


  const onSubmit = async (allForm) => {

    const formData = {
      after_negative: allForm.after_negative === null ? 0 : allForm.after_negative,
      after_progress: allForm.after_progress === null ? 0 : allForm.after_progress,
      before_negative: allForm.before_negative === null ? 0 : allForm.before_negative,
      before_progress: allForm.before_progress === null ? 0 : allForm.before_progress,
      personalised_aim_text: allForm.personalised_aim_text
    };


    try {
      if (!AimId) {
        formData['Element_id'] = element_id;
        await client.post('personalisedaim', formData);
      }
      else {
        //formData['Element_id'] = selectedPersonalisedAim?.id;
        await client.patch(`personalisedaim/${AimId}`, formData).get("data");
      }
      NotificationManager.success('Your notebook has been updated.');
    }
    catch (e) {
      NotificationManager.error('An error occurred with the Self Feedback submission.');
    }
    handleClose();
  };


  return (
    <FormProvider {...methods}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Modal show={open} onHide={handleClose} {...props}>
          <Modal.Header closeButton>
            <Modal.Title>Edit my aim and ratings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-2">
              <b>{selectedPersonalisedAim?.Element?.name}</b>
            </div>
            <FormGroup name="personalised_aim_text">
              <Input
                component={ReactStrapInput}
                type='textarea'
                rows={3}
                name={"personalised_aim_text"}
                placeholder='My specific aim is...'
                rules={{
                  required: false,

                }}
              />
            </FormGroup>
            <FormGroup name="before_negative" label="How much this was affecting me negatively:">
              <Input
                type='number'
                className="form-control notebook-highlight-1"
                name={'before_negative'}
                min={0}
                max={10}
                step={1}
                rules={{
                  min: 0,
                  max: 10,
                  required: false
                }}
              />
            </FormGroup>
            <FormGroup name="after_negative" label="How much this is affecting me negatively after working on my aim:">
              <Input
                type='number'
                className="form-control notebook-highlight-1"
                name={'after_negative'}
                min={0}
                max={10}
                step={1}
                rules={{
                  min: 0,
                  max: 10,
                  required: false
                }}
              />
            </FormGroup>
            <FormGroup name="before_progress" label="How well I was managing at the start:">
              <Input
                type='number'
                className="form-control notebook-highlight-2"
                name={'before_progress'}
                min={0}
                max={10}
                step={1}
                rules={{
                  min: 0,
                  max: 10,
                  required: false
                }}
              />
            </FormGroup>
            <FormGroup name="after_progress" label="How well I'm managing after working on my aim:">
              <Input
                type='number'
                className="form-control notebook-highlight-2"
                name={'after_progress'}
                min={0}
                max={10}
                step={1}
                rules={{
                  min: 0,
                  max: 10,
                  required: false
                }}
              />
            </FormGroup>
            <p style={{ fontSize: '11px' }}><i><b>Feedback values are out of 10</b></i></p>

          </Modal.Body>
          <Modal.Footer>
            <Button color="danger" onClick={handleClose}>
              Close
            </Button>
            <Button color="success" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </FormProvider>
  )
}


export default EditFeedbackAimModal;
